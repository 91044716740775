export const styles = {
  search: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 55,
    border: 'none',
    backgroundColor: theme.palette.common.white,
    '& input::placeholder': {
      fontSize: '20px',
    },
    '& input': {
      color: theme.palette.common.black,
      fontSize: '20px',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  }),
  autocompleteDropdown: (theme) => ({
    marginTop: '1px',
    background: theme.palette.common.white,
    borderRadius: '30px',
    overflow: 'hidden',
  }),
  listStyle: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 1,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: `${theme.palette.background.light}10 !important`,
    },
    '&:not(:hover):not(:focus)': {
      backgroundColor: `${theme.palette.common.white} !important`,
    },
  }),
  listStyleIcon: (theme) => ({
    color: theme.palette.common.black,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    minHeight: '44px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  typographyContainer: (theme) => ({
    minHeight: '44px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    color: theme.palette.common.black,
  }),
  searchIcon: (theme) => ({
    color: theme.palette.secondary.dark,
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};
