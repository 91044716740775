import PropTypes from 'prop-types';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {useSelector} from 'react-redux';

import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Box from '@mui/material/Box';

import {MODE} from '@config';
import {styles} from '@layouts/Layout.styles';
import {globalStylesOverride} from '@/styles/global';

import Navbar from '@/components/navigation/Navbar';
import BaseSnackbar from '@/components/common/alerts/BaseSnackbar';

import {useAuth0User} from '@/hooks/useAuth0User';
import {Loader} from '@/components/common/loaders';
import {useAuth0Service} from '@/hooks/useAuth0Service';
import {getUser} from '@/selectors';

function Layout({children}) {
  const {isLoading, error} = useAuth0Service();
  const user = useSelector(getUser);
  useAuth0User();

  if (isLoading || user.isLoading) {
    return <Loader />;
  }
  if (error) {
    return <Box>Authentication Error: {error.message}</Box>;
  }

  return (
    <>
      <CssBaseline />
      <GlobalStyles styles={globalStylesOverride} />
      <Box sx={styles.root}>
        <BaseSnackbar />
        <Navbar>{children}</Navbar>
      </Box>
      {MODE === 'development' && (
        <ReactQueryDevtools position="bottom" buttonPosition="bottom-left" />
      )}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
