export const styles = {
  root: (theme) => ({
    position: 'relative',
    width: '100%',
    borderRadius: 2,
    backgroundColor: theme.palette.background.dark,
  }),
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    py: 1,
    px: 1.5,
    borderBottom: (theme) =>
      `1px solid ${theme.palette.background.contrast.dark}`,
  },
  title: {
    fontWeight: 'bold',
  },
  headerActions: {
    display: 'flex',
    gap: 1,
  },
  content: {
    px: 1.5,
    py: 0.5,
  },
  footer: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    px: 1.5,
    py: 0.25,
    borderTop: (theme) => `1px solid ${theme.palette.background.contrast.dark}`,
  },
};
