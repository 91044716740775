import {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getAnalyticsVisibility, getFiltersVisibility} from '@/selectors';
import {setViewportBoundingBox} from '@/store/modules/filters/actions';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';
import {useRouter} from '@/hooks/useRouter';

export const useMapResize = () => {
  const dispatch = useDispatch();
  const {getBoundingBox, resizeMap} = useMapboxGL();
  const isAnalyticsOpen = useSelector(getAnalyticsVisibility);
  const filtersOpen = useSelector(getFiltersVisibility);
  const {isReportRoute} = useRouter();

  const handleResize = useCallback(
    ({target: map}) => {
      map.resize();
      if (!isReportRoute) {
        dispatch(setViewportBoundingBox(map.getBounds()));
      }
    },
    [dispatch, isReportRoute],
  );

  useEffect(() => {
    const handleWindowResize = () => {
      resizeMap();
      if (!isReportRoute) {
        dispatch(setViewportBoundingBox(getBoundingBox()));
      }
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [
    dispatch,
    getBoundingBox,
    resizeMap,
    filtersOpen,
    isAnalyticsOpen,
    isReportRoute,
  ]);

  return {handleResize};
};
