export const globalStylesOverride = (theme) => ({
  '&::-webkit-scrollbar': {
    width: '5px',
    height: '5px',
    backgroundColor: 'transparent',
  },
  '&:hover::-webkit-scrollbar': {
    width: '5px',
    height: '5px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: `${theme.palette.primary.main}59`,
    borderRadius: '20px',
  },
  '&::-webkit-scrollbar-thumb:active': {
    backgroundColor: `${theme.palette.primary.main}B3`,
  },
  '.mapboxgl-ctrl-scale': {
    backgroundColor: `${theme.palette.secondary.main}4D`,
    color: theme.palette.common.black,
  },
  '.mapboxgl-popup-content': {
    background: `${theme.palette.secondary.dark}E6`,
    boxShadow: `0 10px 2px ${theme.palette.common.black}1A`,
    padding: 0,
    cursor: 'default',
    fontSize: '0.625rem',
  },
  '.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip': {
    borderTopColor: `${theme.palette.secondary.dark}E6`,
  },
  '.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip': {
    borderTopColor: `${theme.palette.secondary.dark}E6`,
  },
  '.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip': {
    borderTopColor: `${theme.palette.secondary.dark}E6`,
  },
  '.mapboxgl-popup-anchor-top .mapboxgl-popup-tip': {
    borderBottomColor: `${theme.palette.secondary.dark}E6`,
  },
  '.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip': {
    borderBottomColor: `${theme.palette.secondary.dark}E6`,
  },
  '.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip': {
    borderBottomColor: `${theme.palette.secondary.dark}E6`,
  },
  '#popup-dynamic': {
    border: `4px solid ${theme.palette.background.default}`,
  },
  '.popupItem': {
    '&:hover': {
      color: `${theme.palette.primary.main}!important`,
      textDecoration: 'none !important',
    },
  },
  '.custom-attribution': {
    bottom: 0,
    right: 0,
    position: 'absolute',
    background: 'transparent',
    paddingInline: '8px',
    paddingBlock: '2px',
  },
  '.mapboxgl-ctrl-bottom-right': {
    bottom: '16px',
  },
  '.mapboxgl-ctrl-group': {
    backgroundColor: `${theme.palette.background.light} !important`,
    border: `1px solid ${theme.palette.divider} !important`,
    borderRadius: '4px !important',
  },
  '.mapboxgl-ctrl-group button:last-child': {
    borderTop: 'none !important',
  },
  '.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon': {
    backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iI2ZhZmFmYSIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMjQsMTI4YTgsOCwwLDAsMS04LDhIMTM2djgwYTgsOCwwLDAsMS0xNiwwVjEzNkg0MGE4LDgsMCwwLDEsMC0xNmg4MFY0MGE4LDgsMCwwLDEsMTYsMHY4MGg4MEE4LDgsMCwwLDEsMjI0LDEyOFoiPjwvcGF0aD48L3N2Zz4=")`,
  },
  '.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon': {
    backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iI2ZhZmFmYSIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMjQsMTI4YTgsOCwwLDAsMS04LDhINDBhOCw4LDAsMCwxLDAtMTZIMjE2QTgsOCwwLDAsMSwyMjQsMTI4WiI+PC9wYXRoPjwvc3ZnPg==")`,
  },
});
