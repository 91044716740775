export const styles = {
  root: (theme) => ({
    overflow: 'auto',
    height: '100%',
    paddingBottom: 2,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  grid: {
    display: 'flex',
    flexGrow: 1,
  },
};
