export const styles = {
  root: (theme) => ({
    paddingY: 1,
    paddingX: 2,
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.light,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
  textBox: {
    height: '40px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    justifyContent: 'center',
  },
  text: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  prefixIcon: {
    marginRight: 2,
    display: 'flex',
    alignItems: 'center',
  },
  suffixIcon: {
    marginLeft: 2,
    display: 'flex',
    alignItems: 'center',
  },
};
