import React, {memo} from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import {Typography} from '@mui/material';

const useStyles = () => ({
  button: {
    marginTop: 1,
    marginRight: 1,
    border: '1px solid transparent',
    height: '24px',
    fontSize: '0.875rem',
  },
  firstChip: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginRight: 0,
    width: '75px',
  },
  middleChip: {
    borderRadius: 0,
    marginRight: 0,
    width: '75px',
  },
  lastChip: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginRight: 0,
    width: '75px',
  },
  outlined: (theme) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    '& .MuiChip-label': {
      color: theme.palette.primary.main,
    },
  }),
  action: (theme) => ({
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: `${theme.palette.background.light}!important`,
    },
  }),
  chipProgress: {
    position: 'absolute',
    right: '50%',
  },
});

function ButtonChip({
  selected,
  isAction,
  loading,
  disabled,
  label,
  size,
  connected,
  position,
  color,
  selectedColor,
  ...props
}) {
  const styles = useStyles();

  return (
    <Chip
      sx={[
        styles.button,
        selected ? {} : styles.outlined,
        isAction && !selected ? styles.action : {},
      ]}
      color={selected ? selectedColor : color}
      variant={selected ? 'default' : 'outlined'}
      icon={
        loading ? <CircularProgress sx={styles.chipProgress} size={16} /> : null
      }
      disabled={loading || disabled}
      label={<Typography variant="caption">{label}</Typography>}
      size={size || 'medium'}
      style={connected ? styles[`${position}Chip`] : {}}
      {...props}
    />
  );
}

ButtonChip.propTypes = {
  selected: PropTypes.bool,
  isAction: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  connected: PropTypes.bool,
  position: PropTypes.oneOf(['first', 'middle', 'last']),
  color: PropTypes.oneOf(['primary', 'default', 'info']),
  selectedColor: PropTypes.oneOf(['primary', 'default', 'info']),
};

ButtonChip.defaultProps = {
  connected: false,
  position: 'middle',
  color: 'default',
  selectedColor: 'primary',
};

export default memo(ButtonChip);
