import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

// MUI Components
import {Box, useTheme} from '@mui/material';

// Styles and constants
import {styles} from '@/components/common/typography/TextLimit.styles';
import {useTranslation} from '@/hooks/useTranslation';

function TextLimit({text, linesLimit, sx}) {
  const [showAll, setShowAll] = useState(false);
  const [boxCollapsedHeight, setBoxCollapsedHeight] = useState('unset');
  const [isLimitReached, setIsLimitReached] = useState(false);
  const boxRef = useRef(null);

  const {getI18N} = useTranslation();
  const theme = useTheme();

  const {showLess, showMore} = getI18N('textLimit');

  const handleClick = () => {
    if (!isLimitReached) return;
    setShowAll((prev) => !prev);
  };

  /**
   * Calculates the maximum height of the text box based on the number of lines allowed and the line-height of the text.
   * It then defines the max-height that should have the box when is collapsed and save it on the boxCollapsedHeight state.
   * It also checks if the actual height (scrollHeight) of the text is greater than the maximum height.
   * If it is, then it sets the isLimitReached state to true, indicating that the text has exceeded the maximum number of lines allowed.
   */
  const updateBoxLimit = useCallback(() => {
    if (boxRef.current) {
      const {scrollHeight} = boxRef.current;

      const computedStyles = window.getComputedStyle(boxRef.current);
      const lineHeight = parseInt(computedStyles.lineHeight, 10);
      const maxHeight = lineHeight * linesLimit;

      setBoxCollapsedHeight(maxHeight);
      setIsLimitReached(scrollHeight > maxHeight);
    }
  }, [linesLimit]);

  useEffect(() => {
    updateBoxLimit();
  }, []);

  const backgroundColor =
    sx.backgroundColor || sx.background || theme.palette.background.default;

  return (
    <Box
      onClick={handleClick}
      sx={{
        ...styles.textBox,
        ...(!showAll && isLimitReached
          ? {...styles.textLimitBox, maxHeight: boxCollapsedHeight}
          : {}),
        sx,
        backgroundColor,
      }}
      ref={boxRef}>
      {text}
      {isLimitReached &&
        (!showAll ? (
          <Box sx={styles.moreButton} component="span">
            ...&nbsp;
            <Box sx={styles.moreButtonText} component="span">
              {showMore}
            </Box>
          </Box>
        ) : (
          <Box sx={styles.lessButton} component="span">
            &nbsp;{showLess}
          </Box>
        ))}
    </Box>
  );
}

export default memo(TextLimit);

TextLimit.propTypes = {
  text: PropTypes.string.isRequired,
  linesLimit: PropTypes.number,
  sx: PropTypes.any,
};

TextLimit.defaultProps = {
  sx: {},
  linesLimit: 3,
};
