export const API_USER = 'users/me';
export const API_USER_SETTINGS = 'users/settings';
export const API_USER_UPLOAD_PHOTO = 'users/me/photo';

export const API_ORGANIZATION = 'organizations/me';

export const API_KEY = 'api-keys';

export const API_SAVED_LOCATIONS = 'saved-locations';
export const API_SAVED_LOCATIONS_TAGS = 'saved-locations/tags';
export const API_SAVED_LOCATIONS_REPORT = 'saved-locations/report';

export const API_LOCATION = 'locations';
export const API_LOCATION_SEARCH = 'locations/search';
export const API_LOCATION_FIND = 'locations/find';
export const API_LOCATION_UPDATES = 'locations/updates';
export const API_LOCATION_VIEWPORT = 'locations/viewport/summary';
export const API_LOCATION_SUMMARY = 'locations/{id}/summary';
export const API_LOCATION_SUMMARIES = 'locations/summaries';
export const API_LOCATION_HOODS = 'locations/{id}/neighborhoods';
export const API_LOCATION_INSIGHTS = 'locations/{id}/insights';

export const API_THREAT = 'threats';
export const API_THREAT_RADIUS = 'threats/radius';
export const API_THREAT_RADIUS_COUNT = 'threats/radius/count';
export const API_THREAT_COUNT = 'threats/count';
export const API_THREAT_VIEWPORT_COUNT = 'threats/viewport/count';

export const API_THREAT_CATEGORIES = 'threat-categories';
export const API_SOURCE_CATEGORIES = 'source-categories';

export const API_KEY_TAKEAWAY = 'key-takeaways';
export const API_KEY_TAKEAWAY_RADIUS = 'key-takeaways/radius';

// Charts
export const API_CHART_QUINTILES = 'charts/threat-quintiles';

export const API_CHART_EVENT_SUMMARY = 'threats/summary';
export const API_CHART_EVENT_SUMMARY_RADIUS = 'threats/radius/summary';

export const API_CHART_SEVERITY = 'charts/severity-score-trend-analysis';

export const API_EVENT_BREAKDOWN = 'charts/event-breakdown';
export const API_EVENT_BREAKDOWN_RADIUS = 'charts/radius/event-breakdown';

export const API_THREAT_CATEGORIES_TREND = 'charts/threat-categories-trend';
export const API_THREAT_CATEGORIES_TREND_RADIUS =
  'charts/radius/threat-categories-trend';

export const API_SOURCE_CATEGORIES_TREND = 'charts/source-categories-trend';
export const API_SOURCE_CATEGORIES_TREND_RADIUS =
  'charts/radius/source-categories-trend';

export const API_TIME_SERIES = 'charts/time-series-analysis';
export const API_TIME_SERIES_RADIUS = 'charts/radius/time-series-analysis';

export const API_ROLLING_AVERAGE =
  'charts/time-series-analysis/rolling-average';
export const API_ROLLING_AVERAGE_RADIUS =
  'charts/radius/time-series-analysis/rolling-average';

export const API_HISTORICAL_MONTHLY = 'charts/historical-analysis/monthly';
export const API_HISTORICAL_MONTHLY_RADIUS =
  'charts/radius/historical-analysis/monthly';

export const API_HISTORICAL_DAY_OF_WEEK =
  'charts/historical-analysis/day-of-week';
export const API_HISTORICAL_DAY_OF_WEEK_RADIUS =
  'charts/radius/historical-analysis/day-of-week';

export const API_HISTORICAL_TIME_OF_DAY =
  'charts/historical-analysis/time-of-day';
export const API_HISTORICAL_TIME_OF_DAY_RADIUS =
  'charts/radius/historical-analysis/time-of-day';
export const API_SAVED_LOCATIONS_BREAKDOWN_BY_TYPE =
  'charts/saved-locations/breakdown-by-type';
export const API_SAVED_LOCATIONS_BREAKDOWN_BY_TAG =
  'charts/saved-locations/breakdown-by-tag';

// Change Charts
export const API_CHANGE_SOURCES = 'change/sources';
export const API_CHANGE_RADIUS_SOURCES = 'change/radius/sources';
export const API_CHANGE_TRENDLINE = 'change/trendline';
export const API_CHANGE_RADIUS_TRENDLINE = 'change/radius/trendline';
export const API_CHANGE_PRIOR_YEAR = 'change/prior-year';
export const API_CHANGE_RADIUS_PRIOR_YEAR = 'change/radius/prior-year';
export const API_CHANGE_PRIOR_PERIOD = 'change/prior-period';
export const API_CHANGE_RADIUS_PRIOR_PERIOD = 'change/radius/prior-period';
export const API_CHANGE_CATEGORY_CONTRIBUTION = 'change/category-contribution';
export const API_CHANGE_RADIUS_CATEGORY_CONTRIBUTION =
  'change/radius/category-contribution';
export const API_CHANGE_LOCATIONS_MOST = 'change/locations-most-change';
export const API_CHANGE_DISTRIBUTION = 'change/saved-locations/distribution';
export const API_CHANGE_DISTRIBUTION_BY_TYPE =
  'change/saved-locations/distribution-by-type';
export const API_CHANGE_DISTRIBUTION_BY_TAG =
  'change/saved-locations/distribution-by-tag';
export const API_CHANGE_BUCKETS =
  'change/saved-locations/distributions/buckets';

export const API_REPORTS = 'reports';
export const API_REPORTS_AVAILABLE = 'reports/available';

// Population & Area
export const API_POPULATION = 'population/population';
export const API_POPULATION_RADIUS = 'population/population/radius';

// Threat Score
export const API_THREAT_SCORE = 'threat-scores';
export const API_THREAT_SCORE_RADIUS = 'threat-scores/radius';
