import {Skeleton} from '@mui/material';
import PropTypes from 'prop-types';

function BaseSkeleton({children, variant, width, height, sx}) {
  return (
    <Skeleton
      variant={variant}
      width={width}
      height={height}
      sx={(theme) => ({
        ...(typeof sx === 'function' ? sx(theme) : sx),
        backgroundColor: theme.palette.background.light,
      })}>
      {children}
    </Skeleton>
  );
}

BaseSkeleton.defaultProps = {
  variant: 'rounded',
  width: '100%',
  sx: {},
};

BaseSkeleton.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default BaseSkeleton;
