import {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import BaseRadioGroup from '@/components/common/checkboxes/BaseRadioGroup';
import PeriodAverage from '@/components/analytics/charts/PeriodAverage';
import {useTranslation} from '@/hooks/useTranslation';
import {
  API_HISTORICAL_DAY_OF_WEEK,
  API_HISTORICAL_DAY_OF_WEEK_RADIUS,
  API_HISTORICAL_MONTHLY,
  API_HISTORICAL_MONTHLY_RADIUS,
  API_HISTORICAL_TIME_OF_DAY,
  API_HISTORICAL_TIME_OF_DAY_RADIUS,
} from '@/hooks/api/constants';
import {useChart} from '@/hooks/api/useChart';
import {useReportURL} from '@/hooks/report/useReportURL';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';

function PeriodAverageSection({id, granularity, sx, onlyCharts, settings}) {
  const [period, setPeriod] = useState('month');
  const {locale, getI18N} = useTranslation();
  const {reportURL} = useReportURL();
  const {monthOfYear, dayOfWeek, timeOfDay} = getI18N('chartSelection');

  const periodOptions = useMemo(
    () => [
      {
        label: monthOfYear,
        value: 'month',
      },
      {
        label: dayOfWeek,
        value: 'day',
      },
      {
        label: timeOfDay,
        value: 'time',
      },
    ],
    [locale],
  );

  // Downloads
  const title = getI18N('threatAnalytics.chartTitle12');

  const downloadTitle = useMemo(() => {
    if (period === 'month') return `${title} - ${monthOfYear}`;
    if (period === 'day') return `${title} - ${dayOfWeek}`;
    if (period === 'time') return `${title} - ${timeOfDay}`;
  }, [period, locale]);

  const {
    data: monthlyData,
    error: monthlyError,
    isFetching: monthlyIsFetching,
    downloadCSV: monthlyDownloadCSV,
    copyCSV: monthlyCopyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title: downloadTitle,
    path:
      granularity === 'radius'
        ? API_HISTORICAL_MONTHLY_RADIUS
        : API_HISTORICAL_MONTHLY,
    granularity,
    customParams: {
      timeAggregation: 'monthly',
    },
  });

  const {
    data: weeklyData,
    error: weeklyError,
    isFetching: weeklyIsFetching,
    downloadCSV: downloadWeeklyCSV,
    copyCSV: copyWeeklyCSV,
  } = useChart({
    id,
    title: downloadTitle,
    path:
      granularity === 'radius'
        ? API_HISTORICAL_DAY_OF_WEEK_RADIUS
        : API_HISTORICAL_DAY_OF_WEEK,
    granularity,
  });

  const {
    data: timeData,
    error: timeError,
    isFetching: timeIsFetching,
    downloadCSV: downloadTimeCSV,
    copyCSV: copyTimeCSV,
  } = useChart({
    id,
    title: downloadTitle,
    path:
      granularity === 'radius'
        ? API_HISTORICAL_TIME_OF_DAY_RADIUS
        : API_HISTORICAL_TIME_OF_DAY,
    granularity,
  });

  const isLoading = useMemo(
    () => monthlyIsFetching && weeklyIsFetching && timeIsFetching,
    [monthlyIsFetching, weeklyIsFetching, timeIsFetching],
  );

  const isAnyLoading = useMemo(
    () => monthlyIsFetching || weeklyIsFetching || timeIsFetching,
    [monthlyIsFetching, weeklyIsFetching, timeIsFetching],
  );

  const handleDownloadCSV = () => {
    if (period === 'month') monthlyDownloadCSV();
    else if (period === 'day') downloadWeeklyCSV();
    else if (period === 'time') downloadTimeCSV();
  };

  const handleCopyCSV = () => {
    if (period === 'month') monthlyCopyCSV();
    else if (period === 'day') copyWeeklyCSV();
    else if (period === 'time') copyTimeCSV();
  };

  const data = useMemo(() => {
    if (period === 'month') return monthlyData;
    if (period === 'day') return weeklyData;
    if (period === 'time') return timeData;
  }, [period, monthlyData, weeklyData, timeData]);

  const enabledPeriods = useMemo(
    () =>
      periodOptions.map((option) => {
        const disabled =
          (option.value === 'month' && Boolean(monthlyError)) ||
          (option.value === 'day' && Boolean(weeklyError)) ||
          (option.value === 'time' && Boolean(timeError));

        const loading =
          (option.value === 'month' && Boolean(monthlyIsFetching)) ||
          (option.value === 'day' && Boolean(weeklyIsFetching)) ||
          (option.value === 'time' && Boolean(timeIsFetching));

        return {
          ...option,
          disabled,
          loading,
        };
      }),
    [
      monthlyError,
      weeklyError,
      timeError,
      monthlyIsFetching,
      weeklyIsFetching,
      timeIsFetching,
      periodOptions,
    ],
  );

  // Set period to first enabled period if current period is disabled
  useEffect(() => {
    const selected = enabledPeriods.find((option) => option.value === period);

    if (selected?.disabled) {
      const firstEnabled = enabledPeriods.find((option) => !option.disabled);
      setPeriod(firstEnabled?.value);
    }

    if (reportURL?.customFilters) {
      setPeriod(reportURL?.customFilters.period);
    }
  }, [enabledPeriods, period, reportURL]);

  const isError = useMemo(
    () =>
      (period === 'month' && monthlyError) ||
      (period === 'day' && weeklyError) ||
      (period === 'time' && timeError),
    [period, monthlyError, weeklyError, timeError],
  );

  useEffect(() => {
    setPeriod(settings.period);
  }, [settings]);

  if (onlyCharts)
    return (
      <ReportChartWrapper isLoading={isAnyLoading} error={Boolean(isError)}>
        <PeriodAverage
          height={sx.height}
          width={sx.width}
          type={period}
          data={data}
          isFetching={isLoading}
        />
      </ReportChartWrapper>
    );

  if (isError) return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        title={title}
        tooltip="periodAverage"
        downloadCSV={handleDownloadCSV}
        copyCSV={handleCopyCSV}
        downloadImage={downloadImage}
        copyImage={copyImage}
        chart={
          <PeriodAverage type={period} data={data} isLoading={isLoading} />
        }
        controls={
          <BaseRadioGroup
            dense
            onChange={(value) => setPeriod(value)}
            selected={period}
            options={enabledPeriods}
          />
        }
        settings={{id, period}}
      />
    </Box>
  );
}

PeriodAverageSection.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onlyCharts: PropTypes.bool,
  settings: PropTypes.object,
};

export default PeriodAverageSection;
