export const styles = {
  slider: {
    '& .MuiSlider-thumb': {
      backgroundColor: 'primary.main',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
    },
    '> .MuiSlider-mark': {
      backgroundColor: 'primary.main',
      width: 4,
      height: 4,
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '& .MuiSlider-markActive': {
      display: 'none',
    },
    '& .MuiSlider-valueLabel': {
      backgroundColor: 'transparent',
      color: 'white',
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: 1.2,
      padding: 0,
    },
    '& .MuiSlider-valueLabelOpen': {
      transform: 'translateY(-50%) scale(1)',
    },
    // Style first label
    '> .MuiSlider-markLabel': {
      transform: 'translateX(-10px)',
    },

    // Style other labels
    '> .MuiSlider-markLabel ~ .MuiSlider-markLabel': {
      transform: 'translateX(-50%)',
    },

    '.MuiSlider-markLabel:not(:has(~ .MuiSlider-markLabel))': {
      transform: 'translateX(-100%)',
    },
  },
};
