import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  API_LOCATION,
  API_LOCATION_HOODS,
  API_LOCATION_FIND,
} from '@hooks/api/constants';
import {useApi} from '@hooks/api/useApi';
import {useMixpanel} from '@hooks/useMixpanel';
import {
  getSelectedCategories,
  getSelectedMonths,
  getSelectedTimeslices,
  getActiveCity,
  getFiltersLoading,
  getIsFlying,
  getViewTypeWorld,
  getSelectedSources,
  getLocation,
  getIsMapReady,
} from '@/selectors';
import {
  toggleLoadingNBHD,
  setNeighborhoodsLoadingTimestamp,
  setLocationSettings,
  setLocationLoading,
} from '@/store/modules/filters/actions';
import {setLocationDistricts} from '@/store/modules/districts/actions';
import {useDates} from '@/hooks/useDates';
import {useQuery} from '@/hooks/useQuery';
import {useMapHandlers} from '@/hooks/map/useMapHandlers';

export const useLocation = ({enabled}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const {getISOfromDate, getISOtoDate} = useDates();
  const {useGetQuery} = useApi();
  const {track} = useMixpanel();
  const {queryLocation} = useMapHandlers();

  const sources = useSelector(getSelectedSources);
  const categories = useSelector(getSelectedCategories);
  const timeSlices = useSelector(getSelectedTimeslices);
  const months = useSelector(getSelectedMonths);
  const activeCity = useSelector(getActiveCity);
  const isFlying = useSelector(getIsFlying);
  const filtersLoading = useSelector(getFiltersLoading);
  const isWorldView = useSelector(getViewTypeWorld);
  const {id: locationId} = useSelector(getLocation);
  const {name: locationName} = useSelector(getLocation);
  const isMapReady = useSelector(getIsMapReady);

  const [locationQuery, setLocationQuery] = useState('');
  const [queryEnabled, setQueryEnabled] = useState(true);

  const {data: location, isFetching: isFetchingLocation} = useGetQuery({
    path: API_LOCATION,
    query: activeCity,
    config: {
      enabled: activeCity !== null && !isWorldView && !isFlying && enabled,
    },
  });

  useEffect(() => {
    dispatch(setLocationLoading(isFetchingLocation));
    if (!isEmpty(location) && !isFetchingLocation) {
      dispatch(setLocationSettings(location));
    }
  }, [dispatch, location, isFetchingLocation]);

  const {data: locationFound} = useGetQuery({
    path: API_LOCATION_FIND,
    params: {name: locationQuery},
    config: {
      enabled: queryEnabled && Boolean(locationQuery) && enabled,
    },
  });

  useEffect(() => {
    if (
      locationQuery &&
      queryEnabled &&
      !isEmpty(locationFound) &&
      isMapReady
    ) {
      queryLocation(locationFound);
      setQueryEnabled(false);
      track('User query location', {
        location: locationFound.name,
        ...locationFound,
      });
    }
  }, [locationQuery, queryEnabled, isMapReady, locationFound]);

  useEffect(() => {
    setLocationQuery(query.get('location'));
  }, []);

  const handleNeighborhoodsLoading = (timestamp) => {
    dispatch(setNeighborhoodsLoadingTimestamp(timestamp));
  };

  const {data: hoods, isFetching: isFetchingHoods} = useGetQuery({
    path: API_LOCATION_HOODS.replace('{id}', locationId),
    params: {
      fromDate: getISOfromDate(),
      toDate: getISOtoDate(),
      sources: JSON.stringify(sources),
      categories: JSON.stringify(categories),
      daytimes: JSON.stringify(timeSlices),
      months: JSON.stringify(months),
    },
    config: {
      enabled:
        !isWorldView &&
        !isFlying &&
        !filtersLoading &&
        Boolean(locationId ?? false) &&
        enabled,
    },
  });

  useEffect(() => {
    if (hoods?.length > 0 && !isFetchingHoods) {
      dispatch(setLocationDistricts(hoods));
    } else {
      dispatch(setLocationDistricts([]));
    }
  }, [dispatch, hoods, isFetchingHoods]);

  useEffect(() => {
    dispatch(toggleLoadingNBHD(isFetchingHoods));
  }, [dispatch, isFetchingHoods]);

  useEffect(() => {
    if (locationName) {
      track('Map Location Changed', {location: locationName});
    }
  }, [dispatch, locationName]);

  return {handleNeighborhoodsLoading};
};
