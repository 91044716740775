import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ChartBar} from '@phosphor-icons/react';

import {
  getThreatAnalyticsAreaDisplay,
  getIsDataVisible,
  getViewTypeWorld,
  getLocation,
  getDrawer,
} from '@/selectors';
import {setAnalyticsPanelStatus} from '@/store/modules/drawer/actions';

import {useRouter} from '@/hooks/useRouter';
import {BaseIconButton} from '@/components/common/buttons';
import {styles} from '@/components/navigation/list/TopNavigation.styles';
import {useMixpanel} from '@/hooks/useMixpanel';

function ActionAnalytics() {
  const dispatch = useDispatch();
  const {isMapRoute} = useRouter();
  const {track} = useMixpanel();

  const {id: locationId} = useSelector(getLocation);
  const isDataVisible = useSelector(getIsDataVisible);
  const isRadiusVisible = useSelector(getThreatAnalyticsAreaDisplay);
  const isWorldView = useSelector(getViewTypeWorld);
  const {analyticsOpen} = useSelector(getDrawer);

  const disableAnalytics = useMemo(
    () =>
      !(
        isMapRoute &&
        isDataVisible &&
        !isWorldView &&
        (isRadiusVisible || Boolean(locationId >= 0))
      ),
    [isDataVisible, isWorldView, isRadiusVisible, locationId, isMapRoute],
  );

  const handleAnalytics = useCallback(() => {
    dispatch(setAnalyticsPanelStatus(!analyticsOpen));

    if (analyticsOpen) {
      track('Analytics Drawer Closed');
    } else {
      track('Analytics Drawer Opened');
    }
  }, [dispatch, analyticsOpen]);

  return (
    isMapRoute && (
      <BaseIconButton
        icon={ChartBar}
        onClick={handleAnalytics}
        disabled={disableAnalytics}
        sx={analyticsOpen ? styles.rightIconActive : styles.rightIcon}
      />
    )
  );
}

export default ActionAnalytics;
