import {useCallback, useState} from 'react';
import {debounce} from 'lodash';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux';
import {ThumbsUp, ThumbsDown} from '@phosphor-icons/react';
import {BaseIconButton} from '@/components/common/buttons';
import {useTranslation} from '@/hooks/useTranslation';
import {useMixpanel} from '@/hooks/useMixpanel';
import {convertDistance} from '@/utils/utils';
import {styles} from '@/components/common/UserFeedback.styles';
import {
  getDistricts,
  getLocation,
  getClickedLocation,
  getThreatAnalyticsArea,
  getUser,
  getGranularity,
} from '@/selectors';

function UserFeedback({context}) {
  const {getI18N} = useTranslation();
  const {track} = useMixpanel();
  const {message} = getI18N('userFeedback');

  const {selectedDistricts} = useSelector(getDistricts);
  const {latitude, longitude, radius} = useSelector(getThreatAnalyticsArea);
  const {id: locationId, name: locationName} = useSelector(getLocation);
  const {id: savedLocationId, name: savedName} =
    useSelector(getClickedLocation);
  const {units} = useSelector(getUser);
  const granularity = useSelector(getGranularity);

  const Rating = {
    ThumbsUp: 'thumbs-up',
    ThumbsDown: 'thumbs-down',
  };

  const [rating, setRating] = useState(undefined);
  const [feedback, setFeedback] = useState([]);

  const sendFeedback = useCallback(
    debounce((feedbackArray) => {
      track('User Feedback', {
        context,
        feedback: feedbackArray,
        locationId,
        location:
          savedName ||
          selectedDistricts?.map(({displayName}) => displayName).join(', ') ||
          locationName,
        granularity,
        ...(granularity === 'radius' && {
          latitude,
          longitude,
          radius: convertDistance(radius, units),
          units,
          savedLocationId,
        }),
      });
      setRating(undefined);
      setFeedback([]);
    }, 2000),
    [
      context,
      locationId,
      savedName,
      selectedDistricts,
      locationName,
      granularity,
      latitude,
      longitude,
      radius,
      units,
      savedLocationId,
    ],
  );

  const handleFeedback = useCallback(
    (latestFeedback) => {
      const newFeedback = [...feedback, latestFeedback];
      setRating(latestFeedback);
      setFeedback(newFeedback);
      sendFeedback(newFeedback);
    },
    [feedback],
  );

  return (
    <Box sx={styles.root}>
      <Typography variant="body2" sx={styles.message}>
        {message}
      </Typography>
      <BaseIconButton
        icon={ThumbsUp}
        size={24}
        sx={
          rating === Rating.ThumbsUp
            ? styles.clickedIconButton
            : styles.iconButton
        }
        onClick={() => handleFeedback(Rating.ThumbsUp)}
      />
      <BaseIconButton
        icon={ThumbsDown}
        size={24}
        sx={
          rating === Rating.ThumbsDown
            ? styles.clickedIconButton
            : styles.iconButton
        }
        onClick={() => handleFeedback(Rating.ThumbsDown)}
      />
    </Box>
  );
}

UserFeedback.propTypes = {
  context: PropTypes.string,
};

export default UserFeedback;
