import {useTranslation} from '@/hooks/useTranslation';

export const useChartTranslation = () => {
  const {getI18N} = useTranslation();

  const {
    none,
    office,
    hotel,
    event,
    restaurant,
    travel,
    warehouse,
    parking,
    service_area,
    point_of_interest,
    facility,
    other,
  } = getI18N('savedLocations');
  const savedLocationTypeTranslations = {
    office,
    hotel,
    event,
    restaurant,
    travel,
    warehouse,
    parking,
    service_area,
    point_of_interest,
    facility,
    other,
  };
  const savedLocationTypes = Object.keys(savedLocationTypeTranslations);
  const {degreeOfChangeOptions} = getI18N('myLocationsAnalysis.filters');

  const translateLocationTag = (tag) => tag || none;

  const translateLocationType = (type) =>
    savedLocationTypes.includes(type)
      ? savedLocationTypeTranslations[type]
      : type;

  const translateDegreeOfChange = (degree) =>
    degreeOfChangeOptions.find(({value}) => value === degree)?.name;

  return {
    translateLocationTag,
    translateLocationType,
    translateDegreeOfChange,
  };
};
