export const styles = {
  innerBox: {
    paddingY: 2,
  },
  legendBox: {
    marginTop: 1,
    marginLeft: 4,
  },
  legend: {
    backgroundColor: 'transparent',
  },
};
