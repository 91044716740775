export const styles = {
  card: (theme) => ({
    minHeight: 243,
    borderRadius: '8px',
    backgroundColor: theme.palette.background.light,
    backgroundImage: 'none',
    position: 'relative',
    '& .MuiCardHeader-root': {
      '& .MuiCardHeader-content': {
        width: '60%',
      },
    },
    '& .MuiCardActions-root': {
      '& >:not(:first-of-type)': {
        marginLeft: '2px',
      },
    },
  }),
  cardHeader: (theme) => ({
    padding: '1rem 1rem 0 1rem',
    borderBottom: `1px solid ${theme.palette.background.light}`,
    alignItems: 'start',
    backgroundImage: 'none',
    '& .MuiCardHeader-avatar': {
      marginRight: '0.5rem',
    },
    minHeight: '77px',
  }),
  cardActions: {
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '0',
    width: '100%',
    padding: 0,
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeaderTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardHeaderTitle: {
    fontWeight: 'bold',
    lineHeight: 1.3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  cardHeaderSubtitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  cardHeaderSubtitleAlt: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'grey.400',
  },
  cardContent: {
    padding: '0 1rem 3.625rem 1rem',
    justifyContent: 'center',
    gridContainer: (theme) => ({
      border: `1px solid ${theme.palette.secondary.dark}`,
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      flexWrap: 'nowrap',
      height: '60px',
    }),
    gridItems: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 1,
    },
  },
  cardContentTags: {
    display: 'flex',
    padding: '0.5rem 1rem 0.5rem 1rem',
    minHeight: '48px',
    maxHeight: '48px',
    gap: 1,
  },
  cardButton: (theme) => ({
    height: '40px',
    textTransform: 'none',
    width: '100%',
    borderRadius: '0',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'black',
    },
  }),
  changeDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 0.5,
  },
  cardContentText: {
    minHeight: '57px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  chipContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    gap: '8px',
    minHeight: '32px',
    flexWrap: 'wrap',
  },
  chip: {
    backgroundColor: (theme) => theme.palette.background.contrast.dark,
    expanded: {
      backgroundColor: (theme) => theme.palette.background.light,
    },
    hidden: {
      opacity: 0,
    },
    popover: (theme) => ({
      backgroundColor: theme.palette.background.contrast.dark,
      margin: '0.25rem',
    }),
  },
  remainingChips: {
    backgroundColor: (theme) => theme.palette.background.contrast.dark,
    '&:hover': {
      backgroundColor: (theme) => theme.palette.background.light,
    },
  },
  popover: (theme, width) => ({
    '& .MuiPaper-root': {
      backgroundImage: 'none',
      backgroundColor: theme.palette.background.dark,
      borderRadius: '8px',
      padding: '1rem',
      width: `${width}px`,
      marginTop: '5px',
    },
  }),
  relevantInfo: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  divider: (theme) => ({
    borderColor: `1px solid ${theme.palette.background.contrast.dark}`,
    margin: '0.625rem 0',
  }),
};
