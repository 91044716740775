import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {FunnelSimple} from '@phosphor-icons/react';

import {getViewTypeWorld, getDrawer} from '@/selectors';
import {
  setMapFiltersStatus,
  setSavedLocationFiltersStatus,
  setMyLocationsAnalysisFiltersStatus,
} from '@/store/modules/drawer/actions';

import {useRouter} from '@/hooks/useRouter';
import {useMixpanel} from '@/hooks/useMixpanel';
import {BaseIconButton} from '@/components/common/buttons';
import {styles} from '@/components/navigation/list/TopNavigation.styles';

function ActionFilters() {
  const {track} = useMixpanel();
  const dispatch = useDispatch();
  const {isMapRoute, isSavedRoute, isMyLocationsAnalysisRoute} = useRouter();

  const isWorldView = useSelector(getViewTypeWorld);
  const {filtersOpen} = useSelector(getDrawer);
  const {isMyLocationsAnalysisFiltersOpen} = useSelector(getDrawer);

  const handleFilters = useCallback(() => {
    if (isMapRoute) {
      track(`Map Filters Drawer ${filtersOpen ? 'Closed' : 'Opened'}`);
      dispatch(setMapFiltersStatus(!filtersOpen));
    }

    if (isSavedRoute) {
      dispatch(setSavedLocationFiltersStatus());
    }

    if (isMyLocationsAnalysisRoute) {
      dispatch(
        setMyLocationsAnalysisFiltersStatus(!isMyLocationsAnalysisFiltersOpen),
      );
    }
  }, [
    dispatch,
    filtersOpen,
    isMapRoute,
    isSavedRoute,
    isMyLocationsAnalysisRoute,
    isMyLocationsAnalysisFiltersOpen,
  ]);

  return (
    (isMapRoute || isSavedRoute || isMyLocationsAnalysisRoute) && (
      <BaseIconButton
        disabled={isWorldView && isMapRoute}
        icon={FunnelSimple}
        sx={
          filtersOpen || isMyLocationsAnalysisFiltersOpen
            ? styles.leftIconActive
            : styles.leftIcon
        }
        onClick={handleFilters}
      />
    )
  );
}

export default ActionFilters;
