export const styles = {
  columns: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    minHeight: 46,
    paddingInline: '1rem',
  },
  rowsContainer: {
    maxHeight: 'calc(70vh - 64px)',
    overflow: 'auto',
  },
  rows: (theme) => ({
    backgroundImage: 'none',
    backgroundColor: theme.palette.background.dark,
  }),
};
