import destination from '@turf/destination';
import distance from '@turf/distance';
import createCircle from '@turf/circle';
import bearing from '@turf/bearing';

export const getCircleGeoJSON = ({latitude, longitude, radius}, id) => ({
  type: 'FeatureCollection',
  features: [
    createCircle([longitude, latitude], radius, {
      steps: 359,
      units: 'meters',
      properties: {id: id ?? 'circle'},
    }),
  ],
});

export const getDestinationPoint = ({latitude, longitude, radius, bearing}) => {
  const {
    geometry: {
      coordinates: [lon, lat],
    },
  } = destination(
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [longitude, latitude],
      },
    },
    radius,
    bearing,
    {units: 'meters'},
  );
  return {
    latitude: lat,
    longitude: lon,
  };
};

export const getDistance = (
  {latitude: lat1, longitude: lon1},
  {latitude: lat2, longitude: lon2},
) =>
  distance(
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [lon1, lat1],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [lon2, lat2],
      },
    },
    {units: 'meters'},
  );

export const getBearing = (
  {latitude: lat1, longitude: lon1},
  {latitude: lat2, longitude: lon2},
) =>
  bearing(
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [lon1, lat1],
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [lon2, lat2],
      },
    },
  );
