export const styles = {
  card: (theme) => ({
    borderRadius: '8px',
    backgroundColor: theme.palette.background.light,
    backgroundImage: 'none',
  }),
  cardContent: {
    padding: 1,
    '&:last-child': {
      paddingBottom: 1,
    },
  },
  columnHeader: {
    fontWeight: 'bold',
  },
  headerCard: (theme) => ({
    borderRadius: '8px',
    backgroundColor: theme.palette.background.light,
    backgroundImage: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
  title: {
    display: 'flex',
    alignItems: 'center',
    icon: {
      paddingRight: '0.5rem',
    },
    text: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: 1.3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cardContentTags: {
    display: 'flex',
    padding: 0,
    gap: 1,
  },
  popover: (width) => ({
    '& .MuiPaper-root': {
      borderRadius: '8px',
      padding: '1rem',
      width: `${width}px`,
      marginTop: '5px',
    },
  }),
};
