import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import momentPropTypes from 'react-moment-proptypes';
import {Calendar} from '@phosphor-icons/react';

function DatePickerBO({views, label, onChange, value, minDate, maxDate}) {
  return (
    <DatePicker
      views={views}
      label={label}
      onChange={onChange}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      disableFuture
      data-testid="date-picker-from-date"
      slots={{openPickerIcon: Calendar}}
      slotProps={{
        textField: {
          variant: 'standard',
          InputProps: {
            sx: {
              fontSize: '0.875rem',
            },
          },
        },
        openPickerIcon: {
          fontSize: '1rem',
        },
      }}
    />
  );
}

DatePickerBO.propTypes = {
  views: PropTypes.arrayOf(PropTypes.oneOf(['day', 'month', 'year'])),
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: momentPropTypes.momentObj,
  minDate: momentPropTypes.momentObj,
  maxDate: momentPropTypes.momentObj,
};

export default memo(DatePickerBO);
