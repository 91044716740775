import {useCallback} from 'react';

import {useMixpanel} from '@hooks/useMixpanel';

export const useMapDrag = () => {
  const {track} = useMixpanel();

  const handleDragEnd = useCallback(
    ({target: map, viewState: {latitude, longitude, zoom}}) => {
      track('Drag/Move saved locations change map', {
        zoom,
        latitude,
        longitude,
        viewport: map.getBounds(),
      });
    },
    [],
  );

  return {handleDragEnd};
};
