import image from '@images/logo-bo.svg';
import {TOP_NAVBAR_HEIGHT, LEFT_NAVBAR_WIDTH} from '@/hooks/constants';

const iconStyles = (theme) => ({
  width: '36px',
  borderRadius: '8px',
  backgroundColor: 'transparent',
  color: theme.palette.secondary.main,
  '&:active, &:hover': {
    backgroundColor: theme.palette.background.light,
  },
});

export const styles = {
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 2,
  },
  leftIcon: (theme) => iconStyles(theme),
  leftIconActive: (theme) => ({
    ...iconStyles(theme),
    backgroundColor: theme.palette.background.light,
  }),
  rightIcon: (theme) => iconStyles(theme, 'left'), // for right icon use marginLeft
  rightIconActive: (theme) => ({
    ...iconStyles(theme, 'left'),
    backgroundColor: theme.palette.background.light,
  }),
  button: {
    boxShadow: 'none',
    textTransform: 'none',
    '&.MuiButtonBase-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  titleText: (theme) => ({
    color: theme.palette.text.secondary,
  }),
  locationText: (theme) => ({
    color: theme.palette.text.secondary,
    margin: '0 8px',
  }),
  logo: {
    height: `${TOP_NAVBAR_HEIGHT}px`,
    width: `${LEFT_NAVBAR_WIDTH}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url("${image}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  leftSideActions: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    paddingInline: 1,
  },
  locationActions: {
    display: 'flex',
    alignItems: 'center',
  },
};
