import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux';
import Typography from '@mui/material/Typography';
import {useFeatureAccess} from '@/hooks/useFeatureAccess';
import FeatureAccessModal from '@/components/feature-access/FeatureAccessModal';
import EnterpriseLabel from '@/components/common/typography/EnterpriseLabel';
import {useMixpanel} from '@/hooks/useMixpanel';
import {getOrganization, getUser} from '@/selectors';
import {styles} from '@/components/feature-access/FeatureAccessWrapper.styles';
import FeatureTeaserModal from '@/components/feature-access/FeatureTeaserModal';
import {useTranslation} from '@/hooks/useTranslation';
import BaseButton from '@/components/common/buttons/BaseButton';

function FeatureAccessWrapper({feature, action, ref, children}) {
  const {track} = useMixpanel();
  const {getFeatureAccess, getControlledFeature} = useFeatureAccess();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useSelector(getUser);
  const organization = useSelector(getOrganization);
  const [modalStatus, setModalStatus] = useState('initial');
  const {getI18N} = useTranslation();

  const {chartTeaser, explore} = getI18N('featureAccess');

  const controlledFeature = useMemo(
    () => getControlledFeature(feature),
    [feature, getControlledFeature],
  );
  const hasAction = useMemo(
    () => action && getFeatureAccess(feature, action),
    [action, feature, getFeatureAccess],
  );
  let isDisabled = useMemo(
    () => getFeatureAccess(feature, 'disable'),
    [feature, getFeatureAccess],
  );
  let hasTeaser = useMemo(
    () => getFeatureAccess(feature, 'tease'),
    [feature, getFeatureAccess],
  );
  let hasLabel = useMemo(
    () => getFeatureAccess(feature, 'label'),
    [feature, getFeatureAccess],
  );
  let isHidden = useMemo(
    () => getFeatureAccess(feature, 'hide'),
    [feature, getFeatureAccess],
  );

  if (hasAction) {
    isDisabled = action === 'disable';
    hasTeaser = action === 'tease';
    hasLabel = action === 'label';
    isHidden = action === 'hide';
  }

  const handleClose = useCallback(() => setIsModalOpen(false), []);

  const handleOpen = useCallback(() => setIsModalOpen(true), []);

  const handleControlledEvent = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsModalOpen(true);
  }, []);

  const handleSubmit = useCallback(() => {
    setModalStatus('requested');
    track('Enterprise Information Request', {
      message: `${user.firstName} ${user.lastName} from ${organization.name} (${user.email}),
      has requested further information on the following Enterprise capability: "${controlledFeature.mixpanel}".
      Please let the Sales Team know so they can reach out and provide additional information on Enterprise.`,
    });
  }, [user, organization, controlledFeature, track]);

  const controlledChildren = useMemo(
    () =>
      React.cloneElement(children || <div />, {
        onClick: handleControlledEvent,
        onChange: handleControlledEvent,
        color: 'inherit',
      }),
    [children, handleControlledEvent],
  );

  const renderFeature = useCallback(() => {
    if (hasLabel) {
      return (
        <Box sx={styles.root}>
          <Box
            ref={ref}
            sx={isDisabled ? styles.disabled : {}}
            onClick={isDisabled ? handleOpen : () => {}}>
            {controlledChildren}
          </Box>
          <EnterpriseLabel onClick={handleOpen} />
        </Box>
      );
    }
    if (isDisabled) {
      return (
        <Box ref={ref} sx={styles.disabled} onClick={handleOpen}>
          {controlledChildren}
        </Box>
      );
    }
    if (hasTeaser) {
      return (
        <Box sx={styles.banner}>
          <Typography variant="body2">{chartTeaser}</Typography>
          <BaseButton onClick={handleOpen}>{explore}</BaseButton>
        </Box>
      );
    }
    return children;
  }, [
    ref,
    children,
    hasLabel,
    isDisabled,
    hasTeaser,
    controlledChildren,
    chartTeaser,
    explore,
    handleOpen,
  ]);

  if (isHidden) return null;
  if (!feature) return children;

  return (
    <>
      {(hasLabel || isDisabled) && (
        <FeatureAccessModal
          status={modalStatus}
          isOpen={isModalOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      {hasTeaser && (
        <FeatureTeaserModal
          status={modalStatus}
          isOpen={isModalOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      {renderFeature()}
    </>
  );
}

FeatureAccessWrapper.propTypes = {
  feature: PropTypes.string.isRequired,
  action: PropTypes.string,
  children: PropTypes.node,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.any}),
  ]),
};

FeatureAccessWrapper.defaultProps = {
  children: null,
  feature: '',
};

export default FeatureAccessWrapper;
