import React from 'react';
import PropTypes from 'prop-types';

import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';

import BaseTooltip from '@/components/common/BaseTooltip';
import {styles} from '@/components/filters/FilterItem.styles';

function FilterItem({sx, label, actions, children, tooltip, label2}) {
  return (
    <Box
      sx={(theme) => ({
        ...styles.root(theme),
        ...(typeof sx === 'function' ? sx(theme) : sx),
      })}>
      <Box sx={styles.header}>
        <Box sx={styles.title} style={{width: actions ? 'auto' : '100%'}}>
          <Typography sx={styles.labelText} variant="body2">
            {label}
            {tooltip && <BaseTooltip message={tooltip} iconSize={14} />}
          </Typography>
          {label2}
        </Box>
        {actions}
      </Box>
      {children}
    </Box>
  );
}

FilterItem.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  label: PropTypes.any,
  children: PropTypes.any,
  actions: PropTypes.element,
  tooltip: PropTypes.string,
  label2: PropTypes.any,
};

export default FilterItem;
