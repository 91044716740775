import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Link, Box} from '@mui/material';
import {getUserSettings} from '@/selectors';
import {useRouter} from '@/hooks/useRouter';

const styles = {
  root: ({theme, color}) => ({
    color: theme.palette.common[color],
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  }),
};

function BaseAttribution() {
  const {mapStyle} = useSelector(getUserSettings);
  const {isMapRoute} = useRouter();

  const sx = useCallback(
    (theme) => {
      let color = 'white';
      if (!isMapRoute) {
        color = 'white';
      } else if (mapStyle !== 'map') {
        color = 'white';
      } else {
        color = 'black';
      }
      return styles.root({theme, color});
    },
    [mapStyle, isMapRoute],
  );

  return (
    <Box component="div" className="custom-attribution">
      <Link
        sx={sx}
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        href="https://www.mapbox.com/about/maps">
        © Mapbox
      </Link>
      &nbsp;&nbsp;
      <Link
        sx={sx}
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        href="https://www.openstreetmap.org/about/">
        © OpenStreetMap
      </Link>
      {/* &nbsp;&nbsp;
      <Link
        sx={sx}
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        href="https://apps.mapbox.com/feedback/?owner=baseoperations">
        Improve this map
      </Link> */}
    </Box>
  );
}

export default BaseAttribution;
