export const styles = {
  emptyMessageContainer: {
    height: '100px',
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  message: {
    paddingInline: 4,
  },
  savedLocationsContainer: {
    height: '200px',
  },
  threatLandscapeContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    paddingBlock: 4,
  },
  threatLandscapeTitle: {
    paddingBottom: 1,
    fontWeight: 'bold',
    paddingLeft: 4,
  },
  threatLandscape: {
    paddingLeft: 4,
  },
  threatLandscapeGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  threatChangeTitle: {
    paddingBottom: 0,
    fontWeight: 'bold',
    paddingLeft: 4,
  },
  threatChangeGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};
