import {useMemo} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material';

import {DownloadSimple} from '@phosphor-icons/react';

import {useTranslation} from '@/hooks/useTranslation';
import {useLocationsDistribution} from '@/hooks/api/useLocationsDistribution';
import {useChartDownloads} from '@/hooks/charts/useChartDownloads';

import {styles} from '@/components/saved-locations/charts/NumberOfLocationsByChange.styles';
import BaseXYChart from '@/components/common/charts/BaseXYChart';
import BaseBar from '@/components/common/charts/XYCharts/BaseBar';
import BaseCard from '@/components/common/cards/BaseCard';
import ChartOptionsDropdown from '@/components/analytics/ChartOptionsDropdown';
import {chartIds} from '@/components/saved-locations/constants';
import {useDegreeOfChange} from '@/hooks/api/useDegreeOfChange';

const chartId = chartIds.changeDistribution;

function NumberOfLocationsByChange() {
  const theme = useTheme();
  const {getI18N, getTooltipTranslation} = useTranslation();

  const {degreeOfChangeOptions} = getI18N('myLocationsAnalysis.filters');
  const {copyImageLabel, downloadImageLabel} = getI18N('chartOptions');
  const {barChartTitle, magnitudeOfChange, locations, indeterminateCaption} =
    getI18N('savedLocations');

  const {data, isFetching} = useLocationsDistribution();

  const {data: buckets} = useDegreeOfChange();

  const {copyImage, downloadImage} = useChartDownloads({
    id: chartId,
    title: barChartTitle,
  });

  const formattedData = useMemo(
    () =>
      data
        .filter(({range}) => range !== 'indeterminate')
        .map(({value, order, range}) => ({
          x: value,
          y:
            range !== 'large_decrease'
              ? `${buckets.find((bucket) => bucket.range === range).bucket.min}%`
              : '',
          range,
          color: theme.palette.change[order],
        }))
        .reverse(),
    [data, theme, buckets],
  );

  const chartCaption = useMemo(
    () =>
      indeterminateCaption.replace(
        '{count1}',
        data.reduce((acc, {value, range}) => {
          if (range === 'indeterminate') return acc;
          return acc + value;
        }, 0),
      ),
    [data],
  );

  const degreeOfChangeLabel = (range) =>
    degreeOfChangeOptions.find(({value}) => value === range)?.name;

  return (
    <BaseCard
      id={chartId}
      title={barChartTitle}
      tooltip={`*${getTooltipTranslation('numberOfLocationsByChange')}\n*${chartCaption}`}
      headerActions={[
        <ChartOptionsDropdown
          icon={DownloadSimple}
          options={[
            {
              label: copyImageLabel,
              action: copyImage,
            },
            {
              label: downloadImageLabel,
              action: downloadImage,
            },
          ]}
        />,
      ]}>
      <Box sx={styles.content}>
        <BaseXYChart
          height={350}
          hideYAxis={false}
          orientation="horizontal"
          isLoading={isFetching}
          seriesLength={1}
          marginLeft={40}
          xScale={{type: 'linear'}}
          yScale={{type: 'band', paddingInner: 0.2}}
          hideGrid
          tooltipOptions={{
            colorAccessor: (_, {color}) => color,
            renderTooltip: ({tooltipData}) => {
              const bar = tooltipData?.nearestDatum?.datum;
              if (!bar) return null;
              return (
                <Box sx={styles.tooltip}>
                  <Typography
                    variant="body2"
                    style={
                      styles.tooltipTitle
                    }>{`${bar.x} ${locations}`}</Typography>
                  <Box style={styles.tooltipSubtitleSection}>
                    <Box style={styles.colorBox(bar.color)} />
                    <Typography variant="caption">
                      <b>{`${magnitudeOfChange}: `}</b>
                      {degreeOfChangeLabel(bar.range)}
                    </Typography>
                  </Box>
                </Box>
              );
            },
          }}
          xAxisOptions={{
            tickLabelProps: () => ({fill: theme.palette.text.secondary}),
            hideZero: false,
            hideTicks: false,
          }}
          yAxisOptions={{
            tickLabelProps: {
              fill: theme.palette.text.secondary,
              dy: '26px',
            },
          }}>
          <BaseBar
            data={{[magnitudeOfChange]: formattedData}}
            colorAccessor={({color}) => color}
            radius={1}
          />
        </BaseXYChart>
      </Box>
    </BaseCard>
  );
}

export default NumberOfLocationsByChange;
