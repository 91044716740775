import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import {Grid, Typography} from '@mui/material';
import BaseCheckbox from './BaseCheckbox';

function BaseCheckboxGroup({
  id,
  title,
  options,
  dense,
  selected,
  onChange,
  disabled,
  columns,
  spacing,
}) {
  const handleChange = (value) => {
    const index = selected.indexOf(value);
    if (index === -1) {
      onChange([...selected, value]);
    } else {
      onChange(selected.filter((_, i) => i !== index));
    }
  };

  return (
    <FormControl id={id} sx={{gap: 1}}>
      {title && (
        <Typography
          variant="body2"
          fontWeight="bold"
          sx={(theme) => ({
            color: disabled ? theme.palette.text.disabled : 'auto',
          })}>
          {title}
        </Typography>
      )}
      <Grid container spacing={spacing}>
        {options.map((option) => (
          <Grid item xs={12 / columns} key={option.value}>
            <BaseCheckbox
              isLoading={option.loading}
              dense={dense}
              key={option.value}
              label={option.label}
              checked={selected.includes(option.value)}
              onClick={() => handleChange(option.value)}
              disabled={disabled ?? option.disabled}
            />
          </Grid>
        ))}
      </Grid>
    </FormControl>
  );
}

BaseCheckboxGroup.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  dense: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  columns: PropTypes.number,
  spacing: PropTypes.number,
  disabled: PropTypes.bool,
};

BaseCheckboxGroup.defaultProps = {
  id: 'base-radio-group',
  title: '',
  dense: false,
  options: [],
  selected: [],
  onChange: () => {},
  columns: 1,
  spacing: 0,
};

export default BaseCheckboxGroup;
