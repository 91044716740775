import {useState} from 'react';
import {Box, Menu, useMediaQuery, useTheme} from '@mui/material';
import {DotsThreeVertical} from '@phosphor-icons/react';

import {styles} from '@/components/navigation/list/TopNavigation.styles';
import ActionAnalytics from '@/components/navigation/actions/ActionAnalytics';
import ActionSearch from '@/components/navigation/actions/ActionSearch';
import ActionDownload from '@/components/navigation/actions/ActionDownload';
import ActionFilters from '@/components/navigation/actions/ActionFilters';
import Breadcrumbs from '@/components/navigation/actions/Breadcrumbs';
import ActionMyLocationsAnalysisSettings from '@/components/navigation/actions/ActionMyLocationsAnalysisSettings';
import {BaseIconButton} from '@/components/common/buttons';

function TopNavigation() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={styles.root}>
      <Box sx={styles.leftSide}>
        <Box sx={styles.logo} />
        <Box sx={styles.leftSideActions}>
          <ActionFilters />
          <Breadcrumbs />
        </Box>
      </Box>
      <Box sx={styles.rightSide}>
        {!isSmallScreen && (
          <>
            <ActionSearch />
            <ActionDownload />
            <ActionAnalytics />
            <ActionMyLocationsAnalysisSettings />
          </>
        )}
        {isSmallScreen && (
          <>
            <BaseIconButton
              icon={DotsThreeVertical}
              sx={styles.rightIcon}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}>
              <Box sx={styles.menu}>
                <ActionSearch />
                <ActionDownload />
                <ActionAnalytics />
                <ActionMyLocationsAnalysisSettings />
              </Box>
            </Menu>
          </>
        )}
      </Box>
    </Box>
  );
}

export default TopNavigation;
