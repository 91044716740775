export const styles = {
  tooltipContainer: (theme) => ({
    paddingX: '16px',
    paddingY: '8px',
    fontSize: '12px',
    borderRadius: '4px',
    color: theme.palette.background.light,
  }),
  tooltipTitle: {
    marginBottom: 1,
  },
  tooltip: (theme) => ({
    marginTop: '4px',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  }),
  tooltipDetails: (colorScale) => ({
    display: 'inline-flex',
    width: '11px',
    height: '11px',
    marginRight: '8px',
    backgroundColor: colorScale,
    borderRadius: '4px',
  }),
};
