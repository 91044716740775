import {useMemo} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  ArrowsDownUp,
  Gauge,
  MapPinLine,
  Ruler,
  UsersThree,
} from '@phosphor-icons/react';
import {useTheme} from '@mui/system';

import {useSelector} from 'react-redux';
import {getClickedLocation, getLocation, getUserProfile} from '@/selectors';

import {API_KEY_TAKEAWAY, API_KEY_TAKEAWAY_RADIUS} from '@/hooks/api/constants';
import {useChart} from '@/hooks/api/useChart';

import {styles} from '@/components/analytics/sections/OverviewSection.styles';
import ThreatLandscapeSummary from '@/components/analytics/threatLandscape/ThreatLandscapeSummary';
import {useLocationChangeValue} from '@/hooks/api/useLocationChangeValue';
import {useThreatCount} from '@/hooks/api/useThreatCount';
import {usePopulationAndArea} from '@/hooks/api/usePopulationAndArea';
import {useTranslation} from '@/hooks/useTranslation';
import {useThreatScore} from '@/hooks/api/useThreatScore';

function OverviewSection({id, granularity}) {
  const theme = useTheme();
  const {getI18N} = useTranslation();
  const {id: locationId} = useSelector(getLocation);
  const {units: displayUnits} = useSelector(getUserProfile);

  const {
    threatScore: threatScoreLabel,
    percentChange: percentChangeLabel,
    monthlyEvents: monthlyEventsLabel,
    monthlyEventsSuffix,
    population: populationLabel,
    area: areaLabel,
  } = getI18N('threatAnalytics.overviewSection');

  const {
    latitude,
    longitude,
    radiusMeters: radius,
  } = useSelector(getClickedLocation);

  const {percentChange, percentChangeIsLoading} = useLocationChangeValue({
    granularity,
    locationId,
  });

  const {data: threatScore, isFetching: threatScoreIsLoading} =
    useThreatScore();
  const {monthlyEvents, threatCountIsLoading} = useThreatCount({granularity});
  const {population, area, populationLoading} = usePopulationAndArea({
    granularity,
  });

  // threat landscape text
  const {
    data: threatLandscapeData,
    error: threatLandscapeError,
    isFetching: threatLandscapeIsLoading,
  } = useChart({
    path: granularity === 'radius' ? API_KEY_TAKEAWAY_RADIUS : API_KEY_TAKEAWAY,
    granularity,
    latitude,
    longitude,
    radius,
  });

  const hideThreatLandscape = useMemo(
    () =>
      (!threatLandscapeIsLoading &&
        threatLandscapeData?.totalCount &&
        threatLandscapeData.totalCount === 0) ||
      threatLandscapeError,
    [threatLandscapeIsLoading, threatLandscapeData, threatLandscapeError],
  );

  const showMetric = (value) => value === 0 || (value && value !== 'NaN');
  const showMetricsBoxes = useMemo(
    () => showMetric(threatScore) && showMetric(percentChange),
    [threatScore, percentChange],
  );

  const locationDetails = useMemo(
    () => [
      {
        icon: Gauge,
        label: threatScoreLabel,
        value: threatScore,
        loading: threatScoreIsLoading,
        hide: !showMetric(threatScore),
        hideFromList: showMetricsBoxes,
      },
      {
        icon: MapPinLine,
        label: monthlyEventsLabel,
        value: monthlyEvents,
        suffix: `/${monthlyEventsSuffix}`,
        loading: threatCountIsLoading,
        hide: !showMetric(monthlyEvents),
      },
      {
        icon: ArrowsDownUp,
        label: percentChangeLabel,
        value: percentChange,
        suffix: '%',
        loading: percentChangeIsLoading,
        hide: !showMetric(percentChange),
        hideFromList: showMetricsBoxes,
      },
      {
        icon: UsersThree,
        label: populationLabel,
        value: population,
        loading: populationLoading,
        hide: !showMetric(population),
      },
      {
        icon: Ruler,
        label: areaLabel,
        value: area,
        suffix: ` ${displayUnits}²`,
        loading: populationLoading,
        hide: !showMetric(area),
      },
    ],
    [
      percentChange,
      percentChangeIsLoading,
      monthlyEvents,
      threatCountIsLoading,
      population,
      populationLoading,
      area,
      displayUnits,
    ],
  );

  return (
    <Box id={id} sx={styles.root}>
      {showMetricsBoxes && (
        <Box sx={styles.metricsBoxes}>
          <Box sx={styles.metricsBox}>
            <Typography variant="h5" fontWeight="bold">
              {threatScore}
            </Typography>
            <Typography variant="caption">{threatScoreLabel}</Typography>
          </Box>
          <Box sx={styles.metricsBox}>
            <Typography variant="h5" fontWeight="bold">
              {percentChange}%
            </Typography>
            <Typography variant="caption">{percentChangeLabel}</Typography>
          </Box>
        </Box>
      )}
      <Box sx={styles.detailsList}>
        {locationDetails.map((detail, index) => {
          if (detail.loading) {
            return <Box key={index} sx={styles.loading} />;
          }
          if (detail.hide || detail.hideFromList) {
            return null;
          }
          return (
            <Box key={index} sx={styles.detail}>
              <detail.icon size={20} color={theme.palette.secondary.light} />
              <Typography variant="body2" sx={{flex: 1}}>
                {detail.label}
              </Typography>
              <Typography variant="body2" sx={{flex: 1}}>
                {detail.value}
                {detail.suffix}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <ThreatLandscapeSummary
        granularity={granularity}
        data={threatLandscapeData}
        isLoading={threatLandscapeIsLoading}
        isVisible={!hideThreatLandscape}
      />
    </Box>
  );
}

OverviewSection.propTypes = {
  granularity: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default OverviewSection;
