import {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import EventSummary from '@/components/analytics/charts/EventSummary';
import BaseRadioGroup from '@/components/common/checkboxes/BaseRadioGroup';
import {
  API_CHART_EVENT_SUMMARY,
  API_CHART_EVENT_SUMMARY_RADIUS,
} from '@/hooks/api/constants';
import {useTranslation} from '@/hooks/useTranslation';
import {useChart} from '@/hooks/api/useChart';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';

function EventSummarySection({id, granularity, sx, onlyCharts, settings}) {
  const [chartType, setChartType] = useState('count');
  const {getI18N} = useTranslation();
  const {threatCount, percentOfTotal} = getI18N('chartSelection');
  const title = getI18N('threatAnalytics.chartTitle1');

  const path = useMemo(
    () =>
      granularity === 'radius'
        ? API_CHART_EVENT_SUMMARY_RADIUS
        : API_CHART_EVENT_SUMMARY,
    [granularity],
  );

  const {
    data,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    path,
    granularity,
    title,
  });

  useEffect(() => {
    setChartType(settings.metric);
  }, [settings]);

  if (onlyCharts)
    return (
      <ReportChartWrapper isLoading={isFetching} error={Boolean(error)}>
        <EventSummary
          height={sx.height}
          width={sx.width}
          type={chartType}
          data={data}
          isLoading={isFetching}
        />
      </ReportChartWrapper>
    );

  if (error) return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        title={title}
        tooltip="eventSummary"
        downloadCSV={downloadCSV}
        copyCSV={copyCSV}
        downloadImage={downloadImage}
        copyImage={copyImage}
        chart={
          <EventSummary
            data={data}
            isLoading={isFetching}
            type={chartType}
            height={300}
          />
        }
        controls={
          <BaseRadioGroup
            dense
            onChange={(value) => {
              setChartType(value);
            }}
            selected={chartType}
            options={[
              {
                label: threatCount,
                value: 'count',
              },
              {
                label: percentOfTotal,
                value: 'percent',
              },
            ]}
          />
        }
        settings={{id, metric: chartType}}
      />
    </Box>
  );
}

EventSummarySection.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onlyCharts: PropTypes.bool,
  settings: PropTypes.object,
};

export default EventSummarySection;
