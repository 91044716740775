export const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
    paddingBlock: 6,
  },
  attribution: {
    fontSize: '10px',
  },
};
