import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {convertDistance} from '@/utils/utils';
import {
  getSelectedDistrictIds,
  getClickedLocation,
  getLocation,
  getClickedLocationHood,
  getUserProfile,
} from '@/selectors';
import {useApi} from '@/hooks/api/useApi';
import {API_POPULATION, API_POPULATION_RADIUS} from '@/hooks/api/constants';
import {useClickedMap} from '@/hooks/charts/useClickedMap';
import {useRouter} from '@/hooks/useRouter';

export const usePopulationAndArea = ({granularity}) => {
  const {useGetQuery} = useApi();
  const {isSavedRoute} = useRouter();
  const [population, setPopulation] = useState();
  const [area, setArea] = useState();
  const selectedDistrictsIds = useSelector(getSelectedDistrictIds);
  const savedDistrict = useSelector(getClickedLocationHood);
  const {units} = useSelector(getUserProfile);

  const {id: locationId} = useSelector(getLocation);
  const {latitude, longitude, radiusMeters} = useSelector(getClickedLocation);
  const {computedRadius} = useClickedMap();

  const radius = useMemo(
    () => convertDistance(isSavedRoute ? radiusMeters : computedRadius, units),
    [isSavedRoute, radiusMeters, units, computedRadius],
  );

  const hoodIds = useMemo(() => {
    if (selectedDistrictsIds.length) {
      return selectedDistrictsIds;
    }
    if (savedDistrict) {
      return [savedDistrict.id];
    }
    return [];
  }, [selectedDistrictsIds, savedDistrict]);

  const params = useMemo(() => {
    if (granularity === 'radius') {
      return {
        latitude,
        longitude,
        radius,
        units,
      };
    }

    if (granularity === 'district') {
      return {locationId, hoodIds, units};
    }

    if (granularity === 'city') {
      return {locationId, units};
    }
  }, [
    granularity,
    locationId,
    latitude,
    longitude,
    radius,
    selectedDistrictsIds,
  ]);

  const config = useMemo(
    () => ({
      enabled: Boolean(
        (granularity === 'radius' && latitude && longitude && radius) ||
          (granularity === 'district' && hoodIds.length > 0) ||
          (granularity === 'city' && locationId),
      ),
    }),
    [locationId, latitude, longitude, radius, selectedDistrictsIds],
  );

  const {data, isFetching: populationLoading} = useGetQuery({
    path: granularity === 'radius' ? API_POPULATION_RADIUS : API_POPULATION,
    params,
    config,
  });

  useEffect(() => {
    if (data) {
      setPopulation(Math.round(data.population).toLocaleString());
      setArea(data.area);
    }
  }, [data, populationLoading]);

  return {
    population,
    area,
    populationLoading,
  };
};
