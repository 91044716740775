import {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import isEqual from 'lodash/isEqual';

import {SlidersHorizontal, X} from '@phosphor-icons/react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import {getUserProfile, getUserSettings} from '@/selectors';
import AnalyticsTitle from '@/components/analytics/AnalyticsTitle';
import {styles} from '@/components/analytics/AnalyticsWrapper.styles';
import BaseButton from '@/components/common/buttons/BaseButton';
import {useApi} from '@/hooks/api/useApi';
import {useTranslation} from '@/hooks/useTranslation';

function AnalyticsWrapper({
  tooltip,
  title,
  downloadable,
  copyImage,
  downloadImage,
  copyCSV,
  downloadCSV,
  subtitle,
  chart,
  controls,
  height,
  settings,
}) {
  const {patchUser} = useApi();
  const {getI18N} = useTranslation();

  const user = useSelector(getUserProfile);
  const userSettings = useSelector(getUserSettings);
  const [showSettings, setShowSettings] = useState(false);
  const {saveDefaultLabel} = getI18N('threatAnalytics');

  const icon = useMemo(
    () => (showSettings ? X : SlidersHorizontal),
    [showSettings],
  );

  const saveDisabled = useMemo(
    () =>
      isEqual(
        userSettings?.analyticCharts.find((chart) => chart.id === settings?.id),
        settings,
      ),
    [userSettings, settings],
  );

  const handleSaveDefault = useCallback(
    () =>
      patchUser.mutate({
        body: {
          ...user,
          settings: {
            ...userSettings,
            analyticCharts: userSettings.analyticCharts.map(
              (defaultSettings) =>
                defaultSettings.id === settings.id ? settings : defaultSettings,
            ),
          },
        },
      }),
    [user, userSettings, settings],
  );

  return (
    <>
      <AnalyticsTitle
        downloadable={downloadable}
        title={title}
        tooltip={tooltip}
        downloadCSV={downloadCSV}
        copyCSV={copyCSV}
        downloadImage={downloadImage}
        copyImage={copyImage}
        icon={controls ? icon : null}
        onClick={() => setShowSettings(!showSettings)}
        sx={{marginBottom: 0}}
      />
      {subtitle && (
        <Typography variant="body2" sx={styles.subtitle}>
          {subtitle}
        </Typography>
      )}
      <Box>
        <Collapse in={showSettings}>
          <Box sx={styles.controls}>
            {controls}
            <BaseButton
              sx={styles.button}
              onClick={handleSaveDefault}
              disabled={saveDisabled}>
              {saveDefaultLabel}
            </BaseButton>
          </Box>
        </Collapse>
        <Box sx={styles.chart} height={height}>
          {chart}
        </Box>
      </Box>
    </>
  );
}

AnalyticsWrapper.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  downloadable: PropTypes.bool,
  copyImage: PropTypes.func,
  downloadImage: PropTypes.func,
  copyCSV: PropTypes.func,
  downloadCSV: PropTypes.func,
  subtitle: PropTypes.string,
  chart: PropTypes.node,
  controls: PropTypes.node,
  height: PropTypes.number,
  settings: PropTypes.object,
};

export default AnalyticsWrapper;
