import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  API_CHANGE_SOURCES,
  API_CHANGE_RADIUS_SOURCES,
} from '@/hooks/api/constants';
import {useApi} from '@/hooks/api/useApi';
import {useTranslation} from '@/hooks/useTranslation';
import {
  getLocation,
  getNewUserLocationLat,
  getNewUserLocationLon,
  getSelectedCategories,
  getSelectedSources,
  getSummary,
  getThreatAnalyticsAreaLat,
  getThreatAnalyticsAreaLon,
  getThreatAnalyticsAreaRadius,
  getUserProfile,
} from '@/selectors';
import {validateChartParams} from '@/utils/apiUtils';

export const useChangeSources = ({
  granularity,
  aggregation,
  disabled = false,
  latitude,
  longitude,
  radius,
}) => {
  const {useGetQuery} = useApi();
  const {locale, getSourceTypesTranslation} = useTranslation();
  const selectedSources = useSelector(getSelectedSources);
  const selectedCategories = useSelector(getSelectedCategories);
  const {sourceThreatCategories} = useSelector(getSummary);
  const {id: locationId} = useSelector(getLocation);
  const savedLocationRadius = useSelector(getThreatAnalyticsAreaRadius);
  const {units} = useSelector(getUserProfile);
  const locationLat = useSelector(getThreatAnalyticsAreaLat);
  const locationLon = useSelector(getThreatAnalyticsAreaLon);
  const newLocationLat = useSelector(getNewUserLocationLat);
  const newLocationLon = useSelector(getNewUserLocationLon);

  // Radius Chart Params
  const radiusParams = useMemo(
    () => ({
      latitude: latitude || newLocationLat || locationLat,
      longitude: longitude || newLocationLon || locationLon,
      radius: savedLocationRadius,
      units: 'meters',
    }),
    [
      newLocationLat,
      newLocationLon,
      locationLat,
      locationLon,
      savedLocationRadius,
      units,
      latitude,
      longitude,
      radius,
    ],
  );

  const sourceCategoryMap = useMemo(() => {
    if (!sourceThreatCategories) return {};
    const map = {};
    sourceThreatCategories.forEach((record) => {
      map[record.sourceCategoryId] = record.threatCategoryIds;
    });
    return map;
  }, [sourceThreatCategories]);

  const params = useMemo(() => {
    if (granularity === 'radius') {
      return radiusParams;
    }
    return {
      locationId,
    };
  }, [granularity, locationId, radiusParams]);

  const {data} = useGetQuery({
    path:
      granularity !== 'radius' ? API_CHANGE_SOURCES : API_CHANGE_RADIUS_SOURCES,
    params,
    config: {
      enabled:
        granularity !== 'district' &&
        !disabled &&
        validateChartParams(params, [granularity]),
    },
  });

  const isSourceSelected = (sourceId) => selectedSources.includes(sourceId);

  const isAnySourceThreatCategorySelected = (sourceId) => {
    const sourceCat = sourceCategoryMap[sourceId] ?? [];
    return sourceCat.some((c) => selectedCategories.includes(c));
  };

  const isDisabled = (sourceId) =>
    !isSourceSelected(sourceId) || !isAnySourceThreatCategorySelected(sourceId);

  const formatted = useMemo(() => {
    if (!data || data.length === 0 || granularity === 'district') {
      return [];
    }

    const filtered = data.filter((r) => {
      if (aggregation === 'quarterly') {
        return r.reporting !== 'yearly';
      }
      return r.reporting !== 'quarterly' && r.reporting !== 'yearly';
    });

    return filtered.map((record) => ({
      id: record.id.toString(),
      name: getSourceTypesTranslation(record.id),
      disabled: isDisabled(record.id),
      disabledReason: !isSourceSelected(record.id)
        ? 'sourceNotSelected'
        : 'categoryNotSelected',
    }));
  }, [
    data,
    locale,
    granularity,
    aggregation,
    selectedSources,
    selectedCategories,
    newLocationLat,
    newLocationLon,
    locationLat,
    locationLon,
  ]);

  return formatted;
};
