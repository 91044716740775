import {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
  getLocation,
  getSelectedCategories,
  getSelectedMonths,
  getSelectedSources,
  getSelectedTimeslices,
} from '@/selectors';

import {API_KEY_TAKEAWAY, API_KEY_TAKEAWAY_RADIUS} from '@/hooks/api/constants';
import {useChangeSources} from '@/hooks/charts/useChangeSources';
import {useSnackbar} from '@/hooks/useSnackbar';
import {useFeatureAccess} from '@/hooks/useFeatureAccess';
import {useMixpanel} from '@/hooks/useMixpanel';
import {useTranslation} from '@/hooks/useTranslation';
import {useChangeCategory} from '@/hooks/charts/useChangeCategory';
import {useRouter} from '@/hooks/useRouter';
import {useChart} from '@/hooks/api/useChart';
import {useChangeTrend} from '@/hooks/charts/useChangeTrend';
import {useReportURL} from '@/hooks/report/useReportURL';

import {styles} from '@/components/analytics/sections/ChangeSection.styles.js';
import BaseSkeleton from '@/components/common/BaseSkeleton';
import BaseSelect from '@/components/common/inputs/BaseSelect';
import ThreatLandscapeSummary from '@/components/analytics/threatLandscape/ThreatLandscapeSummary';
import ChangeHeader from '@/components/analytics/threatLandscape/ChangeHeader';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import ChangeOverview from '@/components/analytics/charts/ChangeOverview';
import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';
import BaseRadioGroup from '@/components/common/checkboxes/BaseRadioGroup';
import BaseSwitch from '@/components/common/inputs/BaseSwitch';
import BaseTooltip from '@/components/common/BaseTooltip';
import ChangeTrend from '@/components/analytics/charts/ChangeTrend';
import {useChangeOptions} from '@/hooks/charts/useChangeOptions';

/**
 * Displays important information about the selected radius, district or city
 *
 * @param {string} granularity - The current selected tab
 * @returns {React.Component} - The component
 *
 * @example
 * <ChangeSection granularity="radius" id="threat-chart" />
 */
function ChangeSection({
  id,
  granularity,
  sx,
  onlyCharts,
  latitude,
  longitude,
  radius,
  fromDate,
  toDate,
  settings,
}) {
  const {locale, getI18N} = useTranslation();
  const {showSnackbar} = useSnackbar();
  const {getFeatureAccess} = useFeatureAccess();
  const {track} = useMixpanel();
  const {isSavedRoute} = useRouter();

  const selectedCategories = useSelector(getSelectedCategories);
  const selectedSources = useSelector(getSelectedSources);
  const selectedDaytimes = useSelector(getSelectedTimeslices);
  const selectedMonths = useSelector(getSelectedMonths);
  const {id: locationId} = useSelector(getLocation);
  const {reportURL} = useReportURL();

  const [aggregation, setAggregation] = useState('quarterly');
  const [period, setPeriod] = useState('year');
  const [source, setSource] = useState('5');
  const [unavailableSources, setUnavailableSources] = useState([]);
  const [numSelectedFilters, setNumSelectedFilters] = useState(0);
  const [normalized, setNormalized] = useState(false);

  const hideChange = getFeatureAccess('threat-analytics-change', 'hide');

  const {
    sourceNotSelected,
    sourceThreatCategoriesNotSelected,
    notEnoughData,
    changeMinimum,
  } = getI18N('tooltips');

  const {change: changeLabel, source: sourceLabel} = getI18N('chartSelection');
  const {emptyThreatLandscape, threatLandscape, threatChange} =
    getI18N('savedLocations');

  const hideChangeFeature = getFeatureAccess('threat-analytics-change', 'hide');
  const title = getI18N('threatAnalytics.takeAwaysTitle');

  const {
    data: changeData,
    errorMonthlyPeriod,
    errorMonthlyYear,
    errorQuarterlyPeriod,
    errorQuarterlyYear,
    isFetching: changeIsLoading,
    yDomain,
    overallChange,
    startDate,
    endDate,
    downloadImage,
    copyImage,
    downloadCSV,
  } = useChangeCategory({
    id,
    title,
    source,
    period,
    granularity,
    aggregation,
    normalized,
    disabled: hideChangeFeature,
    latitude,
    longitude,
    radius,
    fromDate,
    toDate,
  });

  const sources = useChangeSources({
    granularity,
    aggregation,
    disabled: hideChangeFeature,
    latitude,
    longitude,
    radius,
  });

  const {
    data: threatLandscapeData,
    error: threatLandscapeError,
    isFetching: threatLandscapeIsLoading,
  } = useChart({
    path: granularity === 'radius' ? API_KEY_TAKEAWAY_RADIUS : API_KEY_TAKEAWAY,
    granularity,
    latitude,
    longitude,
    radius,
    fromDate,
    toDate,
  });

  const getDisabledReason = (s) => {
    if (s.disabled) {
      if (s.disabledReason === 'sourceNotSelected') {
        return sourceNotSelected;
      }
      if (s.disabledReason === 'categoryNotSelected') {
        return sourceThreatCategoriesNotSelected;
      }
    }
    if (unavailableSources.includes(s.id)) {
      return notEnoughData;
    }
    return null;
  };

  const sourceOptions = useMemo(
    () =>
      sources.map((s) => ({
        label: s.name,
        value: s.id,
        disabled: unavailableSources.includes(s.id) || s.disabled,
        tooltip: getDisabledReason(s),
      })),
    [sources, unavailableSources],
  );

  // If more filters are selected, reset unavailableSources
  useEffect(() => {
    const currentCount =
      selectedCategories.length +
      selectedSources.length +
      selectedDaytimes.length +
      selectedMonths.length;

    if (currentCount > numSelectedFilters) {
      setUnavailableSources([]);
    }
    setNumSelectedFilters(currentCount);
  }, [selectedCategories, selectedSources, selectedDaytimes, selectedMonths]);

  // If selectedLocation changes reset unavailableSources
  useEffect(() => {
    setUnavailableSources([]);
    setNumSelectedFilters(0);
  }, [locationId]);

  // change trend

  const {
    data: changeTrendData,
    yDomain: changeTrendYDomain,
    errorMonthlyPeriod: changeTrendErrorMonthlyPeriod,
    errorMonthlyYear: changeTrendErrorMonthlyYear,
    errorQuarterlyPeriod: changeTrendErrorQuarterlyPeriod,
    errorQuarterlyYear: changeTrendErrorQuarterlyYear,
    isFetching: changeTrendIsLoading,
    downloadCSV: downloadCSVChangeTrend,
    copyCSV: copyCSVChangeTrend,
  } = useChangeTrend({
    id,
    title,
    period,
    granularity,
    aggregation,
    normalized,
    source,
    disabled: hideChange,
    ...reportURL?.customFilters,
  });

  // Separate positive and negative data for dual color chart
  const separatedChangeTrendData = useMemo(() => {
    let lastPositiveIndex = -1;

    const positiveData = changeTrendData.map((d, i) => {
      if (d.y >= 0) {
        lastPositiveIndex = i;
        return {...d};
      }
      if (i === lastPositiveIndex + 1) {
        // in this case we half the value to smooth the curve from + to -
        lastPositiveIndex = i;
        return {...d, y: d.y / 2};
      }
      return {...d, y: 0};
    });
    const negativeData = changeTrendData.map((d) => ({
      ...d,
      y: d.y < 0 ? d.y : 0,
    }));

    if (!positiveData || !negativeData) return null;

    // Due to the chart configuration in this file, the labels Positive Change
    // and Negative Change and not surfaced to the user. The `renderTooltip` method
    // uses the `tooltipLabel` to display the label to the user.
    return {
      'Positive Change': positiveData,
      'Negative Change': negativeData,
    };
  }, [changeTrendData]);

  // end change trend

  const {aggregationOptions, periodOptions, allError} = useChangeOptions({
    locale,
    aggregation,
    period,
    errorMonthlyYear: errorMonthlyYear && changeTrendErrorMonthlyYear,
    errorMonthlyPeriod: errorMonthlyPeriod && changeTrendErrorMonthlyPeriod,
    errorQuarterlyYear: errorQuarterlyYear && changeTrendErrorQuarterlyYear,
    errorQuarterlyPeriod:
      errorQuarterlyPeriod && changeTrendErrorQuarterlyPeriod,
  });

  const enabledSources = useMemo(
    () => sourceOptions.filter((s) => !s.disabled),
    [sourceOptions],
  );

  // Set aggregation to option that is not disabled
  useEffect(() => {
    const option = aggregationOptions.find((o) => o.value === aggregation);
    if (!option || option?.disabled) {
      const nextOption = aggregationOptions.find((o) => !o.disabled);
      setAggregation(nextOption?.value ?? 'monthly');
    }
  }, [aggregationOptions]);

  // Set period to option that is not disabled
  useEffect(() => {
    const option = periodOptions.find((o) => o.value === period);
    if (!option || option?.disabled) {
      const nextOption = periodOptions.find((o) => !o.disabled);
      setPeriod(nextOption?.value ?? 'periodOverPeriod');
    }
  }, [periodOptions]);

  // Set source to option that is not disabled
  useEffect(() => {
    const option = sourceOptions.find((s) => s.value === source);
    if (!option || option.disabled) {
      const nextSource = sourceOptions.find((s) => !s.disabled);
      if (nextSource) setSource(nextSource.value);
    }
  }, [sourceOptions]);

  const subtitleText = useMemo(() => {
    const periodLabel = periodOptions.find((p) => p.value === period)?.label;
    const sourceLabel = sourceOptions.find((s) => s.value === source)?.label;
    return `${periodLabel} - ${sourceLabel}`;
  }, [period, source, periodOptions, sourceOptions]);

  useEffect(() => {
    if (
      allError &&
      enabledSources.length >= 1 &&
      !unavailableSources.includes(source)
    ) {
      showSnackbar({
        icon: 'warning',
        message: notEnoughData,
      });
      setUnavailableSources((prev) => [...prev, source]);
      const nextSource = enabledSources.find((s) => s.value !== source);
      if (nextSource) setSource(nextSource.value);
    }
  }, [allError]);

  const hideChangeOverview = useMemo(
    () =>
      granularity === 'district' ||
      enabledSources?.length < 1 ||
      (enabledSources?.length > 0 && changeData?.length < 1) ||
      hideChangeFeature,
    [enabledSources, granularity, hideChangeFeature, changeData],
  );

  const hideThreatLandscape = useMemo(
    () =>
      (!threatLandscapeIsLoading &&
        threatLandscapeData?.totalCount &&
        threatLandscapeData.totalCount === 0) ||
      threatLandscapeError,
    [threatLandscapeIsLoading, threatLandscapeData, threatLandscapeError],
  );

  const trackChangeEvent = () => {
    track('Filters Applied Change Overview', {
      period,
      source,
      aggregation,
      granularity,
    });
  };

  useEffect(() => {
    setAggregation(settings.period);
    setSource(settings.source);
    setNormalized(settings.normalized);
    setPeriod(settings.metric);
  }, [settings]);

  if (isSavedRoute) {
    if (hideThreatLandscape && hideChangeOverview)
      return (
        <Box sx={styles.emptyMessageContainer}>
          <Typography variant="caption">
            <Box component="li" sx={styles.message}>
              {emptyThreatLandscape}
            </Box>
          </Typography>
        </Box>
      );
    return (
      <Grid container sx={styles.savedLocationsContainer}>
        <Grid item xs={12} md={4} sx={styles.threatChangeGrid}>
          <FeatureAccessWrapper feature="threat-analytics-change">
            {changeData.length > 0 && (
              <Typography variant="body2" sx={styles.threatChangeTitle}>
                {threatChange}
              </Typography>
            )}
            <ChangeOverview
              height={120}
              isLoading={changeIsLoading}
              data={changeData}
              yDomain={yDomain}
            />
          </FeatureAccessWrapper>
        </Grid>
        <Grid item xs={12} md={8} sx={styles.threatLandscapeGrid}>
          <Box sx={styles.threatLandscapeContainer}>
            <Typography variant="body2" sx={styles.threatLandscapeTitle}>
              {threatLandscape}
            </Typography>
            <Box sx={styles.threatLandscape}>
              <ThreatLandscapeSummary
                granularity={granularity}
                data={threatLandscapeData}
                isFetching={threatLandscapeIsLoading}
                isVisible={!hideThreatLandscape}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (onlyCharts)
    return (
      <ReportChartWrapper
        isLoading={changeIsLoading && !allError}
        error={enabledSources.length === 0 && allError}>
        {id === 'chart-change-overview' && (
          <>
            <ChangeHeader
              overallChange={overallChange}
              period={period}
              aggregation={aggregation}
              source={source}
              startDate={startDate}
              endDate={endDate}
            />
            <ChangeOverview
              height={sx.height - 60}
              width={sx.width}
              isLoading={changeIsLoading}
              data={changeData}
              yDomain={yDomain}
            />
          </>
        )}
        {id === 'chart-change-trend' && (
          <ChangeTrend
            height={sx.height}
            width={sx.width}
            isLoading={changeTrendIsLoading}
            data={separatedChangeTrendData}
            yDomain={changeTrendYDomain}
            aggregation={aggregation}
            period={period}
            normalized={normalized}
          />
        )}
      </ReportChartWrapper>
    );

  if (allError && threatLandscapeError) return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        title={title}
        granularity={granularity}
        downloadImage={downloadImage}
        copyImage={copyImage}
        copyCSV={copyCSVChangeTrend}
        downloadCSV={() => {
          downloadCSV();
          downloadCSVChangeTrend();
        }}
        subtitle={subtitleText}
        chart={
          <Box>
            {!hideChangeOverview ? (
              <FeatureAccessWrapper feature="threat-analytics-change">
                <ChangeHeader
                  overallChange={overallChange}
                  period={period}
                  aggregation={aggregation}
                  source={source}
                  startDate={startDate}
                  endDate={endDate}
                />
                <ChangeOverview
                  isLoading={changeIsLoading}
                  data={changeData}
                  yDomain={yDomain}
                />
                {enabledSources.length !== 0 && !hideChange && (
                  <ChangeTrend
                    isLoading={changeTrendIsLoading}
                    data={separatedChangeTrendData}
                    yDomain={changeTrendYDomain}
                    aggregation={aggregation}
                    period={period}
                    normalized={normalized}
                  />
                )}
              </FeatureAccessWrapper>
            ) : (
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" gap={2}>
                  <BaseSkeleton width="20%" height={40} />
                  <BaseSkeleton width="80%" height={40} />
                </Box>
                <BaseSkeleton height={200} />
                <BaseSkeleton height={200} />
              </Box>
            )}
          </Box>
        }
        controls={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="left"
            gap={1}>
            {!hideChangeOverview && (
              <>
                <Typography variant="body2" fontWeight="bold">
                  {changeLabel}:
                  <BaseTooltip
                    title={changeMinimum}
                    placement="top"
                    iconSize={14}
                  />
                </Typography>
                <BaseSelect
                  variant="outlined"
                  size="small"
                  value={aggregation}
                  onChange={(e) => {
                    trackChangeEvent();
                    setAggregation(e.target.value);
                  }}
                  options={aggregationOptions}
                  sx={{width: 320, height: 32}}
                />
                <BaseSelect
                  variant="outlined"
                  size="small"
                  value={period}
                  onChange={(e) => {
                    trackChangeEvent();
                    setPeriod(e.target.value);
                  }}
                  options={periodOptions}
                  sx={{width: 320, height: 32}}
                />
                <BaseSwitch
                  checked={normalized}
                  onChange={() => {
                    trackChangeEvent();
                    setNormalized(!normalized);
                  }}
                  labelPlacement="end"
                  label="Normalized"
                />
                <BaseRadioGroup
                  dense
                  title={`${sourceLabel}:`}
                  selected={source}
                  options={sourceOptions}
                  onChange={(value) => {
                    trackChangeEvent();
                    setSource(value);
                  }}
                />
              </>
            )}
          </Box>
        }
        settings={{id, aggregation, period, source, normalized}}
      />
    </Box>
  );
}

ChangeSection.propTypes = {
  id: PropTypes.string.isRequired,
  granularity: PropTypes.string.isRequired,
  sx: PropTypes.object,
  onlyCharts: PropTypes.bool,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  radius: PropTypes.number,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  settings: PropTypes.object,
};

export default ChangeSection;
