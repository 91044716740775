import {
  SET_ORGANIZATIONS,
  SET_ACTIVE_ORGANIZATION,
  SET_ACTIVE_ORGANIZATION_ID,
} from '@/store/modules/organization/actionTypes';

export const setOrganizations = (payload) => ({
  type: SET_ORGANIZATIONS,
  payload,
});

export const setActiveOrganization = (payload) => ({
  type: SET_ACTIVE_ORGANIZATION,
  payload,
});

export const setActiveOrganizationId = (payload) => ({
  type: SET_ACTIVE_ORGANIZATION_ID,
  payload,
});
