// Package
import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import isNil from 'lodash/isNil';

// MUI
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {useTheme} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';

// Icons
import {
  MapTrifold,
  PencilSimpleLine,
  ArrowDown,
  ArrowUp,
  Equals,
  Minus,
  CaretCircleDown,
} from '@phosphor-icons/react';

// Hooks
import {styles} from '@/components/saved-locations/cards/SavedLocationListTile.styles';

import {useTranslation} from '@/hooks/useTranslation';
import {useFeatureAccess} from '@/hooks/useFeatureAccess';

// Selectors
import {getUserProfile} from '@/selectors';

// Styles

// Components
import {getLocationDateRange} from '@/components/saved-locations/constants';
import SavedLocationTags from '@/components/saved-locations/cards/SavedLocationTags';
import SavedLocationTitle from '@/components/saved-locations/cards/SavedLocationTitle';
import SavedLocationAvatar from '@/components/saved-locations/cards/SavedLocationAvatar';
import BaseIconButton from '@/components/common/buttons/BaseIconButton';
import {convertDistance} from '@/utils/utils';
import SavedVisibilityLabel from '@/components/saved-locations/cards/SavedVisibilityLabel';
import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';
import AnalyticsComponents from '@/components/analytics/AnalyticsComponents';
import AnalyticsGroup from '@/components/analytics/AnalyticsGroup';

function SavedLocationListTile({
  savedLocation,
  details,
  searchText,
  onEditClick,
  onMapClick,
  header,
}) {
  const theme = useTheme();
  const {getI18N} = useTranslation();
  const {getFeatureAccess} = useFeatureAccess();
  const {units} = useSelector(getUserProfile);

  const [collapsed, setCollapsed] = useState(true);

  const {
    editButtonText,
    goToLocation,
    noCoverage,
    userCannotEdit: userCannotEditText,
    changeNotAvailable,
    coverageDates,
    area,
    rating,
    tags: tagsLabel,
    visibilityLabel,
    change,
  } = getI18N('savedLocations');

  const {coverage, startDate, endDate} = details;
  const {
    addressType,
    name,
    isPrivate,
    address,
    customerLocationId,
    customerRating,
    radiusMeters,
    changes,
    tags,
  } = savedLocation;

  const changeValue = changes[0]?.change;
  const formattedTags = tags?.map(({name}) => name) || [];
  const computedAddress = address || details?.defaultAddress || '';

  const canEditSavedLocation = useMemo(
    () => isPrivate || !getFeatureAccess('shared-saved-locations', 'disable'),
    [isPrivate, getFeatureAccess],
  );

  const isChangeAvailable = !isNil(changeValue);
  const formattedChangeIndex = changeValue ? `${changeValue.toFixed(1)}%` : 0;

  const formattedRadius = `${convertDistance(radiusMeters, units).toFixed(
    2,
  )} ${units}`;

  const analyticsComponents = useMemo(
    () => AnalyticsComponents.filter(({id}) => id === 'chart-change-trend'),
    [],
  );

  if (header) {
    return (
      <Card sx={styles.headerCard}>
        <CardContent sx={styles.cardContent}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item md={3}>
              <Typography
                margin="28px"
                variant="caption"
                sx={styles.columnHeader}>
                Location
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="caption" sx={styles.columnHeader}>
                {coverageDates}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="caption" sx={styles.columnHeader}>
                {area}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <FeatureAccessWrapper feature="saved-locations-priority">
                <Typography variant="caption" sx={styles.columnHeader}>
                  {rating}
                </Typography>
              </FeatureAccessWrapper>
            </Grid>
            <FeatureAccessWrapper feature="saved-locations-change">
              <Grid item md={1}>
                <Typography
                  variant="caption"
                  margin="20px"
                  sx={styles.columnHeader}>
                  {change}
                </Typography>
              </Grid>
            </FeatureAccessWrapper>
            <Grid sx={styles.cardContentTags} item xs={3}>
              <Typography variant="caption" sx={styles.columnHeader}>
                {tagsLabel}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <FeatureAccessWrapper feature="shared-locations-visibility">
                <Box display="flex" justifyItems="center">
                  <Typography variant="caption" sx={styles.columnHeader}>
                    {visibilityLabel}
                  </Typography>
                </Box>
              </FeatureAccessWrapper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card data-testid="saved-location-list-tile" sx={styles.card}>
      <CardContent sx={styles.cardContent}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={3}>
            <Box sx={styles.title}>
              <Box sx={styles.title.icon}>
                <SavedLocationAvatar icon={addressType} />
              </Box>
              <Box sx={styles.title.text}>
                <SavedLocationTitle
                  {...{
                    view: 'list',
                    name,
                    isPrivate,
                    address: computedAddress,
                    customerLocationId,
                    searchText,
                    startDate,
                    endDate,
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography variant="caption">
              {getLocationDateRange(startDate, endDate) || noCoverage}
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography variant="caption">
              {formattedRadius || <Minus size={12} />}
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <FeatureAccessWrapper feature="saved-locations-priority">
              <Typography variant="caption" sx={styles.title.text}>
                {customerRating ?? <Minus size={12} />}
              </Typography>
            </FeatureAccessWrapper>
          </Grid>
          <FeatureAccessWrapper feature="saved-locations-change">
            <Grid item xs={12} md={1}>
              <Box sx={styles.title}>
                {!isChangeAvailable ? (
                  <Tooltip title={changeNotAvailable}>
                    <Minus size={12} />
                  </Tooltip>
                ) : (
                  <>
                    <Box sx={styles.title.icon}>
                      {changeValue > 0 && (
                        <ArrowUp size={12} color={theme.palette.quintile[5]} />
                      )}
                      {changeValue < 0 && (
                        <ArrowDown
                          size={12}
                          color={theme.palette.quintile[0]}
                        />
                      )}
                      {changeValue === 0 && <Equals size={12} />}
                    </Box>
                    <Box>
                      <Typography sx={styles.title.text}>
                        {formattedChangeIndex}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </FeatureAccessWrapper>
          <Grid sx={styles.cardContentTags} item xs={12} md={3}>
            <SavedLocationTags
              tags={coverage}
              featuredTags={formattedTags}
              featureName="saved-locations-tags"
              view="list"
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <FeatureAccessWrapper feature="shared-locations-visibility">
              <Box display="flex" justifyItems="center">
                <SavedVisibilityLabel isPrivate={isPrivate} />
              </Box>
            </FeatureAccessWrapper>
          </Grid>
          <Grid item md={1}>
            <Box sx={styles.actions}>
              <BaseIconButton
                tooltip={goToLocation}
                icon={MapTrifold}
                size={28}
                color={theme.palette.primary.main}
                backgroundColor=""
                onClick={onMapClick}
              />
              <BaseIconButton
                disabled={!canEditSavedLocation}
                tooltip={
                  canEditSavedLocation ? editButtonText : userCannotEditText
                }
                icon={PencilSimpleLine}
                size={28}
                color={theme.palette.primary.main}
                backgroundColor=""
                onClick={onEditClick}
              />
              <BaseIconButton
                tooltip="Expand"
                icon={CaretCircleDown}
                size={28}
                color={theme.palette.primary.main}
                backgroundColor=""
                onClick={() => setCollapsed(!collapsed)}
              />
            </Box>
          </Grid>
        </Grid>
        <Collapse in={!collapsed} timeout="auto">
          {!collapsed && (
            <AnalyticsGroup
              sx={{
                height: '200px',
                width: '100%',
                backgroundColor: theme.palette.background.light,
              }}
              components={analyticsComponents}
              granularity="radius"
              analyticsType="overview"
              latitude={details.latitude}
              longitude={details.longitude}
              radius={details.radius}
              fromDate={details.startDate}
              toDate={details.endDate}
            />
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
}

SavedLocationListTile.propTypes = {
  savedLocation: PropTypes.shape({
    addressType: PropTypes.oneOf([
      'office',
      'hotel',
      'event',
      'restaurant',
      'travel',
      'warehouse',
      'parking',
      'service_area',
      'point_of_interest',
      'facility',
      'other',
    ]),
    name: PropTypes.string,
    customerLocationId: PropTypes.string,
    address: PropTypes.string,
    customerRating: PropTypes.number,
    radiusMeters: PropTypes.number,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    isPrivate: PropTypes.bool,
    changes: PropTypes.arrayOf(
      PropTypes.shape({
        change: PropTypes.number,
        changeMetric: PropTypes.string,
      }),
    ),
  }),
  details: PropTypes.object,
  searchText: PropTypes.string,
  onEditClick: PropTypes.func,
  onMapClick: PropTypes.func,
  header: PropTypes.bool,
};

SavedLocationListTile.defaultProps = {
  details: {
    coverage: [],
    startDate: null,
    endDate: null,
    defaultAddress: '',
  },
  searchText: '',
  onEditClick: () => {},
  onMapClick: () => {},
  savedLocation: {
    type: 'office',
    name: '<Title>',
    isPrivate: false,
    customerLocationId: null,
    address: '',
    customerRating: null,
    radiusMeters: null,
    tags: [],
    changes: [],
  },
  sort: '',
  header: false,
};

export default SavedLocationListTile;
