export const styles = {
  root: (theme) => ({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    marginRight: 2,
    marginBottom: 2,
    overflow: 'auto',
    gap: 2,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 1,
    },
  }),
  title: {
    px: 2,
    py: 1.5,
    fontWeight: 'bold',
  },
  card: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '6px',
    flex: '1 1 auto',
    backgroundColor: theme.palette.background.dark,
  }),
  content: {
    padding: '16px',
    borderRadius: '6px',
  },
  downloadFab: (theme) => ({
    position: 'fixed',
    bottom: 32,
    right: 32,
    height: '32px',
    width: '32px',
    minHeight: '32px',
    minWidth: '32px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      width: 'auto',
    },
  }),
  fabContent: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginInline: 2,
    textTransform: 'none',
  },
};
