import React, {memo} from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import BaseSkeleton from '@common/BaseSkeleton';

const useStyles = () => ({
  root: {
    py: 1,
  },
  dataObservationsTitle: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  dataObservationText: {
    whiteSpace: 'pre-line',
    textAlign: 'justify',
  },
});

function DataSourceWidget({data, isLoading}) {
  const styles = useStyles();

  if (isLoading) return <DataSourceLoader />;

  return (
    <Box sx={styles.root}>
      <Typography variant="body2" sx={styles.dataObservationText}>
        {data.insightText}
      </Typography>
      <Box component="ul" paddingLeft={3}>
        {data.insights?.map((item) => (
          <Typography key={item} component="li" variant="body2">
            {item}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}

DataSourceWidget.defaultProps = {
  data: {},
  isLoading: false,
};

DataSourceWidget.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default memo(DataSourceWidget);

function DataSourceLoader() {
  return (
    <Box>
      <BaseSkeleton width="60%" height="20px" sx={{marginBottom: 1.5}} />
      <BaseSkeleton height="14px" sx={{marginBottom: 1}} />
      <BaseSkeleton width="80%" height="14px" sx={{marginBottom: 1}} />
    </Box>
  );
}
