import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

import {setRange} from '@/store/modules/filters/actions';
import {getRange} from '@/selectors';

function FilterRange() {
  const dispatch = useDispatch();
  const range = useSelector(getRange);

  const changeRange = (value) => {
    dispatch(setRange(value));
  };

  return (
    <Box sx={{paddingInline: 1}}>
      <Slider
        data-testid="date-range-quintile"
        min={0}
        max={5}
        marks
        value={range}
        onChange={(_, value) => changeRange(value)}
      />
    </Box>
  );
}

export default FilterRange;
