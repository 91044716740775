import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {styles} from '@common/checkboxes/BaseCheckbox.styles';

import BaseSkeleton from '@common/BaseSkeleton';
import BaseTooltip from '@common/BaseTooltip';

function BaseCheckbox({
  checked,
  label,
  onClick,
  indeterminate,
  bold,
  dense,
  disabled,
  isLoading,
  labelPlacement,
  tooltip,
  ...props
}) {
  const renderLabel = () => {
    if (typeof label === 'string') {
      return (
        <Typography
          variant="body2"
          component="span"
          sx={(theme) => styles.typographyLabel({theme, bold, disabled})}>
          {label}
          {tooltip && <BaseTooltip message={tooltip} iconSize={14} />}
        </Typography>
      );
    }

    return label;
  };

  if (isLoading) {
    return (
      <Box sx={styles.skeletorContainer}>
        <BaseSkeleton width="16px" height="16px" />
        <BaseSkeleton width="70px" height="16px" />
      </Box>
    );
  }

  return (
    <FormControlLabel
      disabled={disabled}
      control={
        <Checkbox
          size="small"
          indeterminate={indeterminate}
          onClick={onClick}
          checked={checked}
          sx={dense ? styles.checkboxContainer : {}}
        />
      }
      label={renderLabel()}
      labelPlacement={labelPlacement}
      {...props}
    />
  );
}

BaseCheckbox.defaultProps = {
  checked: false,
  label: '',
  onClick: () => {},
  labelPlacement: 'end',
};

BaseCheckbox.propTypes = {
  isLoading: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  indeterminate: PropTypes.bool,
  bold: PropTypes.bool,
  dense: PropTypes.bool,
  disabled: PropTypes.bool,
  labelPlacement: PropTypes.string,
  tooltip: PropTypes.string,
};

export default BaseCheckbox;
