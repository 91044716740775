import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {MAP_BOX_USERNAME} from '@config';
import {getSavedLocations} from '@/selectors';
import {setLayers} from '@/store/modules/filters/actions';

import {useMapTransformRequest} from '@/hooks/map/useMapTransformRequest';
import {useMapMouse} from '@/hooks/map/useMapMouse';
import {useMouseMove} from '@/hooks/saved-locations/useMouseMove';
import {useMapZoom} from '@/hooks/saved-locations/useMapZoom';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';
import {useMapDrag} from '@/hooks/saved-locations/useMapDrag';
import {useTranslation} from '@/hooks/useTranslation';

import {CHANGE_SOURCE} from '@/components/map/layers/constants';
import {styles} from '@/components/saved-locations/map/ChangeMagnitudeMap.styles';
import MapControls from '@/components/map/navigation/MapControls';
import LayerChange from '@/components/map/layers/LayerChange';
import MapTooltip from '@/components/map/popups/MapTooltip';
import BaseMap from '@/components/common/map/BaseMap';
import BaseSkeleton from '@/components/common/BaseSkeleton';
import ActionColorScale from '@/components/map/actions/ActionColorScale';

function ChangeMagnitudeMap() {
  const dispatch = useDispatch();
  const {handleTransformRequest} = useMapTransformRequest();
  const {
    cursor,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseOut,
    handleMouseDown,
    handleMouseUp,
  } = useMapMouse();
  const {handleZoomEnd} = useMapZoom();
  const {handleDragEnd} = useMapDrag();
  const {handleMouseMove} = useMouseMove();
  const {triggerMapRepaint} = useMapboxGL();
  const {getI18N} = useTranslation();

  const {analysisFilters} = useSelector(getSavedLocations);
  const [map, setMap] = useState(null);
  const [Selected, setSelected] = useState(false);

  const {changeColorScaleLabel, changeSizeScaleLabel} =
    getI18N('savedLocations');

  const handleMapOnLoad = ({target: map}) => {
    map.jumpTo({center: [-30, 30], zoom: 1});
    setMap(map);
  };

  const handleMapMouseDown = (event) => {
    handleMouseDown(event);
    setSelected(true);
  };

  const handleMapMouseOut = (event) => {
    handleMouseOut(event);
    setSelected(false);
  };

  useEffect(() => {
    triggerMapRepaint(CHANGE_SOURCE, map);
  }, [triggerMapRepaint, analysisFilters, map]);

  useEffect(() => {
    dispatch(setLayers('change'));
  }, []);

  return (
    <Box id="map-container" sx={styles.mapContainer}>
      <Box sx={styles.mapContent} visibility={map ? 'visible' : 'hidden'}>
        <BaseMap
          mapKey="saved-locations"
          dragPan
          style={styles.map}
          scrollZoom={Selected}
          minZoom={0.5}
          cursor={cursor}
          mapStyle={`mapbox://styles/${MAP_BOX_USERNAME}/clrpxjcmk008d01pbh3rh6n4h`}
          onLoad={handleMapOnLoad}
          onMouseMove={handleMouseMove}
          onMouseOut={handleMapMouseOut}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseDown={handleMapMouseDown}
          onZoomEnd={handleZoomEnd}
          onDragEnd={handleDragEnd}
          transformRequest={handleTransformRequest}>
          <MapControls />
          <LayerChange />
          <MapTooltip />
        </BaseMap>
        <Box sx={styles.legend}>
          <ActionColorScale
            width="40px"
            height="18px"
            title={changeColorScaleLabel}
            sx={styles.changeColorScale}
          />
          <Box sx={styles.changeSizeScale}>
            <Box sx={styles.changeSizeIcon} />
            <Typography variant="caption">{changeSizeScaleLabel}</Typography>
          </Box>
        </Box>
        <Box sx={styles.mapSkeleton} visibility={map ? 'hidden' : 'visible'}>
          <BaseSkeleton height="100%" width="100%" />
        </Box>
      </Box>
    </Box>
  );
}

export default ChangeMagnitudeMap;
