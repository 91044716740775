import {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Box from '@mui/material/Box';

import {styles} from '@views/ReportPage.styles';
import ReportMap from '@/components/report/ReportMap';
import ReportCharts from '@/components/report/ReportCharts';
import {setTheme} from '@/store/modules/theme/actions';

function ReportPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTheme('reports'));
  }, [dispatch]);

  return (
    <Box sx={styles.root}>
      <Switch>
        <Route path="/report/chart" component={ReportCharts} />
        <Route path="/report/map" component={ReportMap} />
      </Switch>
    </Box>
  );
}

export default ReportPage;
