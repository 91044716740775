import React from 'react';
import PropTypes from 'prop-types';

// MUI
import Chip from '@mui/material/Chip';
import {styles} from '@/components/common/inputs/BaseChipLabel.styles';

function BaseChipLabel({label, onDelete, deleteIcon, ...props}) {
  return (
    <Chip
      sx={styles.baseChipLabel}
      onDelete={onDelete}
      label={label}
      deleteIcon={deleteIcon}
      {...props}
    />
  );
}

export default BaseChipLabel;

BaseChipLabel.propTypes = {
  label: PropTypes.string.isRequired,
  deleteIcon: PropTypes.node,
  onDelete: PropTypes.func,
};

BaseChipLabel.defaultProps = {
  label: '',
  onDelete: () => {},
  icon: null,
};
