import {useCallback} from 'react';

import {useMixpanel} from '@hooks/useMixpanel';

export const useMapZoom = () => {
  const {track} = useMixpanel();

  const handleZoomEnd = useCallback(
    ({target: map, viewState: {latitude, longitude, zoom}}) => {
      track('Zoom In/Out Saved Locations Change Map', {
        zoom,
        latitude,
        longitude,
        viewport: map.getBounds(),
      });
    },
    [],
  );

  return {handleZoomEnd};
};
