import {useSourceCategories} from '@/hooks/api/useSourceCategories';
import {useSummary} from '@/hooks/api/useSummary';
import {useLocation} from '@/hooks/api/useLocation';
import {useVersionUpdates} from '@/hooks/useVersionUpdates';
import {useThreatCategories} from '@/hooks/api/useThreatCategories';

export const useApiGlobally = ({enabled}) => {
  useVersionUpdates();
  useSourceCategories({enabled});
  useLocation({enabled});
  useSummary({enabled});
  useThreatCategories({enabled});
};
