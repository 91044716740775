import {useSelector} from 'react-redux';
import {useApi} from '@hooks/api/useApi';
import {useDates} from '@hooks/useDates';
import {useMixpanel} from '@hooks/useMixpanel';
import {useSnackbar} from '@hooks/useSnackbar';
import {getClickedLocationName, getDistricts, getLocation} from '@/selectors';
import {useImageCapture} from '@/hooks/useImageCapture';
import {useTranslation} from '@/hooks/useTranslation';

export const useChartDownloads = ({id, title, path, params, granularity}) => {
  // Hooks
  const {getChartDownloads} = useApi();
  const {getAnalyticsStartDate, getAnalyticsEndDate} = useDates();
  const {track} = useMixpanel();
  const {showSnackbar} = useSnackbar();
  const {copyImage: copyCapturedImage, downloadImage: downloadCapturedImage} =
    useImageCapture();
  const {getI18N} = useTranslation();

  // Selectors
  const {name: locationName} = useSelector(getLocation);
  const {selectedDistricts} = useSelector(getDistricts);
  const savedLocationName = useSelector(getClickedLocationName);

  const {copiedToClipboard, downloadSuccess, fetchingData} =
    getI18N('snackbarMessages');

  /**
   * Get chart from API as CSV file
   * @param {string} path - API path
   * @param {function} onSuccess - callback function
   * @param {object} params - API params
   * @returns {void}
   * */
  const getChartCSV = (onSuccess) => {
    getChartDownloads.mutate(
      {
        path,
        params,
      },
      {
        onSuccess,
      },
    );
  };

  /**
   * Get file name for chart download
   * @returns {string}
   * */
  const getFileName = () => {
    const fromDateTime = getAnalyticsStartDate();
    const toDateTime = getAnalyticsEndDate();

    // Remove time and tz from dates
    const fromDate = fromDateTime.split('T')[0];
    const toDate = toDateTime.split('T')[0];

    // Create file name
    let fileName = `${title}_${fromDate}_${toDate}.csv`;

    if (granularity !== 'radius') {
      if (locationName) {
        const shortName = locationName.split(',')[0];
        fileName = `${shortName}_${fileName}`;
      }
      if (granularity === 'district' && selectedDistricts.length > 0) {
        fileName = `${selectedDistricts
          .map(({displayName}) => displayName)
          .join('_')}_district_${fileName}`;
      }
    } else if (granularity === 'radius') {
      fileName = `${savedLocationName}_radius_${fileName}`;
    } else if (granularity === 'district') {
      fileName = `${savedLocationName}_district_${fileName}`;
    } else {
      fileName = `${savedLocationName}_city_${fileName}`;
    }

    return fileName;
  };

  /**
   * Download chart as CSV file
   * @param {string} config - Config object
   * @param {object} params - API params
   * @returns {void}
   */
  const downloadCSV = () => {
    showSnackbar({
      message: fetchingData,
      loading: true,
    });
    track('Downloaded Analytics Chart CSV', {
      chart: title,
      granularity,
      location: locationName,
    });

    const fileName = getFileName();

    // Download function
    const download = (payload) => {
      const blob = new Blob([payload], {type: 'text/csv'});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      link.remove();
      showSnackbar({
        icon: 'check',
        message: downloadSuccess,
      });
    };

    getChartCSV(download);
  };

  /**
   * Copy chart to clipboard
   * @param {string} config - Config object
   * @param {object} params - API params
   * @returns {void}
   */
  const copyCSV = () => {
    showSnackbar({
      message: 'Fetching chart data...',
      loading: true,
    });
    track('Copied Analytics Chart CSV', {
      chart: title,
      granularity,
      location: locationName,
    });

    const copy = (payload) => {
      const blob = new Blob([payload], {type: 'text/plain'});
      const item = new ClipboardItem({'text/plain': blob});
      navigator.clipboard.write([item]);
      showSnackbar({
        icon: 'check',
        message: copiedToClipboard,
      });
    };

    getChartCSV(copy);
  };

  /**
   * Copy chart image to clipboard
   * @param {string} id - element id
   * @returns {void}
   * */
  const copyImage = async () => {
    track('Copied Analytics Chart Image', {
      chart: title,
      granularity,
      location: locationName,
    });

    copyCapturedImage({id});
  };

  /**
   * Download chart as image
   * @param {object} config - config params (id, title)
   * @returns {void}
   * */
  const downloadImage = async () => {
    track('Downloaded Analytics Chart Image', {
      chart: title,
      granularity,
      location: locationName,
    });

    const fileName = getFileName();
    downloadCapturedImage({id, fileName});
  };

  return {
    downloadCSV,
    copyCSV,
    copyImage,
    downloadImage,
  };
};
