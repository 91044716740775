export const styles = {
  root: {
    overflowX: 'auto',
    width: '100%',
  },
  buttonContainer: {
    paddingBlock: '2rem',
    display: 'flex',
    justifyContent: 'right',
    flexGrow: 1,
  },
};
