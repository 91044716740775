import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import {getUserSettings} from '@/selectors';
import {styles} from '@/components/analytics/AnalyticsGroup.styles';

function AnalyticsGroup({
  granularity,
  analyticsType,
  components,
  onlyCharts,
  sx,
  setActivePanel,
  ...props
}) {
  const {analyticCharts} = useSelector(getUserSettings);
  const getDefaultSettings = useCallback(
    (id) => analyticCharts?.find((settings) => settings.id === id),
    [analyticCharts],
  );

  return (
    <Box sx={onlyCharts ? styles.reportGrid : {}} style={onlyCharts ? sx : {}}>
      {components?.map(({id, component: AnalyticsComponent}) => (
        <AnalyticsComponent
          key={id}
          id={id}
          {...props}
          sx={onlyCharts ? sx : styles.root}
          granularity={granularity}
          analyticsType={analyticsType}
          onlyCharts={onlyCharts}
          setActivePanel={setActivePanel}
          settings={getDefaultSettings(id)}
        />
      ))}
    </Box>
  );
}

AnalyticsGroup.propTypes = {
  granularity: PropTypes.string.isRequired,
  analyticsType: PropTypes.string.isRequired,
  components: PropTypes.array,
  onlyCharts: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  setActivePanel: PropTypes.func,
};

AnalyticsGroup.defaultProps = {
  granularity: 'city',
  analyticsType: 'overview',
  onlyCharts: false,
};

export default AnalyticsGroup;
