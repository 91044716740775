import React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {ArrowRight} from '@phosphor-icons/react';

import {useTranslation} from '@hooks/useTranslation';
import {useRouter} from '@/hooks/useRouter';
import HomeSearchInput from '@/components/search/HomeSearchInput';
import BaseButton from '@/components/common/buttons/BaseButton';
import {styles} from '@/components/views/HomePage.styles';

function HomePage() {
  const {getI18N} = useTranslation();
  const {pushMapRoute} = useRouter();
  const {homeHeader, viewMapButton} = getI18N('home');

  const handleClick = () => pushMapRoute();

  return (
    <Box sx={styles.root} id="home-container">
      <Typography align="center" variant="h3" sx={styles.homeHeaderStyle}>
        {homeHeader}
      </Typography>
      <Box sx={styles.search}>
        <HomeSearchInput />
      </Box>
      <BaseButton
        variant="text"
        sx={styles.button}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={handleClick}
        data-testid="view-map-button">
        <Typography align="center" variant="h6" sx={styles.buttonText}>
          {viewMapButton}
        </Typography>
        <ArrowRight size={20} color="white" />
      </BaseButton>
    </Box>
  );
}

export default HomePage;
