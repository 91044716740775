export const styles = {
  root: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.25rem',
    backgroundColor: theme.palette.background.dark,
    height: '1.5rem',
    padding: '0 0.625rem',
    cursor: 'pointer',
    marginRight: 2,
  }),
  text: (theme) => ({
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: '0.75rem',
    textTransform: 'uppercase',
  }),
};
