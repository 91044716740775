export const styles = {
  root: {
    width: '100%',
    textAlign: 'center',
  },
  optionGroup: {
    textAlign: 'left',
  },
  optionHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  expandIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 1.5,
    padding: '1px',
  },
  collapsibleBox: {
    paddingInline: 4,
    borderRadius: '4px',
    marginBottom: 1,
  },
  gridItem: {
    minWidth: '50%',
  },
};
