import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import DataSourceWidget from '@/components/analytics/DataSourceWidget';
import {getLocation} from '@/selectors';
import {useApi} from '@/hooks/api/useApi';
import {API_LOCATION_INSIGHTS} from '@/hooks/api/constants';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';
import {useTranslation} from '@/hooks/useTranslation';

function DataSourceInsightSection({sx}) {
  const {useGetQuery} = useApi();
  const {getI18N} = useTranslation();
  const {id: locationId} = useSelector(getLocation);

  const {data, error, isFetching} = useGetQuery({
    path: API_LOCATION_INSIGHTS.replace('{id}', locationId),
    config: {
      enabled: Boolean(locationId >= 0),
    },
  });

  if (error) return null;

  return (
    <Box sx={sx}>
      <AnalyticsWrapper
        title={getI18N('threatAnalytics.dataObservationsTitle')}
        tooltip="dataSourceInsights"
        chart={<DataSourceWidget data={data} isLoading={isFetching} />}
      />
    </Box>
  );
}

DataSourceInsightSection.propTypes = {
  sx: PropTypes.object,
};

export default DataSourceInsightSection;
