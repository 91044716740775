import {withAuthenticationRequired} from '@auth0/auth0-react';
import PropTypes from 'prop-types';

import Loader from '@/components/common/loaders/Loader';
import {useApiGlobally} from '@/hooks/api/useApiGlobally';
import {useAuth0Service} from '@/hooks/useAuth0Service';

function PrivateRoute({component: Component}) {
  const {isAuthenticated, reportAccessToken} = useAuth0Service();
  useApiGlobally({enabled: isAuthenticated || Boolean(reportAccessToken)});

  if (reportAccessToken) return <Component />;

  return withAuthenticationRequired(Component, {
    onRedirecting: () => <Loader />,
  })();
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

PrivateRoute.defaultProps = {
  component: null,
};

export default PrivateRoute;
