import {useTheme} from '@mui/material';
import {buildChartTheme} from '@visx/xychart';

const colors = [
  ['#3984B6'],
  ['#3984B6', '#85CBCF'],
  ['#2E62A1', '#44A7CB', '#9ED5CD'],
  ['#264992', '#3984B6', '#5ABAD1', '#B7DFCB'],
  ['#223B89', '#316BA7', '#419DC5', '#70C3D0', '#BEE0CC'],
  ['#1E3082', '#2C5A9C', '#3984B6', '#47AED0', '#83CACF', '#C6E3CB'],
  ['#1E3082', '#274B93', '#3371AA', '#3F97C2', '#56B9D2', '#91D0CE', '#CEE6CA'],
  [
    '#1E3082',
    '#2A5599',
    '#3474AC',
    '#3E94C0',
    '#48B3D3',
    '#78C6D0',
    '#AFDCCC',
    '#DCECC9',
  ],
  [
    '#1E3082',
    '#274B93',
    '#3067A5',
    '#3984B6',
    '#43A1C7',
    '#56B9D2',
    '#83CACF',
    '#B3DDCC',
    '#DCECC9',
  ],
  [
    '#1E3082',
    '#24448E',
    '#2D5E9E',
    '#3577AE',
    '#3D91BE',
    '#46AACE',
    '#62BED2',
    '#8ACDCE',
    '#B3DDCC',
    '#DCECC9',
  ],
];

export const getChartColors = (seriesLength = 10) => {
  let colorIndex = Math.min(seriesLength, colors.length) - 1;
  if (colorIndex < 0) colorIndex = 0;
  return colors[colorIndex];
};

const useChartTheme = (seriesLength = 10, customColors) => {
  const theme = useTheme();
  return buildChartTheme({
    // Basic Colors
    backgroundColor: theme.palette.background.paper,
    colors: customColors ?? getChartColors(seriesLength),

    // Grid
    gridColor: theme.palette.text.primary,
    gridColorDark: theme.palette.common.black,
    gridStyles: {
      strokeOpacity: 0.3,
    },

    // Labels
    svgLabelBig: {
      fill: theme.palette.primary.main,
      fontSize: 12,
      fontWeight: 'bold',
    },
    svgLabelSmall: {
      fill: theme.palette.text.secondary,
      fontSize: 10,
      fontWeight: 'normal',
    },

    // Ticks
    xAxisLineStyles: {
      stroke: theme.palette.text.secondary,
      strokeWidth: 2,
    },
    yAxisLineStyles: {
      stroke: theme.palette.text.secondary,
      strokeWidth: 2,
    },
    xTickLineStyles: {
      strokeWidth: 1,
      stroke: theme.palette.text.secondary,
    },
    yTickLineStyles: {
      strokeWidth: 1,
      stroke: theme.palette.text.secondary,
    },
    tickLength: 8,
  });
};

export default useChartTheme;
