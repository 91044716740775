import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';

import Slider from '@mui/material/Slider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import {styles} from '@common/inputs/BaseSlider.styles';
import BaseInputLabel from '@/components/common/inputs/BaseInputLabel';

const BaseSlider = forwardRef(
  (
    {
      control,
      size,
      id,
      name,
      variant,
      disabled,
      fullWidth,
      label,
      required,
      placeholder,
      onChange,
      value,
      errors,
      min,
      max,
      marks,
      sx,
      valueLabelFormat,
      ...rest
    },
    ref,
  ) => {
    const themeSliderStyles = (theme) => ({
      ...styles.slider,
      ...(typeof sx === 'function' ? sx(theme) : sx),
    });

    return (
      <FormControl
        id={id}
        size={size}
        name={name}
        variant={variant}
        required={required}
        fullWidth={fullWidth}
        error={!!errors}
        sx={{display: 'flex'}}
        disabled={disabled}>
        {label && (
          <Box marginBottom={0.5}>
            <BaseInputLabel required={required} label={label} />
          </Box>
        )}
        {control && (
          <Controller
            control={control}
            name={name}
            render={({field: {onChange, value}}) => (
              <Box paddingX="10px">
                <Slider
                  sx={themeSliderStyles}
                  ref={ref}
                  value={value}
                  placeholder={placeholder}
                  marks={marks}
                  onChange={onChange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={valueLabelFormat}
                  aria-labelledby="range-slider"
                  min={min}
                  max={max}
                  {...rest}
                />
              </Box>
            )}
          />
        )}
        {!control && (
          <Box paddingX="10px">
            <Slider
              sx={themeSliderStyles}
              ref={ref}
              value={value}
              placeholder={placeholder}
              marks={marks}
              onChange={onChange}
              valueLabelDisplay="auto"
              valueLabelFormat={valueLabelFormat}
              aria-labelledby="range-slider"
              min={min}
              max={max}
              {...rest}
            />
          </Box>
        )}
        {errors?.message && <FormHelperText>{errors.message}</FormHelperText>}
      </FormControl>
    );
  },
);

BaseSlider.defaultProps = {
  fullWidth: false,
  required: false,
  disabled: false,
  size: 'small',
  id: '',
  name: '',
  label: undefined,
  placeholder: '',
  errors: null,
  onChange: () => {},
  variant: 'standard',
  options: [],
  min: 1,
  max: 5,
  marks: false,
};

BaseSlider.propTypes = {
  control: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  variant: PropTypes.string,
  options: PropTypes.array,
  min: PropTypes.number,
  max: PropTypes.number,
  marks: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    PropTypes.bool,
  ]),
  valueLabelFormat: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

BaseSlider.displayName = 'BaseSlider';

export default BaseSlider;
