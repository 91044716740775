import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {useApi} from '@hooks/api/useApi';
import {validateChartParams} from '@utils/apiUtils';
import {
  getSelectedCategories,
  getSelectedMonths,
  getSelectedTimeslices,
  getAnalyticsRequestEnabled,
  getThreatAnalyticsAreaLat,
  getThreatAnalyticsAreaLon,
  getClickedHoodId,
  getSelectionMarkerLon,
  getSelectionMarkerLat,
  getNewUserLocationLon,
  getNewUserLocationLat,
  getSelectedSources,
  getLocation,
  getSelectedDistrictIds,
  getSavedLocations,
} from '@/selectors';
import {useDates} from '@/hooks/useDates';
import {useChartDownloads} from '@/hooks/charts/useChartDownloads';
import {useRouter} from '@/hooks/useRouter';
import {API_CHART_QUINTILES, API_CHART_SEVERITY} from '@/hooks/api/constants';
import {useClickedMap} from '@/hooks/charts/useClickedMap';

export const useChart = ({
  id,
  title,
  path,
  granularity,
  customParams,
  exactDates = true,
  fromDate,
  toDate,
  disabled = false,
  latitude,
  longitude,
  radius,
}) => {
  // Hooks
  const {isReportRoute, isSavedRoute} = useRouter();
  const {useGetQuery} = useApi();
  const {
    getAnalyticsStartDate,
    getAnalyticsEndDate,
    getISOfromDate,
    getISOtoDate,
  } = useDates();

  // Selectors
  const categories = useSelector(getSelectedCategories);
  const sources = useSelector(getSelectedSources);
  const daytimes = useSelector(getSelectedTimeslices);
  const months = useSelector(getSelectedMonths);
  const selectedDistrictsIds = useSelector(getSelectedDistrictIds);
  const isRequestEnabled = useSelector(getAnalyticsRequestEnabled);
  const locationLat = useSelector(getThreatAnalyticsAreaLat);
  const locationLon = useSelector(getThreatAnalyticsAreaLon);
  const areaHoodId = useSelector(getClickedHoodId);
  const {id: locationId} = useSelector(getLocation);
  const selectedLat = useSelector(getSelectionMarkerLat);
  const selectedLon = useSelector(getSelectionMarkerLon);
  const newLocationLat = useSelector(getNewUserLocationLat);
  const newLocationLon = useSelector(getNewUserLocationLon);
  const {filters: dashParams} = useSelector(getSavedLocations);
  const {computedRadius} = useClickedMap();

  // State
  const [componentMounted, setComponentMounted] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const determineHoodIds = () => {
    if (selectedDistrictsIds.length > 0) {
      return JSON.stringify(selectedDistrictsIds);
    }
    if (areaHoodId) {
      return JSON.stringify([areaHoodId]);
    }
    return [];
  };

  // Radius Chart Params
  const radiusParams = useMemo(
    () => ({
      latitude: latitude || selectedLat || newLocationLat || locationLat,
      longitude: longitude || selectedLon || newLocationLon || locationLon,
      radius: isSavedRoute ? radius : computedRadius,
      units: 'meters',
    }),
    [
      selectedLat,
      newLocationLat,
      locationLat,
      selectedLon,
      newLocationLon,
      locationLon,
      computedRadius,
      latitude,
      longitude,
      radius,
      isSavedRoute,
    ],
  );

  // Location/Hood Chart Params
  const locationsParams = useMemo(() => {
    const paths = [API_CHART_QUINTILES, API_CHART_SEVERITY];
    return {
      locationId,
      ...(granularity === 'district'
        ? {
            ...(paths.includes(path)
              ? {hoodId: selectedDistrictsIds[0] ?? areaHoodId}
              : {
                  hoodIds: determineHoodIds(),
                }),
          }
        : {}),
    };
  }, [locationId, selectedDistrictsIds, areaHoodId, granularity, path]);

  // Chart Filter Params
  const mapParams = useMemo(
    () => ({
      fromDate:
        fromDate ?? (!exactDates ? getAnalyticsStartDate() : getISOfromDate()),
      toDate: toDate ?? (!exactDates ? getAnalyticsEndDate() : getISOtoDate()),
      sources: JSON.stringify(sources),
      categories: JSON.stringify(categories),
      months: JSON.stringify(months),
      daytimes: JSON.stringify(daytimes),
    }),
    [
      sources,
      categories,
      months,
      daytimes,
      fromDate,
      toDate,
      exactDates,
      getISOfromDate,
      getISOtoDate,
      getAnalyticsStartDate,
      getAnalyticsEndDate,
    ],
  );

  // Chart Params based on granularity
  const params = useMemo(
    () => ({
      ...(granularity === 'radius' ? radiusParams : locationsParams),
      ...(isSavedRoute ? {fromDate, toDate} : mapParams),
      ...customParams,
    }),
    [
      granularity,
      customParams,
      radiusParams,
      locationsParams,
      mapParams,
      isSavedRoute,
      dashParams,
    ],
  );

  // Get chart data
  const {
    data: chartData,
    isFetching: isFetchingChart,
    error: chartError,
    isError: isChartError,
  } = useGetQuery({
    path,
    params,
    config: {
      enabled:
        (isRequestEnabled || isReportRoute || isSavedRoute) &&
        componentMounted &&
        !!path &&
        !disabled &&
        validateChartParams(params, [granularity, 'dates']),
    },
  });

  useEffect(() => {
    if (chartError && isChartError) {
      setError(chartError);
      setData({});
    }
  }, [chartError, isChartError]);

  useEffect(() => {
    setIsFetching(isFetchingChart);
    if (chartData && !isFetchingChart) {
      setError(null);
      setData(chartData);
    }
  }, [chartData, isFetchingChart]);

  useEffect(() => {
    setComponentMounted(true);
    return () => {
      setComponentMounted(false);
      setData({});
      setError(null);
      setIsFetching(false);
    };
  }, []);

  // Chart download hook
  const {downloadCSV, copyCSV, copyImage, downloadImage} = useChartDownloads({
    id,
    title,
    path,
    granularity,
    params,
  });

  if (disabled) {
    return {
      data: [],
      error: true,
      isFetching: false,
      downloadCSV: () => {},
      copyCSV: () => {},
      downloadImage: () => {},
      copyImage: () => {},
    };
  }

  return {
    data,
    isFetching,
    error,
    downloadCSV,
    copyCSV,
    copyImage,
    downloadImage,
  };
};
