import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import SortIconButton from '@/components/common/buttons/SortIconButton';
import {styles} from '@/components/saved-locations/tables/RankedLocations.styles';

function RankedLocationsHeader({text, sortable, active, sortDirection}) {
  return (
    <Box sx={styles.header}>
      {text}
      {sortable && (
        <SortIconButton sortDirection={sortDirection} active={active} />
      )}
    </Box>
  );
}

RankedLocationsHeader.propTypes = {
  text: PropTypes.string,
  sortable: PropTypes.bool,
  active: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['ASC', 'DESC']),
};

export default RankedLocationsHeader;
