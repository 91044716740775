import React from 'react';
import PropTypes from 'prop-types';
import {curveMonotoneX} from '@visx/curve';
import {AnimatedAreaSeries, AnimatedAreaStack} from '@visx/xychart';

function BaseArea({data, curve, type}) {
  const accessors = {
    xAccessor: (d) => d?.x ?? 0,
    yAccessor: (d) => d?.y ?? 0,
  };
  if (type === 'stacked') {
    return (
      <AnimatedAreaStack curve={curve} renderLine={false}>
        {Object.keys(data).map((key) => (
          <AnimatedAreaSeries
            key={key}
            dataKey={key}
            data={data[key]}
            fillOpacity={1}
            {...accessors}
          />
        ))}
      </AnimatedAreaStack>
    );
  }
  return (
    <>
      {Object.keys(data).map((key) => (
        <AnimatedAreaSeries
          key={key}
          dataKey={key}
          data={data[key]}
          curve={curve}
          fillOpacity={1}
          renderLine={false}
          {...accessors}
        />
      ))}
    </>
  );
}

BaseArea.defaultProps = {
  curve: curveMonotoneX,
  type: 'series',
};

BaseArea.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.instanceOf(Date),
        ]).isRequired,
        y: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.instanceOf(Date),
        ]).isRequired,
      }),
    ).isRequired,
  ).isRequired,
  curve: PropTypes.func,
  type: PropTypes.oneOf(['stacked', 'series']),
};

export default BaseArea;
