import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const styles = {
  root: () => ({
    textTransform: 'none',
    minWidth: 0,
  }),
};

function BaseButton({
  onClick,
  href,
  disabled,
  color,
  variant,
  children,
  sx,
  ...props
}) {
  if (!onClick && !href) {
    onClick = () => {};
  }
  return (
    <Button
      size="small"
      disableElevation
      onClick={onClick}
      href={href}
      disabled={disabled}
      color={color}
      variant={variant}
      {...props}
      sx={(theme) => ({
        ...styles.root(theme),
        ...(typeof sx === 'function' ? sx(theme) : sx),
      })}>
      {children}
    </Button>
  );
}

BaseButton.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'error']),
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  children: PropTypes.node.isRequired,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

BaseButton.defaultProps = {
  disabled: false,
  color: 'primary',
  variant: 'contained',
  sx: {},
};

export default BaseButton;
