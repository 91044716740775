import {useMemo, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import isEqual from 'lodash/isEqual';

import {
  getCategories,
  getSelectedCategories,
  getLocationThreatTypes,
} from '@/selectors';
import {
  filterSetCategories,
  filterToggleAllCategories,
} from '@/store/modules/filters/actions';

import FilterItem from '@/components/filters/FilterItem';
import BaseExpandableCheckboxGroup from '@/components/common/checkboxes/BaseExpandableCheckboxGroup';
import BaseCheckbox from '@/components/common/checkboxes/BaseCheckbox';

import {useTranslation} from '@/hooks/useTranslation';

function FilterCategories() {
  const dispatch = useDispatch();
  const {getI18N} = useTranslation();
  const previousThreatTypes = useRef([]);

  const categories = useSelector(getCategories);
  const selectedCategories = useSelector(getSelectedCategories);
  const locationThreatTypes = useSelector(getLocationThreatTypes);

  const categoriesLabel = getI18N('filters.categories');
  const label = getI18N('threatCategories');
  const allSelected = Object.values(categories).every(Boolean);
  const someSelected = Object.values(categories).some(Boolean);

  const threatTypesWithLabels = useMemo(
    () =>
      locationThreatTypes.map((threat) => ({
        ...threat,
        name: categoriesLabel[threat.id],
      })),
    [locationThreatTypes, categoriesLabel],
  );

  const selectedThreatTypes = useMemo(() => {
    const activeThreatTypes = threatTypesWithLabels
      .filter(({disabled, parentId}) => !disabled && parentId)
      .map(({id}) => id);
    const retainedSelections = selectedCategories.filter((category) =>
      activeThreatTypes.includes(category),
    );

    if (!isEqual(previousThreatTypes.current, threatTypesWithLabels)) {
      const recentThreatTypes = activeThreatTypes.filter(
        (threat) => !selectedCategories.includes(threat),
      );
      previousThreatTypes.current = threatTypesWithLabels;
      return [...retainedSelections, ...recentThreatTypes];
    }
    return retainedSelections;
  }, [selectedCategories, threatTypesWithLabels]);

  // Handle change when category is selected
  const handleChange = (options) => {
    dispatch(filterSetCategories(options));
  };

  // Select all categories or deselect all categories (if all are selected)
  const handleSelectAll = () => {
    dispatch(filterToggleAllCategories());
  };

  return (
    <FilterItem
      label={
        <BaseCheckbox
          bold
          label={label}
          checked={allSelected}
          indeterminate={someSelected && !allSelected}
          onClick={handleSelectAll}
        />
      }>
      <BaseExpandableCheckboxGroup
        options={threatTypesWithLabels}
        selected={selectedThreatTypes}
        onChange={handleChange}
        dense
      />
    </FilterItem>
  );
}

export default FilterCategories;
