import image from '@images/home-background.jpg';

export const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 16px)',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginLeft: 1,
    marginRight: 2,
    marginBottom: 2,
    borderRadius: 2,
  },
  button: {
    boxShadow: 'none',
    textTransform: 'none',
    '&.MuiButtonBase-root:hover': {
      backgroundColor: 'transparent',
    },
    marginTop: '27px',
  },
  homeHeaderStyle: (theme) => ({
    marginBottom: '27px',
    fontFamily: 'Montserrat',
    color: theme.palette.common.white,
  }),
  buttonText: (theme) => ({
    color: theme.palette.common.white,
    letterSpacing: -1,
    fontFamily: 'Montserrat',
    marginRight: 1,
  }),
  search: {
    maxWidth: '500px',
    width: '100%',
  },
};
