export const useStyles = () => ({
  root: {
    maxWidth: '100%',
    borderSpacing: '0 2px',
  },
  parentBox: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.light,
    paddingX: 1,
    paddingY: 0.5,
    marginTop: 0.5,
  }),
  rowsText: (theme) => ({
    backgroundColor: theme.palette.background.dark,
    borderBottom: 'none',
  }),
  childBox: (theme) => ({
    backgroundColor: theme.palette.background.light,
  }),
  detailsRow: {
    display: 'flex',
    marginX: 3,
    marginY: 1,
  },
  filters: {
    paddingBottom: 2,
  },
  headersRow: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.contrast.dark,
    borderRadius: '4px 4px 0 0',
    paddingX: 1,
    paddingY: 0.5,
  }),
  headersText: {
    display: 'flex',
  },
  headerLabelStyle: (theme) => ({
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    fontWeight: 'bold',
    '& .MuiTableSortLabel-icon': {
      color: `${theme.palette.primary.main} !important`,
    },
  }),
});
