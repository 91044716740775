import {useMemo} from 'react';
import {
  QueryClient,
  QueryCache,
  MutationCache,
  useQuery,
  useMutation,
} from '@tanstack/react-query';

import {useHttpClient} from '@/hooks/useHttpClient';
import {useErrorHandler} from '@/hooks/useErrorHandler';

export const useHttpServices = () => {
  const {handleHttpError} = useErrorHandler();
  const {get, post, put, patch, deletes} = useHttpClient();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: handleHttpError,
        }),
        mutationCache: new MutationCache({
          onError: handleHttpError,
        }),
      }),
    [],
  );

  const globalConfigurations = {
    enabled: false,
    retry: (failureCount, error) => {
      if (error.status === 401 || error.status > 500) {
        return failureCount <= 2;
      }
      return false;
    },
    refetchOnWindowFocus: false,
  };

  const cacheConfigurations = {
    cacheTime: Infinity,
    staleTime: Infinity,
  };

  const useBaseMutation = ({fn, request}) => {
    const {path, query, params, body, config} = request;
    const key = [path, {path, query, params, body}];
    return useMutation({
      ...globalConfigurations,
      ...config,
      mutationKey: key,
      mutationFn: (input) => fn({...request, input}),
      onError: (error) => {
        if (config?.onError) config.onError(error);
      },
    });
  };

  const useGet = (request) => useBaseMutation({fn: get, request});

  const usePost = (request) => useBaseMutation({fn: post, request});

  const usePut = (request) => useBaseMutation({fn: put, request});

  const usePatch = (request) => useBaseMutation({fn: patch, request});

  const useDelete = (request) => useBaseMutation({fn: deletes, request});

  const useBaseQuery = ({key, fn, config}) =>
    useQuery({
      ...globalConfigurations,
      ...cacheConfigurations,
      ...config,
      queryKey: key,
      queryFn: fn,
    });

  const useGetQuery = ({path, query, params, config}) => {
    const key = [path, {path, query, params}];
    return useBaseQuery({key, fn: get, config});
  };

  return {
    queryClient,
    useGetQuery,
    usePut,
    useDelete,
    usePatch,
    useGet,
    usePost,
  };
};
