import {useSelector} from 'react-redux';
import {
  CHANGE_CHART_METRICS,
  DEGREE_OF_CHANGE,
} from '@/components/saved-locations/constants';
import {getSavedLocations, getUserSettings} from '@/selectors';
import {API_CHANGE_DISTRIBUTION} from '@/hooks/api/constants';
import {useApi} from '@/hooks/api/useApi';

export const useLocationsDistribution = () => {
  const {useGetQuery} = useApi();
  const {
    myLocationsAnalysis: {
      change: {aggregation, period},
    },
  } = useSelector(getUserSettings);
  const {
    analysisFilters: {addressTypes, tags, visibility, degreesOfChange},
  } = useSelector(getSavedLocations);

  const {data, isFetching} = useGetQuery({
    path: API_CHANGE_DISTRIBUTION,
    params: {
      addressTypes:
        addressTypes?.length > 0 ? JSON.stringify(addressTypes) : undefined,
      tags: tags?.length > 0 ? JSON.stringify(tags) : undefined,
      degreesOfChange:
        degreesOfChange?.length > 0
          ? JSON.stringify(degreesOfChange)
          : JSON.stringify(DEGREE_OF_CHANGE),
      visibility,
      changeMetric: CHANGE_CHART_METRICS[period][aggregation],
    },
    config: {
      enabled: true,
    },
  });

  return {
    data: data ?? [],
    isFetching,
  };
};
