import {Route, Switch, Redirect} from 'react-router-dom';

import MapPage from '@/components/views/MapPage';
import HomePage from '@/components/views/HomePage';
import UserPage from '@/components/views/UserPage';
import ReportPage from '@/components/views/ReportPage';
import PrivateRoute from '@/components/auth/PrivateRoute';
import SavedLocationsPage from '@/components/views/SavedLocationsPage';
import MyLocationsAnalysisPage from '@/components/views/MyLocationsAnalysisPage';

const routes = [
  {
    path: '/',
    exact: true,
    component: HomePage,
  },
  {
    path: '/map',
    component: MapPage,
  },
  {
    path: '/user',
    component: UserPage,
  },
  {
    path: '/report',
    component: ReportPage,
  },
  {
    path: '/my-locations/analysis',
    component: MyLocationsAnalysisPage,
  },
  {
    path: '/my-locations/list',
    component: SavedLocationsPage,
  },
];

function Router() {
  return (
    <Switch>
      {routes.map(({component: Page, ...route}) => (
        <Route
          key={route.path}
          strict
          path={route.path}
          exact={route.exact}
          render={() => <PrivateRoute component={Page} />}
        />
      ))}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
}

export default Router;
