import {TOP_NAVBAR_HEIGHT, LEFT_NAVBAR_WIDTH} from '@/hooks/constants';

export const styles = {
  root: {
    flexShrink: 0,
  },
  drawerPaper: ({theme, width, height, isMain, anchor}) => ({
    zIndex: theme.zIndex.drawer - (isMain ? -1 : 1),
    paddingLeft: isMain || anchor !== 'left' ? 0 : `${LEFT_NAVBAR_WIDTH}px`,
    paddingTop: isMain ? 0 : `${TOP_NAVBAR_HEIGHT}px`,
    [theme.breakpoints.up('sm')]: {
      width,
      height,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: isMain ? 0 : `${LEFT_NAVBAR_WIDTH}px`,
    },
    overflowX: 'hidden',
    border: 'none',
  }),
};
