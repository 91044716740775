import html2canvas from 'html2canvas';
import {useSnackbar} from '@hooks/useSnackbar';
import {useTheme} from '@mui/material';
import {useSentry} from '@hooks/useSentry';
import {useTranslation} from '@/hooks/useTranslation';

export const useImageCapture = () => {
  // Hooks
  const {captureExceptionSentry} = useSentry();
  const {showSnackbar} = useSnackbar();
  const theme = useTheme();
  const {getI18N} = useTranslation();

  const {copiedToClipboard} = getI18N('snackbarMessages');

  /**
   * Copy chart image to clipboard
   * @param {string} id - element id
   * @returns {void}
   * */
  const copyImage = async ({id}) => {
    try {
      const appDocument = document.getElementById(id);
      const canvas = await html2canvas(appDocument, {
        backgroundColor: theme.palette.background.default,
      });
      canvas.toBlob((blob) => {
        const item = new ClipboardItem({'image/png': blob});
        navigator.clipboard.write([item]);
        showSnackbar({
          icon: 'check',
          message: copiedToClipboard,
        });
      });
    } catch (error) {
      captureExceptionSentry(error);
      console.error('error capturing or copying screenshot. Error is', error);
    }
  };

  /**
   * Download chart as image
   * @param {object} config - config params (id, title)
   * @returns {void}
   * */
  const downloadImage = async ({id, fileName}) => {
    try {
      const appDocument = document.getElementById(id);
      const link = document.createElement('a');
      const canvas = await html2canvas(appDocument, {
        backgroundColor: theme.palette.background.default,
      });
      const imageData = canvas.toDataURL();
      link.href = imageData;
      link.download = `${fileName}.png`;
      link.click();
      link.remove();
    } catch (error) {
      captureExceptionSentry(error);
      console.error('error downloading image. Error is', error);
    }
  };

  return {
    copyImage,
    downloadImage,
  };
};
