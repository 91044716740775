import PropTypes from 'prop-types';

import {useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import {ZERO, FIRST_QUINTILE, LAST_QUINTILE} from '@/components/map/constants';

const useStyles = () => ({
  quintileList: (theme) => ({
    backgroundColor: theme.palette.background.contrast.dark,
    height: '12px',
    display: 'flex',
  }),
});

function QuintileColorScale({quintile}) {
  const styles = useStyles();
  const theme = useTheme();

  const segments = [];

  for (let i = FIRST_QUINTILE; i <= LAST_QUINTILE; i += 1) {
    segments.push(
      <Box
        key={i}
        data-testid={`quintile-segment-${i}`}
        style={{
          flex: 1,
          backgroundColor: i === quintile ? theme.palette.quintile[i] : false,
          borderRight:
            i !== LAST_QUINTILE
              ? `1px solid ${theme.palette.quintile[i]}`
              : 'none',
        }}
      />,
    );
  }

  if (quintile === ZERO) {
    segments.pop();
    segments.unshift(
      <Box
        key={quintile}
        id={`quintile-segment-${quintile}`}
        style={{
          flex: 1,
          backgroundColor: theme.palette.quintile[ZERO],
          borderRight: `1px solid ${theme.palette.quintile[ZERO]}`,
        }}
      />,
    );
  }

  return <Box sx={styles.quintileList}>{segments}</Box>;
}

QuintileColorScale.propTypes = {
  quintile: PropTypes.number.isRequired,
};

export default QuintileColorScale;
