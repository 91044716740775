// Create a hook that returns the theme object of the NotificationTray component.
import {useTheme} from '@mui/material';
import {alpha} from '@mui/material/styles';

export const useNotificationTrayTheme = () => {
  const theme = useTheme();
  return {
    dark: {
      layout: {
        background: theme.palette.common.black,
      },
      header: {
        badgeColor: theme.palette.primary.main,
        badgeTextColor: theme.palette.primary.contrastText,
        fontColor: theme.palette.text.primary,
      },
      notificationItem: {
        unread: {
          background: theme.palette.background.contrast.dark,
          fontColor: theme.palette.text.primary,
          timeMarkFontColor: theme.palette.common.white,
          notificationItemBeforeBrandColor: theme.palette.primary.main,
        },
        read: {
          background: theme.palette.background.light,
          fontColor: theme.palette.text.primary,
          timeMarkFontColor: theme.palette.common.white,
        },
        buttons: {
          primary: {
            backGroundColor: theme.palette.primary.main,
            fontColor: theme.palette.primary.contrastText,
          },
          secondary: {
            backGroundColor: theme.palette.secondary.dark,
            fontColor: theme.palette.text.primary,
          },
        },
      },
      actionsMenu: {
        dotsButtonColor: theme.palette.secondary.main,
        dropdownColor: theme.palette.background.dark,
        hoverColor: alpha(
          theme.palette.action.selected,
          theme.palette.action.selectedOpacity,
        ),
        fontColor: theme.palette.text.primary,
      },
      userPreferences: {
        settingsButtonColor: theme.palette.secondary.light,
        accordion: {
          background: theme.palette.background.light,
          fontColor: theme.palette.text.primary,
          secondaryFontColor: theme.palette.text.secondary,
          arrowColor: theme.palette.secondary.light,
          dividerColor: theme.palette.secondary.dark,
        },
        accordionItem: {
          fontColor: {
            active: theme.palette.text.primary,
            inactive: theme.palette.text.disabled,
          },
          icon: {
            active: theme.palette.text.primary,
            inactive: theme.palette.text.disabled,
          },
          switch: {
            backgroundChecked: `${theme.palette.primary.main} !important`,
            backgroundUnchecked: `${theme.palette.background.contrast.dark} !important`,
          },
        },
      },
      loaderColor: theme.palette.primary.main,
    },
  };
};
