import FormControl from '@mui/material/FormControl';
import {Grid, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import BaseRadio from '@/components/common/checkboxes/BaseRadio';

function BaseRadioGroup({
  id,
  title,
  options,
  dense,
  selected,
  onChange,
  columns,
  spacing,
}) {
  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <FormControl id={id}>
      {title && (
        <Typography variant="body2" fontWeight="bold">
          {title}
        </Typography>
      )}
      <Grid container spacing={spacing}>
        {options.map((option) => (
          <Grid item xs={12 / columns} key={option.value}>
            <BaseRadio
              isLoading={option.loading}
              dense={dense}
              label={option.label}
              checked={selected === option.value}
              onClick={() => handleChange(option.value)}
              disabled={option.disabled}
              tooltip={option.tooltip}
            />
          </Grid>
        ))}
      </Grid>
    </FormControl>
  );
}

BaseRadioGroup.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  dense: PropTypes.bool,
  selected: PropTypes.string,
  onChange: PropTypes.func,
  columns: PropTypes.number,
  spacing: PropTypes.number,
};

BaseRadioGroup.defaultProps = {
  id: 'base-radio-group',
  title: '',
  dense: false,
  options: [],
  selected: '',
  onChange: () => {},
  columns: 1,
  spacing: 0,
};

export default BaseRadioGroup;
