import {useEffect, useMemo, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import debounce from 'lodash/debounce';

// State

// Hooks
import {API_LOCATION_SEARCH, API_SAVED_LOCATIONS} from '@hooks/api/constants';
import {useScript} from '@hooks/useScript';
import {useTranslation} from '@hooks/useTranslation';
import {useApi} from '@hooks/api/useApi';

// MUI
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Icons
import {MagnifyingGlass, MapPin, Star} from '@phosphor-icons/react';

import {GOOGLE_PLACES_API} from '@config';
import {styles} from '@/components/search/HomeSearchInput.styles';
import {useSnackbar} from '@/hooks/useSnackbar';
import {useRouter} from '@/hooks/useRouter';
import {setSelectedLocation} from '@/store/modules/user/actions';
import {setRoutedToMap, setSearchMarker} from '@/store/modules/map/actions';
import {
  setGoogleValue,
  setSuggestions,
  setInputValue,
  setSearchValue,
  setSearchResult,
} from '@/store/modules/filters/actions';
import {
  getGoogleValue,
  getInputValue,
  getSuggestions,
  getSearchValue,
} from '@/selectors';

const autocompleteService = {current: null};

// Create render option as its own component
function HomeSearchOption(props, {id, type, name, description}) {
  if (!id) {
    return null;
  }
  return (
    <Box {...props} key={id} sx={styles.listStyle}>
      <Box sx={styles.listStyleIcon}>
        {type === 'google' ? (
          <MapPin size={18} color={styles.listStyleIcon.color} />
        ) : (
          <Star size={18} color={styles.listStyleIcon.color} />
        )}
      </Box>
      <Box sx={styles.typographyContainer}>
        <Typography variant="body1" fontWeight="bold">
          {name}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Box>
  );
}

function HomeSearchInput() {
  const dispatch = useDispatch();
  const {getI18N} = useTranslation();
  const {useGetQuery} = useApi();
  const scriptStatus = useScript(GOOGLE_PLACES_API);
  const {searchboxPlaceholder} = getI18N('home');
  const {isHomeRoute, pushMapRoute} = useRouter();
  const inputRef = useRef(null);
  const {showSnackbar} = useSnackbar();

  const googleValue = useSelector(getGoogleValue);
  const inputValue = useSelector(getInputValue);
  const suggestions = useSelector(getSuggestions);
  const searchValue = useSelector(getSearchValue);

  const [options, setOptions] = useState([]);

  const {isFetching, data: searchLocation} = useGetQuery({
    path: API_LOCATION_SEARCH,
    params: {
      placeId: searchValue,
    },
    config: {
      enabled: Boolean(searchValue),
    },
  });

  useEffect(() => {
    if (searchLocation && !isFetching) {
      dispatch(setSearchResult(searchLocation));
      pushMapRoute();
    }
  }, [searchLocation, isFetching]);

  const {data: savedLocations} = useGetQuery({
    path: API_SAVED_LOCATIONS,
    params: {
      search: inputValue,
      page: 1,
      take: 6,
    },
    config: {
      enabled: inputValue.length > 0,
    },
  });

  useEffect(() => {
    if (inputValue.length === 0) {
      setOptions([]);
      return;
    }
    const googleSuggested = suggestions.map((s) => ({
      id: s.place_id,
      type: 'google',
      name: s.structured_formatting.main_text,
      description: s.structured_formatting.secondary_text,
    }));
    if (savedLocations?.data) {
      const saved = savedLocations.data
        .map((s) => ({
          id: s.id,
          type: 'saved',
          name: s.name,
          description: s.address,
        }))
        .slice(0, 2);

      const newOptions =
        saved.length > 0 ? [...saved, ...googleSuggested] : googleSuggested;

      setOptions(newOptions.slice(0, 5));
    } else {
      setOptions(googleSuggested.slice(0, 5));
    }
  }, [suggestions, savedLocations]);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 600),
    [],
  );

  const handleSearchClear = () => {
    dispatch(setSearchMarker(null));
    dispatch(setInputValue());
    dispatch(setGoogleValue());
    dispatch(setSuggestions());
    dispatch(setSearchValue());
    dispatch(setSearchResult());
  };

  const handleInputValue = (event, newInputValue, reason) => {
    if (reason === 'clear') {
      handleSearchClear();
    }

    dispatch(setInputValue(newInputValue));
  };

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (inputValue === '') {
      dispatch(setSuggestions(googleValue ? [googleValue] : []));
      return undefined;
    }

    fetch({input: inputValue}, (results) => {
      if (active) {
        let newSuggestions = [];
        if (googleValue) {
          newSuggestions = [googleValue];
        }
        if (results) {
          newSuggestions = [...newSuggestions, ...results];
        }
        dispatch(setSuggestions(newSuggestions));
      }
    });

    return () => {
      active = false;
    };
  }, [googleValue, inputValue, fetch]);

  const handleChange = (event, option) => {
    if (option.type === 'google') {
      dispatch(setSearchValue(option.id));
    } else if (savedLocations?.data.length) {
      dispatch(
        setSelectedLocation(
          savedLocations.data.find((s) => s.id === option.id),
        ),
      );
      pushMapRoute();
      dispatch(setRoutedToMap(true));
    }
  };

  useEffect(() => {
    if (scriptStatus === 'error') {
      showSnackbar({
        icon: 'warning',
        iconColor: 'error',
        message: 'Unable to read Google Maps API',
      });
    }
  }, [scriptStatus]);

  useEffect(() => {
    if (isHomeRoute) {
      handleSearchClear();
    }
  }, [isHomeRoute]);

  useEffect(() => {
    if (scriptStatus === 'ready') {
      inputRef.current.focus();
    }
  }, [scriptStatus]);

  return (
    <Autocomplete
      id="home-page-search"
      disabled={scriptStatus !== 'ready'}
      fullWidth
      freeSolo
      autoHighlight
      autoComplete
      includeInputInList
      filterSelectedOptions
      data-html2canvas-ignore="true"
      getOptionLabel={(option) => option.name || option}
      filterOptions={(x) => x}
      options={options}
      value={googleValue}
      onChange={handleChange}
      onInputChange={handleInputValue}
      PaperComponent={({children}) => (
        <Paper sx={styles.autocompleteDropdown}>{children}</Paper>
      )}
      classes={{paper: styles.paper}}
      renderInput={(params) => (
        <TextField
          {...params}
          hiddenLabel
          inputRef={inputRef}
          variant="outlined"
          placeholder={searchboxPlaceholder}
          sx={styles.search}
          InputProps={{
            ...params.InputProps,
            'data-testid': 'home-search-text-field',
            startAdornment: (
              <InputAdornment position="start">
                <Box sx={styles.searchIcon}>
                  {isFetching ? (
                    <CircularProgress size={28} />
                  ) : (
                    <MagnifyingGlass
                      size={28}
                      color={styles.searchIcon.color}
                    />
                  )}
                </Box>
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={HomeSearchOption}
    />
  );
}

export default HomeSearchInput;
