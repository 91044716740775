import PropTypes from 'prop-types';
import {useTheme} from '@mui/material';
import BaseXYChart from '@/components/common/charts/BaseXYChart';
import BaseBar from '@/components/common/charts/XYCharts/BaseBar';

import {useTranslation} from '@/hooks/useTranslation';

function ChangeOverview({data, isLoading, yDomain, height, width}) {
  const theme = useTheme();
  const {getI18N} = useTranslation();
  const {percentChange: percentChangeLabel} = getI18N('changeOverview');

  const getColor = (value) => {
    if (value > 0) return theme.palette.quintile[5];
    return theme.palette.quintile[2];
  };

  const tooltipColorAccessor = (_, datum) => getColor(datum.y);

  return (
    <BaseXYChart
      showZeroLine
      height={height}
      width={width}
      seriesLength={1}
      isLoading={isLoading}
      xAxisOptions={{hideAxisLine: true, hideTicks: true}}
      yScale={{type: 'linear', domain: yDomain}}
      hideGrid
      marginBottom={25}
      tooltipOptions={{
        colorAccessor: tooltipColorAccessor,
        formatValue: (_, datum) => `${datum.y}%`,
      }}>
      <BaseBar
        colorAccessor={(d) => getColor(d.y)}
        data={{[percentChangeLabel]: data}}
        radiusAll
      />
    </BaseXYChart>
  );
}

ChangeOverview.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  yDomain: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.number,
  width: PropTypes.number,
};

export default ChangeOverview;
