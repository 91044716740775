import image from '@images/logo-bo.svg';
import {TOP_NAVBAR_HEIGHT, LEFT_NAVBAR_WIDTH} from '@/hooks/constants';

export const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
    width: '56px',
  },
  listItemNoAction: {
    paddingY: 1,
    paddingX: 0,
  },
  listItem: {
    padding: 0,
  },
  listButton: {
    padding: '18px',
    flexDirection: 'column',
    backgroundColor: 'transparent',
  },
  listIcon: {
    padding: 0,
    justifyContent: 'center',
    fontSize: '18px',
  },
  logo: {
    height: `${TOP_NAVBAR_HEIGHT}px`,
    width: `${LEFT_NAVBAR_WIDTH}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url("${image}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
};
