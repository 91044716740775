export const styles = {
  root: (theme) => ({
    textTransform: 'none',
    minWidth: 130,
    maxWidth: 'max-content',
    width: 'auto',
    color: theme.palette.text.primary,
    minHeight: '50px',
    height: '50px',
    '&.Mui-selected': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.light,
    },
    '&.Mui-disabled': {
      color: theme.palette.text.disabled,
    },
  }),
};
