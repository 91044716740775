import {useMemo} from 'react';
import {useTranslation} from '@/hooks/useTranslation';

export const useChangeOptions = ({
  locale,
  aggregation,
  period,
  errorMonthlyYear,
  errorMonthlyPeriod,
  errorQuarterlyYear,
  errorQuarterlyPeriod,
}) => {
  const {getI18N} = useTranslation();
  const {
    periodOverPeriod: periodOverPeriodLabel,
    yearOverYear: yearOverYearLabel,
    monthlyChange: monthlyChangeLabel,
    quarterlyChange: quarterlyChangeLabel,
  } = getI18N('chartSelection');
  const {optionNotAvailable} = getI18N('tooltips');

  const aggregationOptions = useMemo(() => {
    const disabledMonthly =
      period === 'year' ? errorMonthlyYear : errorMonthlyPeriod;
    const disabledQuarterly =
      period === 'year' ? errorQuarterlyYear : errorQuarterlyPeriod;
    return [
      {
        label: monthlyChangeLabel,
        value: 'monthly',
        disabled: disabledMonthly,
        tooltip: disabledMonthly ? optionNotAvailable : null,
      },
      {
        label: quarterlyChangeLabel,
        value: 'quarterly',
        disabled: disabledQuarterly,
        tooltip: disabledQuarterly ? optionNotAvailable : null,
      },
    ];
  }, [
    locale,
    period,
    errorMonthlyYear,
    errorMonthlyPeriod,
    errorQuarterlyYear,
    errorQuarterlyPeriod,
  ]);

  const periodOptions = useMemo(() => {
    const disabledPeriod =
      aggregation === 'monthly' ? errorMonthlyPeriod : errorQuarterlyPeriod;
    const disabledYear =
      aggregation === 'monthly' ? errorMonthlyYear : errorQuarterlyYear;
    return [
      {
        label: periodOverPeriodLabel,
        value: 'periodOverPeriod',
        disabled: disabledPeriod,
        tooltip: disabledPeriod ? optionNotAvailable : null,
      },
      {
        label: yearOverYearLabel,
        value: 'yearOverYear',
        disabled: disabledYear,
        tooltip: disabledYear ? optionNotAvailable : null,
      },
    ];
  }, [
    locale,
    aggregation,
    errorMonthlyYear,
    errorMonthlyPeriod,
    errorQuarterlyYear,
    errorQuarterlyPeriod,
  ]);

  const allError = useMemo(
    () =>
      errorMonthlyPeriod &&
      errorMonthlyYear &&
      errorQuarterlyPeriod &&
      errorQuarterlyYear,
    [
      errorMonthlyPeriod,
      errorMonthlyYear,
      errorQuarterlyPeriod,
      errorQuarterlyYear,
    ],
  );

  return {
    aggregationOptions,
    periodOptions,
    allError,
  };
};
