export const styles = {
  chart: {
    width: '100%',
  },
  controls: (theme) => ({
    borderRadius: 2,
    padding: 2,
    width: '100%',
    backgroundColor: theme.palette.background.light,
    gap: 2,
    display: 'flex',
    flexDirection: 'column',
  }),
  button: (theme) => ({
    backgroundColor: theme.palette.background.contrast.dark,
    color: theme.palette.text.primary,
    width: '111px',
    border: `0.5px solid ${theme.palette.background.contrast.dark}`,
  }),
  subtitle: (theme) => ({
    color: theme.palette.text.secondary,
    marginBottom: 1,
  }),
};
