export const styles = {
  root: {
    marginBottom: 2,
  },
  detailsList: {
    paddingBottom: 3,
  },
  detail: {
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
    marginBottom: 1.5,
  },
  metricsBoxes: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    paddingBottom: 3,
  },
  metricsBox: (theme) => ({
    padding: 2,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2.5,
    backgroundColor: theme.palette.background.light,
  }),
  loading: (theme) => ({
    paddingBottom: 2,
    marginBottom: 2,
    backgroundColor: theme.palette.background.light,
    borderRadius: 4,
  }),
};
