export const styles = {
  root: (theme) => ({
    fontSize: '0.875rem',
    width: '100%',
    // Input Label
    '& label': {
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
    },
    // Focused Input Label
    '& label.Mui-focused': {
      fontWeight: 'normal',
    },
    '&:before': {
      borderBottom: `1px solid ${theme.palette.background.contrast.dark}`,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${theme.palette.background.contrast.light}`,
    },
    '&:after': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '&.MuiInputBase-root.MuiOutlinedInput-root': {
      borderRadius: '6px',
    },
    '&.MuiInputBase-root.MuiFilledInput-root': {
      backgroundColor: theme.palette.background.contrast.dark,
      borderRadius: '6px',
      '&:after, &:before': {
        content: 'none',
      },
      '&.Mui-focused:after': {
        content: 'none',
      },
      '& .MuiInputBase-input': {
        paddingY: 1,
        paddingX: 2,
      },
    },
  }),
  sxStyle: {
    height: '26px',
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    justifyContent: 'space-between',
  },
  menuItemContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subMenuItemContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: (theme) => ({
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    '&.Mui-focused': {
      fontWeight: 'normal',
    },
  }),
  nestedMenuItem: {
    paddingLeft: '24px',
    paddingRight: 0,
  },
  betaLabel: (theme) => ({
    backgroundColor: theme.palette.background.light,
    marginLeft: '24px',
  }),
};
