import image from '@images/enterprise-logo.png';

export const styles = {
  root: (theme) => ({
    bgcolor: theme.palette.background.paper,
    height: 240,
    width: 260,
  }),
  content: {
    gap: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    width: 32,
    height: 37,
    backgroundImage: `url(${image})`,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontWeight: 'bold',
  },
};
