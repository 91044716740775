import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import useChartTheme from '@common/charts/ChartTheme';
import {useTranslation} from '@/hooks/useTranslation';
import BaseXYChart from '@/components/common/charts/BaseXYChart';
import BaseBar from '@/components/common/charts/XYCharts/BaseBar';

function PeriodAverage({data, isLoading, type, height, width}) {
  const {
    locale,
    getI18N,
    getAnalyticsTranslation,
    getWeekdayTranslations,
    getTimeSliceTranslation,
  } = useTranslation();
  const {average} = getI18N('chartSelection');
  const {colors} = useChartTheme(6);

  const chartData = useMemo(() => {
    const d = data?.chartData;
    if (!d || d.length === 0) {
      return [];
    }
    return d;
  }, [data]);

  const formatMonthlyData = (d) => {
    const sortedData = d.sort((a, b) => a.monthNumber - b.monthNumber);
    return sortedData.map((d) => ({
      x: getAnalyticsTranslation(d.month),
      y: parseFloat(d.average),
    }));
  };

  const formatDayOfWeekData = (d) =>
    d.map((d) => ({
      x: getWeekdayTranslations(d.day),
      y: parseFloat(d.average),
    }));

  const formatTimeOfDayData = (d) =>
    d.map((d) => ({
      x: getTimeSliceTranslation(d.timeOfDay),
      y: parseFloat(d.average),
    }));

  const formattedData = useMemo(() => {
    if (type === 'month') {
      return formatMonthlyData(chartData);
    }
    if (type === 'day') {
      return formatDayOfWeekData(chartData);
    }
    if (type === 'time') {
      return formatTimeOfDayData(chartData);
    }
  }, [chartData, locale]);

  return (
    <BaseXYChart
      isLoading={isLoading}
      height={height}
      width={width}
      seriesLength={1}
      customColors={colors}>
      <BaseBar data={{[average]: formattedData}} />
    </BaseXYChart>
  );
}

PeriodAverage.propTypes = {
  type: PropTypes.oneOf(['month', 'day', 'time']),
  height: PropTypes.number,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  width: PropTypes.number,
};

PeriodAverage.defaultProps = {
  type: 'month',
  data: {chartData: []},
  isLoading: false,
};

export default PeriodAverage;
