export const styles = {
  textBox: {
    display: 'block',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    background: 'inherit',
  },
  textLimitBox: {
    cursor: 'pointer',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
  },
  moreButton: {
    backgroundColor: 'inherit',
    position: 'absolute',
    right: '0',
    bottom: '0',
    cursor: 'pointer',
  },
  moreButtonText: (theme) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  }),
  lessButton: (theme) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 'bold',
  }),
};
