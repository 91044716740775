import {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {Collapse, Divider, Typography, useTheme} from '@mui/material';
import {CaretCircleDown, CaretCircleUp} from '@phosphor-icons/react';
import BaseSkeleton from '@/components/common/BaseSkeleton';
import QuintileColorScale from '@/components/analytics/quintile/QuintileColorScale';
import {styles} from '@/components/analytics/quintile/QuintilesGroup.styles';

function QuintilesGroup({options, defaultExpanded, compact, isLoading}) {
  const theme = useTheme();

  const [expanded, setExpanded] = useState([]);

  const parentOptions = useMemo(() => {
    const parents = options.filter((option) => option?.parentId === null);
    if (defaultExpanded) {
      setExpanded(
        parents
          ?.map((parent) => parent.id)
          .slice(0, compact ? 2 : parents?.length),
      );
    }
    return parents;
  }, [options]);

  const childOptions = useMemo(
    () => options.filter((option) => option?.parentId !== null),
    [options],
  );

  const getChildren = (parentId) =>
    childOptions.filter((option) => option?.parentId === parentId) || [];

  const handleExpand = (id) => {
    if (expanded.includes(id)) {
      setExpanded(expanded.filter((expandedId) => expandedId !== id));
    } else {
      setExpanded([...expanded, id]);
    }
  };

  if (isLoading) {
    return (
      <Box>
        {[1, 2, 3, 4, 5, 6].map((i) => (
          <BaseSkeleton
            key={i}
            width="100%"
            height="37.91px"
            sx={{marginBottom: 0.5}}
          />
        ))}
      </Box>
    );
  }

  return (
    <Box sx={styles.root}>
      {parentOptions.map((parent) => (
        <Box key={parent.id} sx={styles.parent}>
          <Box sx={styles.optionHeader}>
            <Box
              sx={styles.parentItem}
              style={{padding: !compact ? '7px 16px' : 0}}>
              <Typography
                sx={styles.threatLabel}
                variant="caption"
                fontWeight={compact ? 'bold' : 'normal'}>
                {parent.name}
              </Typography>
              <Typography
                sx={styles.threatCount}
                variant="caption"
                fontWeight={compact ? 'bold' : 'normal'}>
                {parent.count}
              </Typography>
              <Box sx={styles.threatQuintile}>
                <QuintileColorScale quintile={parent.severity} />
              </Box>
            </Box>
            {!compact &&
              (expanded.includes(parent.id) ? (
                <Box sx={styles.expandIcon}>
                  <CaretCircleUp
                    size={21}
                    color={theme.palette.primary.main}
                    onClick={() => handleExpand(parent.id)}
                  />
                </Box>
              ) : (
                <Box sx={styles.expandIcon}>
                  <CaretCircleDown
                    size={21}
                    color={theme.palette.primary.main}
                    onClick={() => handleExpand(parent.id)}
                  />
                </Box>
              ))}
          </Box>
          <Collapse in={expanded.includes(parent.id)}>
            {!compact && <Divider sx={styles.divider} />}
            <Box sx={styles.collapsibleBox}>
              {getChildren(parent.id).map((children) => (
                <Box
                  key={children.id}
                  sx={styles.childItem}
                  style={compact ? styles.compactChildItem : {}}>
                  <Typography
                    sx={styles.threatLabel}
                    variant="caption"
                    fontWeight="normal">
                    {children.name}
                  </Typography>
                  <Typography
                    sx={styles.threatCount}
                    variant="caption"
                    fontWeight="normal">
                    {children.count}
                  </Typography>
                  <Box sx={styles.threatQuintile}>
                    <QuintileColorScale quintile={children.severity} />
                  </Box>
                </Box>
              ))}
            </Box>
          </Collapse>
        </Box>
      ))}
    </Box>
  );
}

QuintilesGroup.propTypes = {
  isLoading: PropTypes.bool,
  options: PropTypes.array.isRequired,
  defaultExpanded: PropTypes.bool,
  compact: PropTypes.bool,
};

QuintilesGroup.defaultProps = {
  options: [],
  defaultExpanded: false,
  compact: false,
};

export default QuintilesGroup;
