import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Popover} from '@mui/material';
import {
  NotificationCenter,
  useSocket,
  useNotifications,
} from '@novu/notification-center';
import {useSnackbar} from '@/hooks/useSnackbar';
import {useNotificationTrayTheme} from '@/hooks/useNotificationTrayTheme';
import {useRouter} from '@/hooks/useRouter';

// All notification actions are defined here.
// The key is the templateIdentifier (workflowId), the value is an object with the action type as key and the action function as value.
const notificationActions = {
  'report-download': {
    primary: (message) => {
      const url = message.payload?.downloadLink;
      if (url) {
        window.open(url, '_blank', 'noreferrer');
      }
    },
  },
};

function NotificationTray({anchorEl, onClose}) {
  const {socket} = useSocket();
  const {showSnackbar} = useSnackbar();
  const notifications = useNotifications();
  const {pushRoute} = useRouter();

  // Theme configuration for the notification center
  const novuTheme = useNotificationTrayTheme();

  // Mark all notification as seen when the notification tray is opened as they are fetched
  useEffect(() => {
    if (Boolean(anchorEl) && notifications && notifications?.unseenCount > 0) {
      notifications.markFetchedNotificationsAsSeen();
    }
  }, [anchorEl, notifications.isFetching]);

  // Handle action click
  const handleAction = (id, type, message) => {
    notifications.markNotificationAsRead(message.id);
    const fn = notificationActions[id]?.[type];
    if (typeof fn === 'function') {
      fn(message);
    }
  };

  // Handle notification click. If internal link, redirect to the page. If external link, open in new tab.
  const handleNotificationClick = (message) => {
    const url = message.cta?.data?.url;
    if (url && url.length > 0) {
      if (url.startsWith('/')) {
        pushRoute(url);
        onClose();
      } else {
        window.open(url, '_blank', 'noreferrer');
      }
    }
  };

  // Build actions from the notification message
  const getSnackbarActions = (message) => {
    const buttons = message.cta?.action?.buttons;
    if (!buttons) return [];
    return buttons.map((button) => ({
      label: button.content,
      props: {
        variant: button.type === 'primary' ? 'contained' : 'outlined',
        onClick: () =>
          handleAction(message.templateIdentifier, button.type, message),
      },
    }));
  };

  // Show snackbar when a new notification is received
  useEffect(() => {
    if (socket) {
      socket.on('notification_received', (data) => {
        const message = data?.message;
        const actor = message?.actor;
        const actions = getSnackbarActions(message);

        const redirect = message.cta?.data?.url;

        if (message) {
          showSnackbar({
            id: message.id,
            icon: actor?.type === 'system_icon' ? actor?.data : 'bell',
            iconColor: actor?.type === 'system_icon' ? actor?.data : 'primary',
            message: message.content,
            duration: actions.length === 0 ? 5000 : 10000,
            actions,
            onClick:
              (redirect?.length ?? 0) > 0
                ? () => {
                    handleNotificationClick(message);
                  }
                : null,
          });
        }
      });
    }
    return () => {
      if (socket) {
        socket.off('notification_received');
      }
    };
  }, [socket]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      transformOrigin={{
        horizontal: -10,
        vertical: 0,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <NotificationCenter
        colorScheme="dark"
        onActionClick={handleAction}
        onNotificationClick={handleNotificationClick}
        theme={novuTheme}
        footer={() => undefined}
      />
    </Popover>
  );
}

NotificationTray.propTypes = {
  anchorEl: PropTypes.any.isRequired,
  onClose: PropTypes.func,
};

export default NotificationTray;
