export const styles = {
  root: (theme) => ({
    overflow: 'auto',
    height: '100vh',
    borderRadius: 2,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  container: (theme) => ({
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  content: {
    paddingTop: '16px',
    paddingBottom: '64px',
  },
  header: {
    display: 'flex',
    gap: 1,
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    actions: {
      marginRight: '12px',
      backgroundColor: (theme) => theme.palette.background.contrast.dark,
    },
  },
  listColumns: {
    paddingTop: '4px',
    paddingBottom: '12px',
    text: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
  downloadButton: (theme) => ({
    '&:active, &:hover': {
      backgroundColor: theme.palette.background.light,
    },
    fontSize: '0.875rem',
    fontWeight: 'bold',
    lineHeight: '1.0256rem',
    backgroundColor: (theme) => theme.palette.background.contrast.dark,
  }),
  separator: (theme) => ({
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
  sortSelect: (theme) => ({
    height: '32px',
    width: '240px',
    backgroundColor: theme.palette.background.light,
  }),
};
