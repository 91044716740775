import ChangeSection from '@components/analytics/sections/ChangeSection';
import EventSummarySection from '@components/analytics/sections/EventSummarySection';
import EventBreakdownSection from '@components/analytics/sections/EventBreakdownSection';
import DataSourceInsightSection from '@components/analytics/sections/DataSourceInsightSection';
import SeverityScoreSection from '@components/analytics/sections/SeverityScoreSection';
import DataSourceReportingSection from '@components/analytics/sections/DataSourceReportingSection';
import ThreatEventsTable from '@components/analytics/ThreatEventsTable';
import EventTrendSection from '@/components/analytics/sections/EventTrendSection';
import PeriodAverageSection from '@/components/analytics/sections/PeriodAverageSection';
import NotableEventsSections from '@/components/analytics/sections/NotableEventsSections';
import TeaserBannerSection from '@/components/analytics/sections/TeaserBannerSection';
import OverviewSection from '@/components/analytics/sections/OverviewSection';
import EventCompositionSection from '@/components/analytics/sections/EventCompositionSection';

const AnalyticsComponents = [
  //  Overview Panel
  {
    id: 'location-overview',
    analyticsType: ['overview'],
    granularity: ['radius', 'district', 'city'],
    component: OverviewSection,
  },
  // Threat Breakdown Panel
  {
    id: 'chart-event-summary',
    analyticsType: ['breakdown'],
    granularity: ['radius', 'district', 'city'],
    component: EventSummarySection,
  },
  {
    id: 'chart-event-breakdown',
    analyticsType: ['breakdown'],
    granularity: ['radius', 'district', 'city'],
    component: EventBreakdownSection,
  },
  // Trend Analysis Panel
  {
    id: 'chart-event-trend',
    analyticsType: ['analysis'],
    granularity: ['radius', 'district', 'city'],
    component: EventTrendSection,
  },
  {
    id: 'chart-period-average',
    analyticsType: ['analysis'],
    granularity: ['radius', 'district', 'city'],
    component: PeriodAverageSection,
  },

  // Notable Events Panel
  {
    id: 'notable-events',
    analyticsType: ['notableEvents'],
    granularity: ['radius', 'district', 'city'],
    component: NotableEventsSections,
  },

  // Events Panel
  {
    id: 'threat-event-table',
    analyticsType: ['eventList'],
    granularity: ['radius', 'district', 'city'],
    component: ThreatEventsTable,
  },

  // Comparative Panel
  {
    id: 'chart-severity-score',
    analyticsType: ['comparative'],
    granularity: ['district'],
    component: SeverityScoreSection,
  },

  // Data Source Panel
  {
    id: 'chart-source-trend',
    analyticsType: ['source'],
    granularity: ['radius', 'district', 'city'],
    component: DataSourceReportingSection,
  },
  {
    id: 'data-source-insights',
    analyticsType: ['source'],
    granularity: ['radius', 'district', 'city'],
    component: DataSourceInsightSection,
  },

  // Change Analysis Panel
  {
    id: 'chart-change-trend',
    analyticsType: ['changeAnalysis'],
    granularity: ['radius', 'district', 'city'],
    component: ChangeSection,
  },

  // Report generation only
  {
    id: 'chart-event-composition',
    analyticsType: [],
    granularity: ['radius', 'district', 'city'],
    component: EventCompositionSection,
  },
  {
    id: 'chart-change-overview',
    analyticsType: [],
    granularity: ['radius', 'district', 'city'],
    component: ChangeSection,
  },

  // Feature access only
  {
    id: 'analytics-chart-teaser',
    analyticsType: ['overview', 'changeAnalysis'],
    granularity: ['radius', 'district', 'city'],
    component: TeaserBannerSection,
  },
];

export default AnalyticsComponents;
