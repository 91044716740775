export const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: (theme) => ({
    color: theme.palette.secondary.dark,
    display: 'flex',
    alignItems: 'center',
    marginRight: '12px',
  }),
  iconButton: (theme) => ({
    margin: '0 4px',
    width: '42px',
    color: theme.palette.secondary.dark,
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: '4px',
    backgroundColor: 'transparent',
  }),
  clickedIconButton: (theme) => ({
    margin: '0 4px',
    width: '42px',
    color: theme.palette.primary.main,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',
  }),
};
