import {useMemo} from 'react';
import moment from 'moment';
import {useChart} from '@/hooks/api/useChart';
import {
  API_CHANGE_TRENDLINE,
  API_CHANGE_RADIUS_TRENDLINE,
} from '@/hooks/api/constants';

export const useTrendline = ({
  id = 'trendline',
  title = 'Trendline',
  granularity,
  aggregation,
}) => {
  const {
    data: rawData,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title,
    granularity,
    path:
      granularity !== 'radius'
        ? API_CHANGE_TRENDLINE
        : API_CHANGE_RADIUS_TRENDLINE,
    exactDates: false,
    customParams: {
      timeAggregation: 'monthly',
    },
  });

  const formatted = useMemo(() => {
    const data = rawData?.chartData;
    if (!data || data.length === 0) {
      return [];
    }

    return data.map((data) => ({
      x: moment(data.date).local().toDate(),
      y: aggregation === 'monthly' ? data.value : (data.value / 30).toFixed(2),
    }));
  }, [rawData, aggregation]);

  return {
    data: formatted,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  };
};
