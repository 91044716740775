export const styles = {
  keyTakeAwayTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: 1,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  errorAlert: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '5%',
    marginRight: '5%',
  },
  textWithEmphasis: {
    fontWeight: 'bold',
  },
  keyTakeAway: {
    display: 'inline-table',
    lineHeight: '16px',
    marginBottom: 1,
  },
  gridItem: {
    paddingTop: '4px',
  },
};
