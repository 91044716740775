import React from 'react';
import propTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {getThreatAnalyticsAreaRadius, getUserProfile} from '@/selectors';
import {
  getCrimePercentage,
  getNightDayPercentageAndDelta,
  getPropertyAndViolentCrimePercentage,
} from '@/utils/keyTakeAwaysUtils';
import BaseInjectedTypography from '@/components/common/typography/BaseInjectedTypography';
import {styles} from '@/components/analytics/threatLandscape/ThreatLandscape.styles';
import {useTranslation} from '@/hooks/useTranslation';
import {convertDistance} from '@/utils/utils';

/**
 * Displays important information about the selected radius
 *
 * @param {number} totalCount - The total number of events
 * @param {object} dayVsNightComparison - Events count and percentage for day and night
 * @param {object} propertyVsViolentComparison - Events count and percentage for property and violent crime
 * @param {object} categoryComparison - The category with the highest count and percentage of occurrences
 *
 * @returns {React.Component}
 *
 * @example
 * <ThreatLandscapeRadius {...props} />
 */
function ThreatLandscapeRadius({
  categoryComparison,
  dayVsNightComparison,
  propertyVsViolentComparison,
}) {
  const {getI18N, getThreatTypesTranslation} = useTranslation();

  const radius = useSelector(getThreatAnalyticsAreaRadius);
  const {units} = useSelector(getUserProfile);

  const unitTranslation = getI18N('units');
  const {
    highestCrimeRadius,
    dayVsNightGreaterRadius,
    dayVsNightLessRadius,
    propertyVsViolentRadius,
  } = getI18N('keyTakeaways');
  const {violentCrime, propertyCrime} = getI18N('filters');

  const {dayPercentage, delta, nightPercentage} =
    getNightDayPercentageAndDelta(dayVsNightComparison);
  const {propertyCrimePercentage, violentCrimePercentage} =
    getPropertyAndViolentCrimePercentage(propertyVsViolentComparison);

  const getTranslatedUnits = () => {
    if (units === 'mi') {
      return unitTranslation.mile;
    }
    return unitTranslation.kilometer;
  };

  const keyTakeawaysRadiusReplacement = {
    radius: `${convertDistance(radius, units)} `,
    units: getTranslatedUnits(),
    crimeCategory: getThreatTypesTranslation(categoryComparison?.categoryId),
    crimePercentage: getCrimePercentage(categoryComparison),
    nightPercentage,
    dayPercentage,
    propertyCrimePercentage,
    violentCrimePercentage,
    propertyCrime,
    violentCrime,
  };

  const keyTakeawaysRadiusStyles = {
    crimeCategory: styles.textWithEmphasis,
    crimePercentage: styles.textWithEmphasis,
    nightPercentage: styles.textWithEmphasis,
    dayPercentage: styles.textWithEmphasis,
    propertyCrimePercentage: styles.textWithEmphasis,
    violentCrimePercentage: styles.textWithEmphasis,
    propertyCrime: styles.textWithEmphasis,
    violentCrime: styles.textWithEmphasis,
  };

  return (
    <>
      {categoryComparison && (
        <BaseInjectedTypography
          textVariant="body2"
          replacementVariant="body2"
          language={highestCrimeRadius}
          replacements={keyTakeawaysRadiusReplacement}
          replacementStyles={keyTakeawaysRadiusStyles}
          dataTestId="takeaway-radius-highest-crime"
          styles={styles.keyTakeAway}
        />
      )}
      {dayVsNightComparison && delta > 0 && (
        <BaseInjectedTypography
          textVariant="body2"
          replacementVariant="body2"
          language={delta > 20 ? dayVsNightGreaterRadius : dayVsNightLessRadius}
          replacements={keyTakeawaysRadiusReplacement}
          replacementStyles={keyTakeawaysRadiusStyles}
          dataTestId="takeaway-radius-day-vs-night"
          styles={styles.keyTakeAway}
        />
      )}
      {propertyVsViolentComparison && (
        <BaseInjectedTypography
          textVariant="body2"
          replacementVariant="body2"
          language={propertyVsViolentRadius}
          replacements={keyTakeawaysRadiusReplacement}
          replacementStyles={keyTakeawaysRadiusStyles}
          dataTestId="takeaway-radius-property-vs-violent"
          styles={styles.keyTakeAway}
        />
      )}
    </>
  );
}

export default ThreatLandscapeRadius;

ThreatLandscapeRadius.defaultProps = {
  totalCount: 0,
};

ThreatLandscapeRadius.propTypes = {
  categoryComparison: propTypes.object,
  dayVsNightComparison: propTypes.object,
  propertyVsViolentComparison: propTypes.object,
};
