import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {getUserSettings} from '@/selectors';
import {CHANGE_CHART_METRICS} from '@/components/saved-locations/constants';
import {API_CHANGE_BUCKETS} from '@/hooks/api/constants';
import {useApi} from '@/hooks/api/useApi';

export const useDegreeOfChange = () => {
  const {useGetQuery} = useApi();
  const {
    myLocationsAnalysis: {
      change: {aggregation, period},
    },
  } = useSelector(getUserSettings);

  const {data: changeBuckets, isFetching} = useGetQuery({
    path: API_CHANGE_BUCKETS,
    params: {
      changeMetric: CHANGE_CHART_METRICS[period][aggregation],
    },
    config: {
      enabled: true,
    },
  });

  const data = useMemo(
    () =>
      changeBuckets?.map(({bucket, range}) => {
        if (range === 'large_increase')
          return {range, bucket: {...bucket, max: Infinity}};
        if (range === 'large_decrease')
          return {range, bucket: {min: -Infinity, ...bucket}};
        return {range, bucket};
      }),
    [changeBuckets],
  );

  return {
    data,
    isFetching,
  };
};
