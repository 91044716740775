export const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  summary: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '2.5',
  },
  textInjection: {
    marginLeft: 1,
    lineHeight: '1',
  },
};
