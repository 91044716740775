import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getClickedLocation} from '@/selectors';
import {API_THREAT_COUNT, API_THREAT_RADIUS_COUNT} from '@/hooks/api/constants';
import {useChart} from '@/hooks/api/useChart';
import {useDates} from '@/hooks/useDates';

export const useThreatCount = ({granularity}) => {
  const [monthlyEvents, setMonthlyEvents] = useState();
  const {analyticsDifferenceInMonths} = useDates();

  const {
    latitude,
    longitude,
    radiusMeters: radius,
  } = useSelector(getClickedLocation);

  const {data: threatCount, isFetching: threatCountIsLoading} = useChart({
    path: granularity === 'radius' ? API_THREAT_RADIUS_COUNT : API_THREAT_COUNT,
    granularity,
    latitude,
    longitude,
    radius,
  });

  useEffect(() => {
    setMonthlyEvents(
      !threatCount || !analyticsDifferenceInMonths
        ? undefined
        : Math.round(threatCount / analyticsDifferenceInMonths),
    );
  }, [threatCount, analyticsDifferenceInMonths]);

  return {
    threatCount,
    monthlyEvents,
    threatCountIsLoading,
  };
};
