import React from 'react';
import PropTypes from 'prop-types';
import {Box, Switch, Typography, useTheme} from '@mui/material';
import {Controller} from 'react-hook-form';

const styles = {
  root: (theme) => ({
    width: '50px',
    paddingY: '8px',
    paddingX: '0px',
    '& .Mui-checked': {transform: 'translateX(28px) !important'},
    '& .MuiSwitch-switchBase': {
      padding: 0,
      marginY: 1,
    },
    '& .MuiSwitch-track': {
      width: '100%',
      height: '100%',
      borderRadius: '20px',
      opacity: '1 !important',
      backgroundColor: theme.palette.primary.main,
      '&:after, &:before': {
        color: theme.palette.common.black,
        fontSize: '11px',
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:after': {
        left: '5px',
      },
      '&:before': {
        right: '5px',
      },
    },
    '& .MuiSwitch-thumb': {
      color: theme.palette.common.white,
      width: '22px',
      height: '22px',
      margin: '0px',
      boxShadow: 'none',
    },
  }),
};

const renderSwitch = ({checked, onChange, disabled, sx, ...props}) => (
  <Switch
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    sx={sx}
    {...props}
  />
);

function BaseSwitch({
  name,
  control,
  checked,
  onChange,
  disabled,
  label,
  checkedLabel,
  uncheckedLabel,
  justifyContent,
  labelPlacement,
  sx,
  ...props
}) {
  const theme = useTheme();

  const customStyles = styles.root(theme);

  customStyles['& .MuiSwitch-track']['&:after'].content = `"${checkedLabel}"`;
  customStyles['& .MuiSwitch-track']['&:before'].content =
    `"${uncheckedLabel}"`;

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={1}
      justifyContent={justifyContent}
      alignItems="center">
      {label && labelPlacement === 'start' && (
        <Typography variant="caption">{label}</Typography>
      )}
      {control && (
        <Controller
          name={name}
          control={control}
          render={({field: {onChange: controlChange, value: controlValue}}) => (
            <Switch
              checked={controlValue}
              onChange={(e) => controlChange(e.target.checked)}
              disabled={disabled}
              sx={sx || customStyles}
              {...props}
            />
          )}
        />
      )}
      {!control &&
        renderSwitch({
          checked,
          onChange: (_, val) => onChange(val),
          sx: sx || customStyles,
          disabled,
          ...props,
        })}
      {label && labelPlacement === 'end' && (
        <Typography variant="caption">{label}</Typography>
      )}
    </Box>
  );
}

BaseSwitch.propTypes = {
  sx: PropTypes.any,
  control: PropTypes.any,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  checkedLabel: PropTypes.string,
  uncheckedLabel: PropTypes.string,
  justifyContent: PropTypes.oneOf(['start', 'end', 'center']),
  labelPlacement: PropTypes.oneOf(['start', 'end']),
};

BaseSwitch.defaultProps = {
  labelPlacement: 'end',
  disabled: false,
  checkedLabel: 'On',
  uncheckedLabel: 'Off',
  justifyContent: 'center',
};

export default BaseSwitch;
