import image from '@images/chart-teaser-logo.png';

export const styles = {
  root: (theme) => ({
    bgcolor: theme.palette.background.paper,
    height: 550,
    width: 600,
    maxWidth: 600,
    maxHeight: 550,
    gap: 4,
  }),
  content: {
    gap: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: 230,
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingInline: 8,
    alignItems: 'center',
  },
  bullets: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 1,
  },
  title: {
    fontWeight: 'bold',
  },
  text: {
    maxWidth: '350px',
  },
  thanksText: {
    fontWeight: 'bold',
  },
};
