import React, {memo} from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import BaseButton from '@/components/common/buttons/BaseButton';
import {styles} from '@/components/user/key-management/KeyManagementGrid.styles';

function KeyManagementGrid({headers, data}) {
  if (data?.length < 1) return null;
  return (
    <Grid container>
      <Grid item container spacing={0}>
        {headers.map(({label, ...props}) => (
          <Grid item key={label} {...props} component={Box} sx={styles.columns}>
            <Typography variant="body2">{label}</Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item container spacing={0.25} sx={styles.rowsContainer}>
        {data.map(({id, ...row}) => (
          <Grid item key={id} xs={12}>
            <Card sx={styles.rows}>
              <Grid container>
                {headers.map(({key, ...header}) => (
                  <Grid
                    item
                    {...header}
                    key={key}
                    component={Box}
                    sx={styles.columns}>
                    {key !== 'actions' ? (
                      <Typography variant="body2">{row[key]}</Typography>
                    ) : (
                      row.actions?.map((action) => (
                        <BaseButton
                          key={action.label}
                          variant="text"
                          color="primary"
                          onClick={action.handler}>
                          {action.label}
                        </BaseButton>
                      ))
                    )}
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

KeyManagementGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      handler: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      xs: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

KeyManagementGrid.defaultProps = {
  data: [],
  actions: [],
  headers: [],
};

export default memo(KeyManagementGrid);
