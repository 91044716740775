import image from '@images/resize-control.svg';

export const styles = {
  tooltipUnrestData: {
    marginLeft: '15px',
  },
  resizeHandle: (theme) => ({
    backgroundColor: theme.palette.primary.main,
    width: '30px',
    height: '15px',
    padding: '4px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundImage: `url("${image}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 10px',
    backgroundPosition: 'center',
  }),
  center: (theme) => ({
    backgroundColor: theme.palette.primary.main,
    width: '10px',
    padding: 1,
    borderRadius: 8,
  }),
  magnitude: (theme) => ({
    backgroundColor: theme.palette.background.paper,
    gap: 1,
    borderRadius: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  tooltipText: {
    maxWidth: 300,
    wordWrap: 'break-word',
    fontSize: 10,
  },
};
