export const styles = {
  tab: (theme) => ({
    flexGrow: 1,
    flexShrink: 0, // This ensures the tab doesn't shrink smaller than its minWidth.
    maxWidth: '50%',
    minWidth: '60px',
    height: '32px',
    minHeight: '32px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.light,
    '&.Mui-selected': {
      color: theme.palette.background.light,
      backgroundColor: theme.palette.primary.main,
    },
  }),
  tabGroup: (theme) => ({
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    height: 'auto',
    minHeight: '32px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  }),
};
