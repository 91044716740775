export const styles = {
  menu: {
    marginTop: 1,
    '& .MuiPaper-root': {
      borderRadius: 1.5,
    },
  },
  menuItem: {
    padding: 0,
  },
  box: (theme) => ({
    width: '390px',
    padding: '12px 16px',
    background: theme.palette.action.disabledBackground,
  }),
  button: (theme) => ({
    backgroundColor: theme.palette.background.contrast.dark,
    color: theme.palette.text.primary,
    width: '111px',
    border: `0.5px solid ${theme.palette.background.contrast.dark}`,
  }),
};
