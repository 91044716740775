import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {starterItems} from '@/components/search/constants';

import {useTranslation} from '@/hooks/useTranslation';

function MenuHelp({onClose, anchorEl}) {
  const {getHelpTranslations} = useTranslation();

  const handleURL = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
    onClose();
  };

  return (
    <Menu
      data-testid="help-menu"
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}>
      {starterItems.map((item) => (
        <MenuItem
          key={item.id}
          data-testid={`help-item-${item}`}
          onClick={() => handleURL(item.url)}>
          {getHelpTranslations(item.text)}
        </MenuItem>
      ))}
    </Menu>
  );
}

MenuHelp.propTypes = {
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.any.isRequired,
};

export default MenuHelp;
