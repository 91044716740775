import {API_URL} from '@config';

export const starterItems = [
  {
    id: 0,
    text: 'Assessment Guide',
    url: 'https://drive.google.com/file/d/10KVxg_A-nepkQyUen9gZLjx4IZokDXov/view?usp=share_link',
    size: 3,
  },
  {
    id: 1,
    text: 'REST API Documentation',
    url: API_URL.replace('/v1', '/public'),
    size: 4,
  },
  {
    id: 2,
    text: 'Threat Assessment Template I',
    url: 'https://drive.google.com/file/d/13TAGoaqgfhMKrc64ZlyTKBvuPkqlhS5V/view?usp=share_link',
    size: 5,
  },
  {
    id: 3,
    text: 'Threat Assessment Template II',
    url: 'https://docs.google.com/presentation/d/1PgofMieIZB2jXyrru_p6Vy_JYqqeXzFL/edit?usp=share_link&ouid=115816705044469731724&rtpof=true&sd=true',
    size: 5,
  },
  {
    id: 4,
    text: 'Threat Assessment Template III',
    url: 'https://drive.google.com/file/d/1Uk4hzSb72tYe8bxMV24vwewnXPFrPzAt/view?usp=share_link',
    size: 5,
  },
  {
    id: 5,
    text: 'Threat Assessment Template IV',
    url: 'https://docs.google.com/document/d/1U4cs0MfJqCz59-8mygD2PZ_FwLocRtOZ/edit?usp=share_link&ouid=115816705044469731724&rtpof=true&sd=true',
    size: 5,
  },
];
