export const styles = {
  root: {
    width: '100%',
    marginBottom: 3,
  },
  widgetBox: {
    paddingLeft: 3,
    width: '100%',
    marginY: 0,
  },
  widget: {
    fontSize: '12px',
    lineHeight: '16px',
    marginBottom: 1,
  },
  textLimit: {
    backgroundColor: 'background.dark',
  },
  link: {
    fontSize: '12px',
    display: 'block',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: 3,
  },
};
