export const styles = {
  root: (theme) => ({
    color: theme.palette.text.primary,
    fontSize: '10px',
    padding: '10px 0',
    backgroundColor: theme.palette.background.light,
    borderRadius: '6px',
    overflow: 'hidden',
  }),
  innerBox: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '8px',
    columnGap: '4px',
    justifyContent: 'center',
  },
  legendItem: {
    width: '110px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    margin: '0px',
    paddingRight: '0px',
  },
  legendItemBox: {
    width: '16px',
    height: '16px',
  },
  legendLabel: {
    align: 'left',
    padding: '0 0 0 8px',
    overflowWrap: 'break-word',
  },
};
