import PropTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import {useTheme} from '@mui/material';

import {
  X,
  CheckCircle,
  Info,
  WarningCircle,
  Bell,
  CaretUp,
  Question,
  XCircle,
} from '@phosphor-icons/react';
import {useSnackbar} from '@/hooks/useSnackbar';

import BaseButton from '@/components/common/buttons/BaseButton';

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

const useStyles = () => ({
  root: (theme) => ({
    background: theme.palette.background.paper,
    flexWrap: 'nowrap',
  }),
});

const icons = {
  bell: Bell,
  check: CheckCircle,
  success: CheckCircle,
  info: Info,
  up: CaretUp,
  question: Question,
  warning: WarningCircle,
  error: XCircle,
};

function PrefixIcon({name, ...props}) {
  if (!name) return null;
  const Icon = icons[name] || Info;
  return <Icon {...props} />;
}
PrefixIcon.propTypes = {
  name: PropTypes.string,
};

function BaseSnackbar() {
  const styles = useStyles();
  const theme = useTheme();
  const {
    isOpen,
    isLoading,
    icon,
    iconColor,
    message,
    hideSnackbar,
    actions,
    onClick,
  } = useSnackbar();

  const handleActionClick = (e, action) => {
    const {onClick} = action.props;
    if (onClick) {
      onClick(e);
    }
    e.stopPropagation();
  };

  return (
    <Snackbar
      id="snackbar"
      open={isOpen}
      onClick={onClick}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      onClose={hideSnackbar}
      TransitionComponent={SlideTransition}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        top: '80px !important',
      }}>
      <SnackbarContent
        sx={styles.root}
        action={
          !isLoading ? (
            <Box display="flex" sx={{gap: 1}}>
              {actions?.map((action, index) =>
                action.component === 'icon' ? (
                  <IconButton
                    {...action.props}
                    key={index}
                    style={{color: 'white'}}
                    size="small"
                    onClick={(e) => handleActionClick(e, action)}>
                    <X size={20} color={theme.palette.text.primary} />
                  </IconButton>
                ) : (
                  <BaseButton
                    {...action.props}
                    key={index}
                    onClick={(e) => handleActionClick(e, action)}>
                    {action.label}
                  </BaseButton>
                ),
              )}
            </Box>
          ) : null
        }
        message={
          <Box display="flex" alignItems="center" justifyItems="flex-start">
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <PrefixIcon
                name={icon}
                size={24}
                color={theme.palette[iconColor]?.main || 'primary'}
              />
            )}
            <Typography
              style={{color: 'white', marginLeft: isLoading || icon ? 8 : 0}}
              variant="body2">
              {message}
            </Typography>
          </Box>
        }
      />
    </Snackbar>
  );
}

export default BaseSnackbar;
