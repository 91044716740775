export const styles = {
  root: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 16px)',
    alignItems: 'center',
    backgroundColor: theme.palette.background.light,
    borderRadius: 2,
    marginLeft: 1,
    marginRight: 2,
    marginBottom: 2,
  }),
  content: {
    paddingTop: '1.25rem',
    width: '40%',
    maxWidth: '500px',
  },
  header: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '4.1875rem',
    width: '100%',
    backgroundColor: theme.palette.background.dark,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: '1.7581rem',
  }),
  form: {
    paddingBottom: '30px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  ml1: {
    marginLeft: 1,
  },
  tabsContainer: (theme) => ({
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  }),
  actionSection: {
    marginTop: '1.25rem',
    marginBottom: '2.5rem',
    display: 'flex',
    gap: '20px',
    justifyContent: 'center',
  },
  button: {
    fontWeight: 'bold',
  },
  inputFile: {
    display: 'none',
  },
};
