import {TOP_NAVBAR_HEIGHT, LEFT_NAVBAR_WIDTH} from '@/hooks/constants';

export const styles = {
  appBar: {
    position: 'fixed',
    zIndex: (theme) => theme.zIndex.drawer + 1,
    backgroundImage: 'none',
  },
  toolbar: {
    padding: '0px !important',
  },
  navbar: {
    marginTop: `${LEFT_NAVBAR_WIDTH}px`,
  },
  main: {
    position: 'absolute',
    right: 0,
    marginTop: `${TOP_NAVBAR_HEIGHT}px`,
    height: `calc(100% - ${TOP_NAVBAR_HEIGHT}px)`,
  },
};
