import {Map as ReactMapGL} from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import {useMemo} from 'react';

import {MAPBOX_PUBLIC_KEY} from '@config';
import {INTERACTIVE_LAYERS} from '@map/constants';

import {useErrorHandler} from '@/hooks/useErrorHandler';
import {useAuth0Service} from '@/hooks/useAuth0Service';
import BaseAttribution from '@/components/common/map/BaseAttribution';

function BaseMap({mapKey, children, ...props}) {
  const {handleMapError} = useErrorHandler();
  const {reportAccessToken, accessToken} = useAuth0Service();

  const key = useMemo(() => {
    const token = reportAccessToken || accessToken;
    return `map-${mapKey}-${token.slice(0, 4) + token.slice(-4)}`;
  }, [reportAccessToken, accessToken, mapKey]);

  return (
    <ReactMapGL
      dragRotate={false}
      antialias={false}
      attributionControl={false}
      preserveDrawingBuffer
      minZoom={2}
      interactiveLayerIds={INTERACTIVE_LAYERS}
      {...props}
      id={key}
      key={key}
      mapboxAccessToken={MAPBOX_PUBLIC_KEY}
      mapLib={mapboxgl}
      onError={handleMapError}>
      {children}
      <BaseAttribution />
    </ReactMapGL>
  );
}

BaseMap.propTypes = {
  mapKey: PropTypes.string,
  children: PropTypes.node,
  mapStyle: PropTypes.string,
};

export default BaseMap;
