import {Box, Typography, Link} from '@mui/material';
import {styles} from '@/components/analytics/sections/DataSourceAttribution.styles';
import {useTranslation} from '@/hooks/useTranslation';

function DataSourceAttribution() {
  const {getI18N} = useTranslation();
  const attributionText = getI18N('threatAnalytics.acledAttribution');

  return (
    <Box sx={styles.root}>
      <Typography variant="caption" sx={styles.attribution}>
        {attributionText};&nbsp;
        <Link
          href="https://www.acleddata.com"
          target="_blank"
          rel="noopener noreferrer">
          www.acleddata.com
        </Link>
        .
      </Typography>
    </Box>
  );
}

export default DataSourceAttribution;
