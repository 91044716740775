import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import BaseIconButton from '@common/buttons/BaseIconButton';
import {X} from '@phosphor-icons/react';

const styles = {
  root: (theme) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: theme.palette.background.light,
    border: 'none',
    borderRadius: '8px',
    padding: '8px',
    width: '100%',
    maxWidth: '500px',
  }),
};

function BaseModal({children, title, sx, ...props}) {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box
        sx={(theme) => ({
          ...styles.root(theme),
          ...(typeof sx === 'function' ? sx(theme) : sx),
        })}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '20px',
          }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
            }}>
            {title}
          </Typography>
          <BaseIconButton
            sx={{backgroundColor: sx.backgroundColor ?? sx.bgcolor}}
            icon={X}
            backgroundColor=""
            onClick={() =>
              props.handleCloseButton
                ? props.handleCloseButton()
                : props.onClose()
            }
          />
        </Box>
        <Box
          sx={{
            paddingTop: '8px',
            paddingBottom: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            height: '100%',
          }}>
          {children}
        </Box>
      </Box>
    </Modal>
  );
}

BaseModal.defaultProps = {
  open: false,
  onClose: () => {},
  title: '',
  children: null,
  sx: {},
};

BaseModal.propTypes = {
  open: PropTypes.bool,
  handleCloseButton: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default BaseModal;
