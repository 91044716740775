import {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';

import {
  ArrowRight,
  Clock,
  ListMagnifyingGlass,
  Question,
  Star,
  X,
} from '@phosphor-icons/react';

import {API_SAVED_LOCATIONS} from '@hooks/api/constants';
import {useApi} from '@hooks/api/useApi';
import {getInputValue, getModal, getSuggestions} from '@/selectors';
import {
  displaySearchModal,
  setRecentSearches,
} from '@/store/modules/modal/actions';
import {setSelectedLocation} from '@/store/modules/user/actions';
import {setSearchValue} from '@/store/modules/filters/actions';
import {setLocationAddress, setRoutedToMap} from '@/store/modules/map/actions';
import {starterItems} from '@/components/search/constants';
import {useRouter} from '@/hooks/useRouter';
import SearchInput from '@/components/search/SearchInput';
import SearchCard from '@/components/search/SearchCard';
import {useTranslation} from '@/hooks/useTranslation';
import BaseIconButton from '@/components/common/buttons/BaseIconButton';
import BaseButton from '@/components/common/buttons/BaseButton';

const useStyles = () => ({
  modal: (theme) => ({
    '& .MuiBackdrop-root': {
      backgroundColor: theme.palette.background.blur,
    },
  }),
  root: (theme) => ({
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    border: `2px solid ${theme.palette.background.paper}`,
    outline: 'none',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '700px',
      maxHeight: '650px',
      width: '100%',
      height: '100%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      height: '100vh',
    },
  }),
  content: {
    overflowY: 'scroll',
    paddingTop: 3,
    paddingBottom: 4,
    paddingX: 4,
  },
  subtitleContainer: {
    display: 'flex',
    paddingBlock: '10px',
  },
  savedLocationButton: {
    textTransform: 'none',
    height: '24px',
  },
  closeIcon: {
    marginRight: 1,
    marginTop: 1,
  },
  searchContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
  },
  resultsContainer: {
    paddingBottom: '16px',
  },
  gettingStartedContent: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  linkTag: (theme) => ({
    marginRight: 1,
    marginBottom: 1,
    paddingX: 2,
    paddingY: 1,
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: theme.palette.background.light,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    backgroundImage: 'none',
  }),
  subtitleIcon: {
    marginRight: '12px',
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'right',
  },
  searchInput: {
    paddingY: '17.5px',
    border: 'none',
    backgroundColor: 'transparent',
    width: '100%',
    justifyContent: 'end',
    '& input::placeholder': {
      fontSize: '20px',
    },
    '& input': {
      fontSize: '20px',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
});

function SearchModal() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const {getI18N, getHelpTranslations} = useTranslation();
  const {pushDashRoute, pushMapRoute} = useRouter();
  const {useGetQuery} = useApi();
  const {
    savedLocationsTitle,
    gettingStarted,
    recentTranslation,
    searchResults,
    seeSavedLocations,
    more,
  } = getI18N('searchModal');

  const {isSearchOpen, recentSearches} = useSelector(getModal);
  const suggestions = useSelector(getSuggestions);
  const inputValue = useSelector(getInputValue);

  const [savedDefault, setSavedDefault] = useState([]);
  const [savedSearch, setSavedSearch] = useState([]);

  const {data: savedLocations} = useGetQuery({
    path: API_SAVED_LOCATIONS,
    params: {
      search: inputValue,
      page: 1,
      take: 6,
      order: 'ASC',
    },
    config: {
      enabled: isSearchOpen,
    },
  });

  const handleClose = () => {
    dispatch(displaySearchModal(false));
  };

  const handleRecent = useCallback(
    ({id, text, caption, action, type}) => {
      dispatch(
        setRecentSearches(
          [
            {id, text, caption, action, type},
            ...recentSearches.filter((location) => location.id !== id),
          ].slice(0, 3),
        ),
      );
    },
    [recentSearches],
  );

  const navigateToGoogleSearch = useCallback(
    (location) => {
      pushMapRoute();
      dispatch(setSearchValue(location.place_id));
    },
    [dispatch],
  );

  const navigateToSavedSearch = useCallback(
    (location) => {
      pushMapRoute();
      dispatch(setSelectedLocation(location));
      dispatch(setRoutedToMap(true));
    },
    [pushMapRoute, dispatch],
  );

  const handleGoogleSearch = useCallback(
    (location) => {
      handleRecent({
        id: location.place_id,
        text: location.structured_formatting.main_text,
        caption: location.structured_formatting.secondary_text,
        action: () => navigateToGoogleSearch(location),
        type: 'google',
      });
      navigateToGoogleSearch(location);
    },
    [handleRecent, navigateToGoogleSearch],
  );

  const handleSavedSearch = (location) => {
    handleRecent({
      id: location.id,
      text: location.name,
      caption: location.address,
      action: () => navigateToSavedSearch(location),
      type: 'saved',
    });
    navigateToSavedSearch(location);
  };

  const handleMore = () => {
    pushDashRoute();
    handleClose();
  };

  const seeMoreSaved = () => {
    dispatch(setLocationAddress(inputValue));
    pushDashRoute();
    handleClose();
  };

  const handleURL = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    if (savedLocations) {
      if (savedDefault?.length < 1) {
        setSavedDefault(savedLocations.data);
      }

      if (inputValue) {
        setSavedSearch(savedLocations.data);
      }
    }
  }, [savedLocations]);

  return (
    <Modal sx={styles.modal} open={isSearchOpen} onClose={handleClose}>
      <Box sx={styles.root}>
        <Box sx={styles.searchContainer}>
          <SearchInput sx={styles.searchInput} />
          <BaseIconButton
            sx={styles.closeIcon}
            onClick={handleClose}
            icon={X}
          />
        </Box>
        <Divider />
        <Box sx={styles.content}>
          {inputValue &&
            (savedSearch.length > 0 || suggestions?.length > 0) && (
              <Box sx={styles.resultsContainer}>
                <Box sx={styles.subtitleContainer}>
                  <ListMagnifyingGlass size={20} style={styles.subtitleIcon} />
                  <Typography>{searchResults}</Typography>
                  <Box flexGrow="1" />
                  {savedSearch.length > 0 && (
                    <BaseButton
                      variant="outlined"
                      onClick={seeMoreSaved}
                      sx={styles.savedLocationButton}>
                      {seeSavedLocations} &nbsp;
                      <ArrowRight />
                    </BaseButton>
                  )}
                </Box>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{xs: 1, sm: 2, md: 3}}>
                  {savedSearch?.map((location) => (
                    <Grid key={location.id} item xs={12}>
                      <SearchCard
                        text={location.name}
                        caption={location.address}
                        action={() => handleSavedSearch(location)}
                        type="saved"
                      />
                    </Grid>
                  ))}
                  {suggestions?.map((location) => (
                    <Grid key={location.place_id} item xs={12}>
                      <SearchCard
                        text={location.structured_formatting.main_text}
                        caption={location.structured_formatting.secondary_text}
                        action={() => handleGoogleSearch(location)}
                        type="google"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          {recentSearches.length > 0 && (
            <Box sx={styles.resultsContainer}>
              <Box sx={styles.subtitleContainer}>
                <Clock size={20} style={styles.subtitleIcon} />
                <Typography>{recentTranslation}</Typography>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{xs: 1, sm: 2, md: 3}}>
                {recentSearches?.map((location) => (
                  <Grid key={location.id} item xs={12}>
                    <SearchCard
                      key={location.id}
                      sx={styles.recentCard}
                      {...location}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {savedDefault.length > 0 && (
            <Box sx={styles.resultsContainer}>
              <Box sx={styles.subtitleContainer}>
                <Star size={20} style={styles.subtitleIcon} />
                <Typography>{savedLocationsTitle}</Typography>
              </Box>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{xs: 1, sm: 2, md: 3}}>
                {savedDefault?.map((location) => (
                  <Grid key={location.id} item xs={12} sm={6}>
                    <SearchCard
                      sx={styles.savedLocationCard}
                      text={location.name}
                      caption={location.address}
                      action={() => handleSavedSearch(location)}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box sx={styles.linkContainer}>
                <Link onClick={handleMore} component="button">
                  <Typography variant="body2">{more}</Typography>
                </Link>
              </Box>
            </Box>
          )}
          <Box>
            <Box sx={styles.subtitleContainer}>
              <Question size={20} style={styles.subtitleIcon} />
              <Typography>{gettingStarted}</Typography>
            </Box>
            <Box sx={styles.gettingStartedContent}>
              {starterItems.map((item) => (
                <LinkTag
                  key={item.id}
                  text={getHelpTranslations(item.text)}
                  action={() => handleURL(item.url)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

function LinkTag({text, action}) {
  const styles = useStyles();
  return (
    <Card elevation={3} sx={styles.linkTag} onClick={action}>
      <Typography variant="body2">{text}</Typography>
    </Card>
  );
}

LinkTag.propTypes = {
  text: PropTypes.string,
  action: PropTypes.func,
};

export default SearchModal;
