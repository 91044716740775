// Packages
import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';

// MUI
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

// Icons
import {User, Camera} from '@phosphor-icons/react';

// Styles
import {styles} from '@/components/user/UserAvatar.styles';

function UserAvatar({user, size, photoSize, isEditable, imgPreview}) {
  const userContent = useMemo(() => {
    if (user?.photo?.length > 0) return null;
    if (user?.firstName && user?.lastName) {
      return `${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`;
    }
    return isEditable ? (
      <Camera size={32} weight="bold" />
    ) : (
      <User weight="bold" />
    );
  }, [user]);

  const avatarUrl = useMemo(() => {
    if (imgPreview) return imgPreview;
    if (user?.photo?.length > 0) {
      return `${user?.photo}/photo_${photoSize}.png`;
    }
    return null;
  }, [user, imgPreview]);

  useEffect(() => () => {
    if (imgPreview) URL.revokeObjectURL(imgPreview);
  });

  return (
    <Box sx={styles.avatarContainer}>
      <Avatar
        alt="User Avatar"
        src={avatarUrl}
        sx={(theme) => ({
          width: size,
          height: size,
          bgcolor: theme.palette.background.dark,
          color: theme.palette.secondary.light,
        })}>
        {userContent}
      </Avatar>
    </Box>
  );
}

export default UserAvatar;

UserAvatar.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    photo: PropTypes.string,
  }),
  isEditable: PropTypes.bool,
  size: PropTypes.string,
  photoSize: PropTypes.oneOf(['sm', 'md', 'lg']),
  imgPreview: PropTypes.string,
};

UserAvatar.defaultProps = {
  isEditable: false,
  size: '4.25rem',
  photoSize: 'sm',
};
