import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {CaretRight} from '@phosphor-icons/react';
import {Box, Typography, useTheme} from '@mui/material';

import {
  getClickedLocationName,
  getDistricts,
  getLocation,
  getClickedLocationHood,
  getGranularity,
} from '@/selectors';

import {useRouter} from '@/hooks/useRouter';
import BaseButton from '@/components/common/buttons/BaseButton';
import {styles} from '@/components/navigation/list/TopNavigation.styles';

import {useTranslation} from '@/hooks/useTranslation';

function Breadcrumbs() {
  const theme = useTheme();
  const {getI18N} = useTranslation();
  const {reload, route, isMapRoute} = useRouter();
  const {breadcrumbs} = getI18N('horizontalNavBar');

  const {name: locationName} = useSelector(getLocation);
  const savedName = useSelector(getClickedLocationName);
  const {selectedDistricts} = useSelector(getDistricts);

  const granularity = useSelector(getGranularity);
  const savedDistrict = useSelector(getClickedLocationHood);

  const pageTitle = useMemo(
    () => breadcrumbs[route?.substr(1) ?? ''] || breadcrumbs.home,
    [breadcrumbs, route],
  );
  const cityTitle = useMemo(
    () => locationName && `${locationName.split(',', 1)?.[0] || locationName}`,
    [locationName],
  );
  const locationTitle = useMemo(() => {
    if (selectedDistricts?.length > 0 && granularity === 'district') {
      return selectedDistricts?.map(({displayName}) => displayName).join(', ');
    }
    if (savedDistrict?.name && granularity === 'district') {
      return savedDistrict.name;
    }
    if (savedName && granularity === 'radius') {
      return savedName;
    }
  }, [savedName, selectedDistricts, granularity, savedDistrict]);

  const handleRoute = () => reload();

  return (
    <Box sx={styles.locationActions}>
      <BaseButton
        variant="text"
        sx={styles.button}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={handleRoute}>
        <Typography align="center" variant="body2" sx={styles.titleText}>
          {pageTitle}
        </Typography>
      </BaseButton>
      {cityTitle && isMapRoute && (
        <>
          <CaretRight
            size={14}
            color={theme.palette.secondary.main}
            sx={styles.greaterThanIcon}
          />
          <Typography variant="body2" sx={styles.locationText}>
            {cityTitle}
          </Typography>
        </>
      )}
      {locationTitle && isMapRoute && (
        <>
          <CaretRight
            size={14}
            color={theme.palette.secondary.main}
            sx={styles.greaterThanIcon}
          />
          <Typography variant="body2" sx={styles.locationText}>
            {locationTitle}
          </Typography>
        </>
      )}
    </Box>
  );
}

export default Breadcrumbs;
