import propTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useTheme} from '@mui/material';

import {getViewTypeCity, getLocation} from '@/selectors';
import {
  getCrimePercentage,
  getNightDayPercentageAndDelta,
  getPropertyAndViolentCrimePercentage,
} from '@/utils/keyTakeAwaysUtils';

import BaseInjectedTypography from '@/components/common/typography/BaseInjectedTypography';
import {styles} from '@/components/analytics/threatLandscape/ThreatLandscape.styles';
import {useTranslation} from '@/hooks/useTranslation';

/**
 * Displays important information about the selected district or city
 *
 * @param {number} totalCount - The total number of events
 * @param {object} dayVsNightComparison - Events count and percentage for day and night
 * @param {object} propertyVsViolentComparison - Events count and percentage for property and violent crime
 * @param {object} districtComparison - The hood quintile and its threat level
 * @param {object} categoryComparison - The category with the highest count and percentage of occurrences
 * @param {string} tab - The current selected tab
 *
 * @returns {React.Component}
 *
 * @example
 * <ThreatLandscape {...props} tab="district | location" />
 */
function ThreatLandscape({
  totalCount,
  dayVsNightComparison,
  propertyVsViolentComparison,
  districtComparison,
  categoryComparison,
  tab,
}) {
  const theme = useTheme();
  const {getI18N, getThreatTypesTranslation} = useTranslation();
  const isCity = useSelector(getViewTypeCity);
  const {name: locationName} = useSelector(getLocation);
  const {
    quintiles,
    districtCompare,
    highestCrimeDistrict,
    highestCrimeCity,
    dayVsNightGreaterDistrict,
    dayVsNightGreaterCity,
    dayVsNightLessDistrict,
    dayVsNightLessCity,
    propertyVsViolentDistrict,
    propertyVsViolentCity,
  } = getI18N('keyTakeaways');
  const {violentCrime, propertyCrime} = getI18N('filters');
  const {tabCity: cityTranslation, tabCountry: countryTranslation} =
    getI18N('threatAnalytics');

  const isDistrict = tab === 'district';

  const {dayPercentage, delta, nightPercentage} =
    getNightDayPercentageAndDelta(dayVsNightComparison);
  const {propertyCrimePercentage, violentCrimePercentage} =
    getPropertyAndViolentCrimePercentage(propertyVsViolentComparison);

  const keyTakeAwayHoodReplacement = {
    totalCount,
    quintile: districtComparison?.hoodQuintile,
    locationType: isCity
      ? cityTranslation.toLowerCase()
      : countryTranslation.toLowerCase(),
    threatLevel: quintiles[districtComparison?.hoodQuintile],
    crimeCategory: getThreatTypesTranslation(categoryComparison?.categoryId),
    crimePercentage: getCrimePercentage(categoryComparison),
    nightPercentage,
    dayPercentage,
    propertyCrimePercentage,
    violentCrimePercentage,
    propertyCrime,
    violentCrime,
    locationName: (locationName || '').split(',')[0],
  };

  const keyTakeAwayHoodStyles = {
    totalCount: styles.textWithEmphasis,
    threatLevel: {
      ...styles.textWithEmphasis,
      color: theme.palette.quintile[districtComparison?.hoodQuintile],
    },
    crimeCategory: styles.textWithEmphasis,
    crimePercentage: styles.textWithEmphasis,
    nightPercentage: styles.textWithEmphasis,
    dayPercentage: styles.textWithEmphasis,
    propertyCrimePercentage: styles.textWithEmphasis,
    violentCrimePercentage: styles.textWithEmphasis,
    propertyCrime: styles.textWithEmphasis,
    violentCrime: styles.textWithEmphasis,
  };

  const dayVsNightLanguage = () => {
    if (delta >= 20) {
      if (isDistrict) {
        return dayVsNightGreaterDistrict;
      }
      return dayVsNightGreaterCity;
    }
    if (isDistrict) {
      return dayVsNightLessDistrict;
    }
    return dayVsNightLessCity;
  };

  return (
    <>
      {isDistrict && (
        <BaseInjectedTypography
          textVariant="body2"
          replacementVariant="body2"
          language={districtCompare}
          replacements={keyTakeAwayHoodReplacement}
          replacementStyles={keyTakeAwayHoodStyles}
          dataTestId="takeaway-hood-events-count"
          styles={styles.keyTakeAway}
        />
      )}
      {categoryComparison && (
        <BaseInjectedTypography
          textVariant="body2"
          replacementVariant="body2"
          language={isDistrict ? highestCrimeDistrict : highestCrimeCity}
          replacements={keyTakeAwayHoodReplacement}
          replacementStyles={keyTakeAwayHoodStyles}
          dataTestId="takeaway-hood-highest-crime"
          styles={styles.keyTakeAway}
        />
      )}
      {dayVsNightComparison && delta > 0 && (
        <BaseInjectedTypography
          textVariant="body2"
          replacementVariant="body2"
          language={dayVsNightLanguage()}
          replacements={keyTakeAwayHoodReplacement}
          replacementStyles={keyTakeAwayHoodStyles}
          dataTestId="takeaway-hood-day-vs-night"
          styles={styles.keyTakeAway}
        />
      )}
      {propertyVsViolentComparison && (
        <BaseInjectedTypography
          textVariant="body2"
          replacementVariant="body2"
          language={
            isDistrict ? propertyVsViolentDistrict : propertyVsViolentCity
          }
          replacements={keyTakeAwayHoodReplacement}
          replacementStyles={keyTakeAwayHoodStyles}
          dataTestId="takeaway-hood-property-vs-violent"
          styles={styles.keyTakeAway}
        />
      )}
    </>
  );
}

export default ThreatLandscape;

ThreatLandscape.defaultProps = {
  totalCount: 0,
};

ThreatLandscape.propTypes = {
  categoryComparison: propTypes.object,
  dayVsNightComparison: propTypes.object,
  districtComparison: propTypes.object,
  propertyVsViolentComparison: propTypes.object,
  tab: propTypes.string,
  totalCount: propTypes.number.isRequired,
};
