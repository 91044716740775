import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import BaseSkeleton from '@common/BaseSkeleton';
import ThreatLandscapeRadius from '@/components/analytics/threatLandscape/ThreatLandscapeRadius';
import ThreatLandscape from '@/components/analytics/threatLandscape/ThreatLandscape';

/**
 * Displays important information about the selected radius, district or city
 *
 * @param {string} granularity - The current selected tab
 * @returns {React.Component} - The component
 *
 * @example
 * <ThreatLandscapeSummary granularity="radius" />
 */
function ThreatLandscapeSummary({granularity, data, isLoading, isVisible}) {
  if (isLoading) {
    return <ThreatLandscapeLoader />;
  }
  if (isVisible && granularity === 'radius') {
    return <ThreatLandscapeRadius {...data} />;
  }
  if (isVisible) {
    return <ThreatLandscape {...data} tab={granularity} />;
  }

  return null;
}

ThreatLandscapeSummary.propTypes = {
  granularity: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  data: PropTypes.object,
};

export default ThreatLandscapeSummary;

function ThreatLandscapeLoader() {
  return (
    <Box>
      {[0, 1, 2, 3, 4].map((index) => (
        <BaseSkeleton key={index} height="16px" sx={{marginBottom: 1}} />
      ))}
      <BaseSkeleton width="80%" height="16px" sx={{marginBottom: 1}} />
    </Box>
  );
}
