export const styles = {
  wrapper: {
    border: 1,
    paddingX: 0.75,
    paddingY: 0.25,
    borderColor: 'white',
    borderRadius: '4px',
    alignContent: 'center',
    justifyContent: 'center',
  },
  label: {fontSize: 10, color: 'white', textTransform: 'uppercase'},
};
