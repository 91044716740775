import {useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {useTranslation} from '@hooks/useTranslation';
import {toggleAllSources, setSources} from '@/store/modules/filters/actions';
import {
  getSources,
  getSelectedSources,
  getSourceCategories,
  getSummary,
} from '@/selectors';

import BaseExpandableCheckboxGroup from '@/components/common/checkboxes/BaseExpandableCheckboxGroup';
import FilterItem from '@/components/filters/FilterItem';
import BaseCheckbox from '@/components/common/checkboxes/BaseCheckbox';

function FilterSources() {
  const dispatch = useDispatch();
  const {getI18N} = useTranslation();

  const sources = useSelector(getSources);
  const selectedSources = useSelector(getSelectedSources);
  const sourceCategories = useSelector(getSourceCategories);
  const {sourceThreatCategories} = useSelector(getSummary);

  const sourcesLabel = getI18N('filters.sources');
  const sourceTitle = getI18N('sourceCategories');
  const {unassignedSource} = getI18N('tooltips');
  const allSelected = Object.values(sources).every(Boolean);
  const someSelected = Object.values(sources).some(Boolean);

  const locationSources = useMemo(() => {
    let sortedChildSources =
      sourceThreatCategories?.map(({sourceCategoryId}) =>
        sourceCategories.find(({id}) => sourceCategoryId === id),
      ) ?? sourceCategories;

    // Remove undefined values if sourceThreatCategories is empty or changed
    sortedChildSources = sortedChildSources.filter((source) => source != null);

    const children = sortedChildSources.filter(({id}) =>
      Object.keys(sources).map(Number).includes(id),
    );

    const parents = sortedChildSources
      .map(({parentId}) => sourceCategories.find(({id}) => id === parentId))
      .filter((parent) => parent);

    return [...new Set(parents), ...children].map((source) => ({
      ...source,
      name: sourcesLabel[source.id],
      tooltip: source.id === 18 ? unassignedSource : undefined,
    }));
  }, [sourceCategories, sources]);

  const handleChange = (sources) => {
    dispatch(setSources(sources));
  };

  const handleToggleAllSources = () => {
    dispatch(toggleAllSources());
  };

  return (
    <FilterItem
      label={
        <BaseCheckbox
          bold
          label={sourceTitle}
          checked={allSelected}
          indeterminate={someSelected && !allSelected}
          onClick={handleToggleAllSources}
        />
      }>
      <BaseExpandableCheckboxGroup
        options={locationSources}
        selected={selectedSources}
        onChange={handleChange}
        dense
      />
    </FilterItem>
  );
}
export default FilterSources;
