import React, {useId} from 'react';
import propTypes from 'prop-types';
import Typography from '@mui/material/Typography';

/**
 * Replaces the text in a string with a typography component with the given variant and styles for each replacement
 * @param {string} language - The string to be replaced
 * @param {object} replacements - The replacements to be made
 * @param {string} replacementVariant - The variant of the replacement typography component
 * @param {object} replacementStyles - The styles of the replacement typography component
 * @param {string} dataTestId - The data test id of the component
 * @param {string} variant - The variant of the main typography component
 * @param {object} styles - The styles of the main typography component
 * @returns {JSX.Element}
 *
 * @example
 * const {getI18N} = useTranslation();
 * const {emptyTableLabel} = getI18N('legendQuintile');
 *
 * <BaseInjectedTypography
 *  language={emptyTableLabel}
 *  replacements={{
 *    r: 2,
 *    u: 'kilometers',
 *  }}
 *  replacementStyles={{
 *    r: {
 *      fontWeight: 'bold',
 *    },
 *    u: {
 *      fontWeight: 'bold',
 *    },
 *   }}
 *  dataTestId="key-take-away-radius"
 * />
 */
function BaseInjectedTypography({
  language,
  replacements,
  replacementVariant,
  replacementStyles,
  dataTestId,
  textVariant,
  styles,
}) {
  const id = useId();

  if (!language) return null;
  const languageSubstrings = language.split(/({[^{}]+})/);

  const injectedText = languageSubstrings.map((languageSubstring) => {
    if (languageSubstring.startsWith('{') && languageSubstring.endsWith('}')) {
      const cleanedPart = languageSubstring.replace('{', '').replace('}', '');

      return (
        <Typography
          key={cleanedPart + id}
          variant={replacementVariant}
          component="span"
          sx={{
            ...replacementStyles[cleanedPart],
            display: 'inline',
          }}>
          {replacements[cleanedPart]}
        </Typography>
      );
    }

    return languageSubstring;
  });

  return (
    <Typography
      key={`injected-text${id}`}
      align="left"
      variant={textVariant}
      component="p"
      data-testid={dataTestId}
      sx={styles}>
      {injectedText}
    </Typography>
  );
}

export default BaseInjectedTypography;

BaseInjectedTypography.propTypes = {
  dataTestId: propTypes.string,
  language: propTypes.string.isRequired,
  replacements: propTypes.object.isRequired,
  replacementStyles: propTypes.object,
  replacementVariant: propTypes.string,
  styles: propTypes.object,
  textVariant: propTypes.string,
};

BaseInjectedTypography.defaultProps = {
  replacementStyles: {},
  replacementVariant: 'subtitle2',
  styles: {
    display: 'inline-table',
    fontSize: 14,
  },
  textVariant: 'subtitle2',
};
