import {useSelector} from 'react-redux';
import {useMemo} from 'react';

import {
  API_SAVED_LOCATIONS_BREAKDOWN_BY_TYPE,
  API_SAVED_LOCATIONS_BREAKDOWN_BY_TAG,
} from '@hooks/api/constants';

import {useApi} from '@/hooks/api/useApi';
import {getChartColors} from '@/components/common/charts/ChartTheme';
import {getSavedLocations, getUserSettings} from '@/selectors';
import {CHART_DISPLAY_OPTIONS} from '@/hooks/constants';
import {useChartTranslation} from '@/hooks/charts/useChartTranslation';
import {useTranslation} from '@/hooks/useTranslation';
import {
  CHANGE_CHART_METRICS,
  DEGREE_OF_CHANGE,
} from '@/components/saved-locations/constants';

const MAX_ITEMS = 5;

export const useLocationsBreakdown = ({option}) => {
  const {useGetQuery} = useApi();
  const {translateLocationTag, translateLocationType} = useChartTranslation();
  const {getI18N} = useTranslation();
  const {remaining} = getI18N('savedLocations');
  const {
    analysisFilters: {addressTypes, tags, visibility, degreesOfChange},
  } = useSelector(getSavedLocations);
  const {
    myLocationsAnalysis: {
      change: {aggregation, period},
    },
  } = useSelector(getUserSettings);

  const {data, isFetching} = useGetQuery({
    path:
      option === CHART_DISPLAY_OPTIONS.TYPE
        ? API_SAVED_LOCATIONS_BREAKDOWN_BY_TYPE
        : API_SAVED_LOCATIONS_BREAKDOWN_BY_TAG,
    params: {
      addressTypes:
        addressTypes?.length > 0 ? JSON.stringify(addressTypes) : undefined,
      tags: tags?.length > 0 ? JSON.stringify(tags) : undefined,
      degreesOfChange:
        degreesOfChange?.length > 0
          ? JSON.stringify(degreesOfChange)
          : JSON.stringify(DEGREE_OF_CHANGE),
      changeMetric: CHANGE_CHART_METRICS[period][aggregation],
      visibility,
    },
    config: {
      enabled: true,
    },
  });

  const translateLabel = (label) => {
    if (option === CHART_DISPLAY_OPTIONS.TYPE) {
      return translateLocationType(label);
    }

    return translateLocationTag(label);
  };

  const donutData = useMemo(() => {
    if (!data?.chartData) return [];
    const {chartData} = data;

    const sortedNodes = chartData
      .sort((a, b) => b.value - a.value)
      .map((item, index) => ({
        order: index,
        label: translateLabel(item.label),
        value: item.value,
      }));

    if (sortedNodes.length > MAX_ITEMS) {
      const topItems = sortedNodes.slice(0, MAX_ITEMS - 1);
      const remainingItems = sortedNodes.slice(MAX_ITEMS - 1);
      const remainingSum = remainingItems.reduce(
        (acc, data) => acc + Number(data.value),
        0,
      );

      topItems.push({
        order: MAX_ITEMS - 1,
        label: remaining,
        value: remainingSum,
      });

      return topItems;
    }

    return sortedNodes;
  }, [data]);

  const colorAccessor = ({order}) => {
    const colors = getChartColors(MAX_ITEMS);
    return colors[order];
  };

  const legend = useMemo(() => {
    if (!donutData) return null;

    return donutData
      .sort((a, b) => a.order - b.order)
      .map((d) => ({
        label: `${d.label} (${d.value})`,
        subtext: d.value,
        color: colorAccessor(d),
      }));
  }, [donutData]);

  return {
    data: donutData,
    legend,
    colorAccessor,
    isFetching,
  };
};
