import {DownloadSimple} from '@phosphor-icons/react';

import {useState} from 'react';
import {useRouter} from '@/hooks/useRouter';
import {BaseIconButton} from '@/components/common/buttons';
import {styles} from '@/components/navigation/list/TopNavigation.styles';
import ActionMapDownload from '@/components/map/actions/ActionMapDownload';
import MyLocationsDownloadMenu from '@/components/navigation/menus/MyLocationsDownload';

function ActionDownload() {
  const {isMyLocationsAnalysisRoute, isMapRoute} = useRouter();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMyLocationsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMyLocationsClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isMyLocationsAnalysisRoute && (
        <>
          <BaseIconButton
            icon={DownloadSimple}
            onClick={handleMyLocationsClick}
            sx={anchorEl ? styles.rightIconActive : styles.rightIcon}
          />
          <MyLocationsDownloadMenu
            anchorEl={anchorEl}
            handleClose={handleMyLocationsClose}
          />
        </>
      )}
      {isMapRoute && <ActionMapDownload sx={styles.rightIcon} />}
    </>
  );
}

export default ActionDownload;
