import {useSelector} from 'react-redux';
import {useTheme} from '@mui/material';
import {useMemo} from 'react';
import {cloneDeep} from 'lodash';

import {
  API_CHANGE_DISTRIBUTION_BY_TAG,
  API_CHANGE_DISTRIBUTION_BY_TYPE,
} from '@hooks/api/constants';
import {
  CHANGE_CHART_METRICS,
  DEGREE_OF_CHANGE,
} from '@/components/saved-locations/constants';

import {useApi} from '@/hooks/api/useApi';
import {useTranslation} from '@/hooks/useTranslation';
import {getSavedLocations, getUserSettings} from '@/selectors';
import {useChartTranslation} from '@/hooks/charts/useChartTranslation';
import {CHART_DISPLAY_OPTIONS} from '@/hooks/constants';

export const useLocationsDistribution = ({option}) => {
  const theme = useTheme();
  const {useGetQuery} = useApi();
  const {getI18N} = useTranslation();
  const {
    analysisFilters: {addressTypes, tags, visibility, degreesOfChange},
  } = useSelector(getSavedLocations);
  const {
    myLocationsAnalysis: {
      change: {aggregation, period},
    },
  } = useSelector(getUserSettings);

  const {translateLocationTag, translateLocationType, translateDegreeOfChange} =
    useChartTranslation();

  const {degreeOfChangeOptions} = getI18N('myLocationsAnalysis.filters');
  const changeCategoryKeys = degreeOfChangeOptions.map(({value}) => value);

  const {data, isFetching} = useGetQuery({
    path:
      option === CHART_DISPLAY_OPTIONS.TYPE
        ? API_CHANGE_DISTRIBUTION_BY_TYPE
        : API_CHANGE_DISTRIBUTION_BY_TAG,
    params: {
      addressTypes:
        addressTypes?.length > 0 ? JSON.stringify(addressTypes) : undefined,
      tags: tags?.length > 0 ? JSON.stringify(tags) : undefined,
      degreesOfChange:
        degreesOfChange?.length > 0
          ? JSON.stringify(degreesOfChange)
          : JSON.stringify(DEGREE_OF_CHANGE),
      changeMetric: CHANGE_CHART_METRICS[period][aggregation],
      visibility,
    },
    config: {
      enabled: true,
    },
  });

  const translatedData = useMemo(() => {
    if (!data?.chartData) return null;
    const {links, nodes} = cloneDeep(data.chartData);

    links.forEach((link) => {
      if (option === CHART_DISPLAY_OPTIONS.TAG) {
        link.target = translateLocationTag(link.target);
      } else if (option === CHART_DISPLAY_OPTIONS.TYPE) {
        link.target = translateLocationType(link.target);
      }
      if (changeCategoryKeys.includes(link.source)) {
        link.source = translateDegreeOfChange(link.source);
      }
    });

    nodes.forEach((node) => {
      if (option === CHART_DISPLAY_OPTIONS.TAG) {
        node.id = node.id || 'none';
      } else if (option === CHART_DISPLAY_OPTIONS.TYPE) {
        node.name = translateLocationType(node.id);
      }
      if (changeCategoryKeys.includes(node.id)) {
        node.name = translateDegreeOfChange(node.id);
      }
      node.name = translateLocationTag(node.name);
    });

    return {links, nodes};
  }, [data, option, changeCategoryKeys]);

  const sankeyData = useMemo(() => {
    if (!translatedData) return {links: [], nodes: []};
    const {links, nodes} = cloneDeep(translatedData);

    const colorMapping = {
      large_increase: theme.palette.quintile[5],
      increase: theme.palette.quintile[4],
      no_change: theme.palette.quintile[3],
      decrease: theme.palette.quintile[2],
      large_decrease: theme.palette.quintile[1],
      indeterminate: theme.palette.quintile[0],
    };
    const endColor = theme.palette.chart[8];

    const updatedNodes = nodes.map((node) => ({
      ...node,
      color: colorMapping[node.id] || endColor,
      order: changeCategoryKeys.indexOf(node.id),
    }));

    return {links, nodes: updatedNodes};
  }, [translatedData]);

  return {
    data: sankeyData,
    isFetching,
  };
};
