import {memo} from 'react';
import {Source, Layer} from 'react-map-gl';
import {useSelector} from 'react-redux';
import {useTheme} from '@mui/material';

import {MARTIN_SERVICE_URL} from '@config';
import {
  THREAT_SCORE,
  THREAT_SCORE_FILL_LAYER,
  THREAT_SCORE_LINE_LAYER,
  THREAT_SCORE_SOURCE,
} from '@/components/map/layers/constants';
import {getLayers} from '@/selectors';

function LayerThreatScore() {
  const theme = useTheme();
  const {scores: isScoresLayer} = useSelector(getLayers);
  return (
    <Source
      id={THREAT_SCORE_SOURCE}
      type="vector"
      url={MARTIN_SERVICE_URL + THREAT_SCORE}>
      <Layer
        id={THREAT_SCORE_FILL_LAYER}
        source={THREAT_SCORE_SOURCE}
        source-layer={`public.${THREAT_SCORE}_fn`}
        type="fill"
        minzoom={5}
        layout={{visibility: isScoresLayer ? 'visible' : 'none'}}
        paint={{
          'fill-opacity': 0.6,
          'fill-color': [
            'step',
            ['get', 'tsd'],
            theme.palette.decile[1],
            10,
            theme.palette.decile[2],
            20,
            theme.palette.decile[3],
            30,
            theme.palette.decile[4],
            40,
            theme.palette.decile[5],
            50,
            theme.palette.decile[6],
            60,
            theme.palette.decile[7],
            70,
            theme.palette.decile[8],
            80,
            theme.palette.decile[9],
            90,
            theme.palette.decile[10],
          ],
        }}
      />
      <Layer
        id={THREAT_SCORE_LINE_LAYER}
        source={THREAT_SCORE_SOURCE}
        source-layer={`public.${THREAT_SCORE}_fn`}
        type="line"
        minzoom={5}
        layout={{visibility: isScoresLayer ? 'visible' : 'none'}}
        paint={{
          'line-color': [
            'step',
            ['get', 'tsd'],
            theme.palette.decile[1],
            10,
            theme.palette.decile[2],
            20,
            theme.palette.decile[3],
            30,
            theme.palette.decile[4],
            40,
            theme.palette.decile[5],
            50,
            theme.palette.decile[6],
            60,
            theme.palette.decile[7],
            70,
            theme.palette.decile[8],
            80,
            theme.palette.decile[9],
            90,
            theme.palette.decile[10],
          ],
          'line-width': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            2,
            0.5,
          ],
        }}
      />
    </Source>
  );
}

export default memo(LayerThreatScore);
