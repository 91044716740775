export const getNightDayPercentageAndDelta = (dayVsNightComparison) => {
  const dayPercentage = dayVsNightComparison?.dayPercentage;
  const nightPercentage = dayVsNightComparison?.nightPercentage;

  if (dayPercentage != null && nightPercentage != null) {
    const delta = Math.abs(dayPercentage - nightPercentage);
    return {
      delta,
      nightPercentage: `${Math.round(nightPercentage)}%`,
      dayPercentage: `${Math.round(dayPercentage)}%`,
    };
  }
  return {
    delta: 0,
    nightPercentage: '',
    dayPercentage: '',
  };
};

export const getPropertyAndViolentCrimePercentage = (
  propertyVsViolentComparison,
) => {
  const propertyCrime = propertyVsViolentComparison?.propertyPercentage;
  const violentCrime = propertyVsViolentComparison?.violentPercentage;

  const oneOfThemIsLessThanOne = propertyCrime < 1 || violentCrime < 1;

  if (propertyCrime && violentCrime) {
    const formatPercentage = (crime) =>
      oneOfThemIsLessThanOne ? crime.toFixed(1) : Math.round(crime);

    return {
      propertyCrimePercentage: `${formatPercentage(propertyCrime)}%`,
      violentCrimePercentage: `${formatPercentage(violentCrime)}%`,
    };
  }

  return {
    propertyCrimePercentage: '',
    violentCrimePercentage: '',
  };
};

export const getCrimePercentage = (categoryComparison) => {
  const crimePercentage = categoryComparison?.percentage;
  if (crimePercentage) {
    return `${Math.round(crimePercentage)}%`;
  }
  return '';
};
