import Providers from '@context/Providers';
import Router from '@router/Router';
import Layout from '@/components/layouts/Layout';

function App() {
  return (
    <Providers>
      <Layout>
        <Router />
      </Layout>
    </Providers>
  );
}

export default App;
