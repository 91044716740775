import {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, useTheme} from '@mui/material';
import moment from 'moment';
import {curveStepAfter} from '@visx/curve';
import BaseRadioGroup from '@/components/common/checkboxes/BaseRadioGroup';
import BaseXYChart from '@/components/common/charts/BaseXYChart';
import BaseLine from '@/components/common/charts/XYCharts/BaseLine';
import BaseLegend from '@/components/common/charts/BaseLegend';
import {useTranslation} from '@/hooks/useTranslation';
import {getChartColors} from '@/components/common/charts/ChartTheme';
import {useCategoryTrend} from '@/hooks/charts/useCategoryTrend';
import {useTimeSeries} from '@/hooks/charts/useTimeSeries';
import {useRollingAverage} from '@/hooks/charts/useRollingAverage';
import {useHistoricalAverage} from '@/hooks/charts/useHistoricalAverage';
import {useTrendline} from '@/hooks/charts/useTrendline';
import {styles} from '@/components/analytics/sections/EventTrendSection.styles';
import SectionTabs from '@/components/analytics/sections/SectionTabs';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';
import BaseBar from '@/components/common/charts/XYCharts/BaseBar';

function EventTrendSection({id, granularity, sx, onlyCharts, settings}) {
  const theme = useTheme();
  const [averageType, setAverageType] = useState('trendline');
  const [aggregation, setAggregation] = useState('monthly');
  const {locale, getI18N} = useTranslation();
  const {eventCount} = getI18N('chartQuintile');
  const {average, trendline, rollingAverage, historicalAverage, daily} =
    getI18N('chartSelection');
  const {label10: monthly} = getI18N('threatAnalytics');
  const {trendline: trendlineTooltip, historical: historicalTooltip} =
    getI18N('tooltips');

  const title = getI18N('threatAnalytics.chartTitle4');

  // Average Type Options
  const averageTypeOptions = useMemo(
    () => [
      {
        label: trendline,
        value: 'trendline',
        loading: true,
        tooltip: trendlineTooltip,
      },
      {
        label: average,
        value: 'average',
        loading: true,
      },
      {
        label: rollingAverage,
        value: 'rolling',
        loading: true,
      },
      {
        label: historicalAverage,
        value: 'historical',
        loading: true,
        tooltip: historicalTooltip,
      },
    ],
    [locale],
  );

  // Aggregation Options
  const aggregationOptions = useMemo(
    () => [
      {
        label: daily,
        value: 'daily',
      },
      {
        label: monthly,
        value: 'monthly',
      },
    ],
    [locale],
  );

  // Generate file title for downloads
  const downloadTitle = useMemo(() => {
    if (averageType === 'average')
      return `${title} - ${eventCount} & ${average}`;
    if (averageType === 'rolling') return `${title} - ${rollingAverage}`;
    if (averageType === 'historical') return `${title} - ${historicalAverage}`;
  }, [averageType, locale]);

  // Time Series Hook
  const {
    data: countData,
    averageData,
    error: countError,
    isFetching: countIsLoading,
    downloadImage,
    copyImage,
  } = useTimeSeries({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
  });

  // Category Trend Hook
  const {
    seriesLength,
    data: categoryData,
    error: categoryError,
    isFetching: categoryIsLoading,
    downloadCSV: downloadCategoryCSV,
    copyCSV: copyCategoryCSV,
    legend,
    disabled,
    setDisabled,
  } = useCategoryTrend({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
  });

  // Rolling Average Hook
  const {
    data: rollingAverageData,
    error: rollingAverageError,
    isFetching: rollingAverageIsLoading,
    downloadCSV: downloadRollingAverageCSV,
    copyCSV: copyRollingAverageCSV,
  } = useRollingAverage({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
  });

  // Historical Average Hook
  const {
    data: historicalAverageData,
    error: historicalAverageError,
    isFetching: historicalAverageIsLoading,
    downloadCSV: downloadHistoricalAverageCSV,
    copyCSV: copyHistoricalAverageCSV,
  } = useHistoricalAverage({
    id,
    title: downloadTitle,
    granularity,
  });

  const {
    data: trendlineData,
    error: trendlineError,
    isFetching: trendlineIsLoading,
    downloadCSV: downloadTrendlineCSV,
    copyCSV: copyTrendlineCSV,
  } = useTrendline({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
  });

  const isAnyLoading = useMemo(
    () =>
      categoryIsLoading ||
      trendlineIsLoading ||
      countIsLoading ||
      rollingAverageIsLoading ||
      historicalAverageIsLoading,
    [
      categoryIsLoading,
      trendlineIsLoading,
      countIsLoading,
      rollingAverageIsLoading,
      historicalAverageIsLoading,
    ],
  );

  const isLoading = useMemo(
    () =>
      categoryIsLoading &&
      trendlineIsLoading &&
      countIsLoading &&
      rollingAverageIsLoading &&
      historicalAverageIsLoading,
    [
      categoryIsLoading,
      trendlineIsLoading,
      countIsLoading,
      rollingAverageIsLoading,
      historicalAverageIsLoading,
    ],
  );

  const enabledAverageTypes = useMemo(
    () =>
      averageTypeOptions
        .map((option) => {
          const disabled =
            (option.value === 'trendline' && Boolean(trendlineError)) ||
            (option.value === 'average' && Boolean(countError)) ||
            (option.value === 'rolling' && Boolean(rollingAverageError)) ||
            (option.value === 'historical' && Boolean(historicalAverageError));

          const loading =
            (option.value === 'trendline' && Boolean(trendlineIsLoading)) ||
            (option.value === 'average' && Boolean(countIsLoading)) ||
            (option.value === 'rolling' && Boolean(rollingAverageIsLoading)) ||
            (option.value === 'historical' &&
              Boolean(historicalAverageIsLoading));

          return {
            ...option,
            disabled,
            loading,
          };
        })
        .filter((option) => {
          if (option.value === 'historical' && aggregation === 'daily') {
            return false;
          }
          return true;
        }),
    [
      averageTypeOptions,
      trendlineError,
      countError,
      rollingAverageError,
      historicalAverageError,
      trendlineIsLoading,
      countIsLoading,
      rollingAverageIsLoading,
      historicalAverageIsLoading,
      aggregation,
    ],
  );

  // Set averageType to first enabled averageType if current averageType is disabled
  useMemo(() => {
    const selected = enabledAverageTypes.find(
      (option) => option.value === averageType,
    );

    if (!selected || selected?.disabled) {
      const firstEnabled = enabledAverageTypes.find(
        (option) => !option.disabled,
      );
      setAverageType(firstEnabled?.value);
    }
  }, [enabledAverageTypes, averageType]);

  // Get historical average for each x value
  const historicalData = useMemo(
    () =>
      countData.map((d) => {
        // Get historical average for the month
        const historicalAverage = historicalAverageData.find(
          (h) => moment(d.x).month() === h.monthNumber - 1,
        );
        return {
          x: d.x,
          y: historicalAverage?.y || 0,
        };
      }),
    [countData, historicalAverageData],
  );

  // Get colors for each series
  const colors = useMemo(() => {
    const chartThemeColors = getChartColors(seriesLength);
    const primary = theme.palette.primary.main;
    return [...chartThemeColors, primary, primary, primary, primary];
  }, [seriesLength, theme]);

  const trendlineChart = useMemo(
    () => ({[trendline]: averageType === 'trendline' ? trendlineData : []}),
    [trendline, averageType, trendlineData],
  );

  const averageChart = useMemo(
    () => ({[average]: averageType === 'average' ? averageData : []}),
    [average, averageType, averageData],
  );

  const rollingAverageChart = useMemo(
    () => ({
      [rollingAverage]: averageType === 'rolling' ? rollingAverageData : [],
    }),
    [rollingAverage, averageType, rollingAverageData],
  );

  const historicalChart = useMemo(
    () => ({
      [historicalAverage]: averageType === 'historical' ? historicalData : [],
    }),
    [historicalAverage, averageType, historicalData],
  );

  const handleDownload = () => {
    if (averageType === 'trendline') {
      downloadTrendlineCSV();
    } else if (averageType === 'average') {
      downloadCategoryCSV();
    } else if (averageType === 'rolling') {
      downloadRollingAverageCSV();
    } else if (averageType === 'historical') {
      downloadHistoricalAverageCSV();
    }
  };

  const handleCopy = () => {
    if (averageType === 'trendline') {
      copyTrendlineCSV();
    } else if (averageType === 'average') {
      copyCategoryCSV();
    } else if (averageType === 'rolling') {
      copyRollingAverageCSV();
    } else if (averageType === 'historical') {
      copyHistoricalAverageCSV();
    }
  };

  const handleLegendClick = (value) => {
    if (disabled.includes(value)) {
      setDisabled(disabled.filter((v) => v !== value));
    } else {
      setDisabled([...disabled, value]);
    }
  };

  const allError = useMemo(
    () =>
      categoryError &&
      trendlineError &&
      countError &&
      rollingAverageError &&
      historicalAverageError,
    [
      categoryError,
      trendlineError,
      countError,
      rollingAverageError,
      historicalAverageError,
    ],
  );

  useEffect(() => {
    setAverageType(settings.metric);
    setAggregation(settings.period);
  }, [settings]);

  const xAxisValues = useMemo(() => {
    // Get unique x values for all charts
    const xValues = new Set();
    Object.values(categoryData).forEach((c) =>
      c.forEach((d) => xValues.add(moment(d.x).toISOString())),
    );
    trendlineData.forEach((d) => xValues.add(moment(d.x).toISOString()));
    averageData.forEach((d) => xValues.add(moment(d.x).toISOString()));
    rollingAverageData.forEach((d) => xValues.add(moment(d.x).toISOString()));
    historicalData.forEach((d) => xValues.add(moment(d.x).toISOString()));
    return Array.from(xValues)
      .sort((a, b) => moment(a).diff(moment(b)))
      .map((d) => moment(d).toDate());
  }, [
    categoryData,
    trendlineData,
    averageData,
    rollingAverageData,
    historicalData,
  ]);

  if (onlyCharts)
    return (
      <ReportChartWrapper isLoading={isAnyLoading} error={Boolean(allError)}>
        <BaseXYChart
          height={sx.height}
          width={sx.width}
          isLoading={isLoading}
          seriesLength={seriesLength + 4}
          customColors={colors}
          dateFormat={aggregation === 'monthly' ? 'MM/YYYY' : 'MM/DD/YYYY'}
          xScale={{type: 'band', domain: xAxisValues, paddingInner: 0.3}}>
          <BaseBar data={categoryData} type="stacked" />
          {!trendlineError && <BaseLine data={trendlineChart} />}
          <BaseLine data={averageChart} />
          <BaseLine data={rollingAverageChart} />
          <BaseLine data={historicalChart} curve={curveStepAfter} />
        </BaseXYChart>
        <BaseLegend
          sx={styles.legend}
          isLoading={categoryIsLoading}
          labels={legend}
          disabled={disabled}
          onClick={handleLegendClick}
        />
      </ReportChartWrapper>
    );

  if (allError) return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        title={title}
        tooltip="eventTrend"
        granularity={granularity}
        downloadCSV={handleDownload}
        copyCSV={handleCopy}
        downloadImage={() => downloadImage()}
        copyImage={() => copyImage()}
        chart={
          <>
            <BaseXYChart
              isLoading={isLoading}
              seriesLength={seriesLength + 4}
              customColors={colors}
              dateFormat={aggregation === 'monthly' ? 'MMMM' : 'MM/DD/YYYY'}
              xScale={{type: 'band', domain: xAxisValues, paddingInner: 0.3}}>
              <BaseBar data={categoryData} type="stacked" />
              {!trendlineError && <BaseLine data={trendlineChart} />}
              <BaseLine data={averageChart} />
              <BaseLine data={rollingAverageChart} />
              <BaseLine data={historicalChart} curve={curveStepAfter} />
            </BaseXYChart>
            <BaseLegend
              sx={styles.legend}
              isLoading={categoryIsLoading}
              labels={legend}
              disabled={disabled}
              onClick={handleLegendClick}
            />
          </>
        }
        controls={
          <Box display="flex" flexDirection="column" gap={2}>
            <Box sx={{width: 170}}>
              <SectionTabs
                options={aggregationOptions}
                value={aggregation}
                onChange={(_, value) => setAggregation(value)}
              />
            </Box>
            <BaseRadioGroup
              dense
              onChange={setAverageType}
              selected={averageType}
              options={enabledAverageTypes}
            />
          </Box>
        }
        settings={{id, metric: averageType, period: aggregation}}
      />
    </Box>
  );
}

EventTrendSection.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onlyCharts: PropTypes.bool,
  settings: PropTypes.object,
};

export default EventTrendSection;
