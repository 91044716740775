import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {getSelectedThreatTypes} from '@/selectors';

import QuintilesGroup from '@/components/analytics/quintile/QuintilesGroup';
import {useTranslation} from '@/hooks/useTranslation';
import {styles} from '@/components/analytics/quintile/Quintile.styles';

function Quintiles({
  data,
  isFetching,
  defaultExpanded,
  compact,
  height,
  width,
}) {
  const threatTypes = useSelector(getSelectedThreatTypes);

  const {locale, getI18N, getThreatTypesTranslation} = useTranslation();

  const {threatCategory, eventCount, severity} = getI18N('chartQuintile');

  const quintiles = useMemo(
    () =>
      data?.chartData?.map(({categoryId, ...quintile}) => ({
        ...threatTypes.find(({id}) => categoryId === id),
        ...quintile,
        name: getThreatTypesTranslation(categoryId),
      })),
    [threatTypes, data, locale],
  );

  return (
    <Box id="quintile-container" style={{height, width}}>
      <Box
        sx={compact ? styles.compactQuintilesHeader : styles.quintilesHeader}>
        <Typography
          fontWeight="bold"
          variant="caption"
          style={styles.threatColumn}>
          {threatCategory}
        </Typography>
        <Typography
          fontWeight="bold"
          variant="caption"
          style={styles.countColumn}>
          {eventCount}
        </Typography>
        <Typography
          fontWeight="bold"
          variant="caption"
          style={styles.severityColumn}>
          {severity}
        </Typography>
      </Box>
      <QuintilesGroup
        options={quintiles}
        isLoading={isFetching}
        defaultExpanded={defaultExpanded}
        compact={compact}
      />
    </Box>
  );
}

Quintiles.defaultProps = {
  data: {chartData: []},
  isFetching: false,
  defaultExpanded: false,
  compact: false,
};

Quintiles.propTypes = {
  data: PropTypes.object,
  isFetching: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  compact: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Quintiles;
