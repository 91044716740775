// reducers
import {
  modalReducer,
  drawerReducer,
  filtersReducer,
  mapReducer,
  userReducer,
  snackbarReducer,
  organizationReducer,
  sourceCategoriesReducer,
  districtsReducer,
  threatCategoriesReducer,
  savedLocationsReducer,
  themeReducer,
} from '@/store/modules';

const rootReducer = {
  modal: modalReducer,
  drawer: drawerReducer,
  filters: filtersReducer,
  map: mapReducer,
  user: userReducer,
  snackbar: snackbarReducer,
  organization: organizationReducer,
  sourceCategories: sourceCategoriesReducer,
  districts: districtsReducer,
  threatCategories: threatCategoriesReducer,
  savedLocations: savedLocationsReducer,
  theme: themeReducer,
};

export default rootReducer;
