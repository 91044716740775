import {useMemo} from 'react';
import {Source, Layer} from 'react-map-gl';
import {useSelector} from 'react-redux';
import {useTheme} from '@mui/material';

import {MARTIN_SERVICE_URL} from '@config';
import {getLayers, getSavedLocations, getUserSettings} from '@/selectors';
import {CHANGE_MAP_METRICS} from '@/components/saved-locations/constants';
import {CHANGE_LAYER, CHANGE_SOURCE} from '@/components/map/layers/constants';
import {useDegreeOfChange} from '@/hooks/api/useDegreeOfChange';

function LayerChange() {
  const theme = useTheme();
  const {data, isFetching} = useDegreeOfChange();

  const {
    analysisFilters: {degreesOfChange},
  } = useSelector(getSavedLocations);
  const {
    myLocationsAnalysis: {
      change: {aggregation, period},
    },
  } = useSelector(getUserSettings);
  const {change: isChangeLayer} = useSelector(getLayers);

  const metric = useMemo(
    () => CHANGE_MAP_METRICS[period][aggregation],
    [aggregation, period],
  );

  const filter = useMemo(() => {
    if (isFetching || !data || degreesOfChange.length < 1) {
      return [['all']];
    }
    return data
      .map(({bucket: {max, min}, range}) => {
        let filter = ['all'];
        if (range === 'large_increase') {
          filter = [
            'all',
            ['has', metric],
            ['<=', metric, max],
            ['>=', metric, min],
          ];
        } else if (range === 'increase') {
          filter = [
            'all',
            ['has', metric],
            ['<', metric, max],
            ['>=', metric, min],
          ];
        } else if (range === 'no_change') {
          filter = [
            'all',
            ['has', metric],
            ['<', metric, max],
            ['>', metric, min],
          ];
        } else if (range === 'decrease') {
          filter = [
            'all',
            ['has', metric],
            ['<=', metric, max],
            ['>', metric, min],
          ];
        } else if (range === 'large_decrease') {
          filter = [
            'all',
            ['has', metric],
            ['<=', metric, max],
            ['>=', metric, min],
          ];
        }
        return {range, filter};
      })
      .filter(({range}) => degreesOfChange.includes(range))
      .map(({filter}) => filter);
  }, [data, degreesOfChange, metric]);

  const buckets = useMemo(() => {
    if (isFetching || !data || data.length < 1) {
      return [-10, -3.75, 3.75, 10];
    }
    return data.map(({bucket}) => bucket.max);
  }, [data, isFetching]);

  return (
    <Source
      id="change-points-source"
      type="vector"
      url={MARTIN_SERVICE_URL + CHANGE_LAYER}>
      <Layer
        id="change-points-layer"
        source={CHANGE_SOURCE}
        source-layer={`public.${CHANGE_LAYER}_fn`}
        type="circle"
        layout={{
          visibility: isChangeLayer && !isFetching ? 'visible' : 'none',
        }}
        filter={['any', ...filter]}
        paint={{
          'circle-opacity': 0.75,
          'circle-radius': [
            'step',
            ['get', metric],
            14,
            buckets[0],
            7,
            buckets[1],
            4,
            0,
            4,
            buckets[2],
            7,
            buckets[3],
            14,
          ],
          'circle-color': [
            'step',
            ['get', metric],
            theme.palette.quintile[1],
            buckets[0],
            theme.palette.quintile[2],
            buckets[1],
            theme.palette.quintile[3],
            0,
            theme.palette.quintile[3],
            buckets[2],
            theme.palette.quintile[4],
            buckets[3],
            theme.palette.quintile[5],
          ],
        }}
      />
    </Source>
  );
}

export default LayerChange;
