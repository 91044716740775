// Packages
import React, {memo} from 'react';
import PropTypes from 'prop-types';

// MUI
import Avatar from '@mui/material/Avatar';
import {useTheme} from '@mui/material';

// Icons
import {LOCATION_TYPE_ICONS} from '@saved-locations/LocationTypeIcons';
import {Star} from '@phosphor-icons/react';

function SavedLocationAvatar({icon}) {
  const theme = useTheme();

  const Icon = LOCATION_TYPE_ICONS[icon] || Star;

  return (
    <Avatar
      sx={{
        height: '100%',
        bgcolor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: 'auto',
      }}>
      <Icon size={20} color={theme.palette.primary.main} />
    </Avatar>
  );
}

// Map location types to icons

SavedLocationAvatar.propTypes = {
  icon: PropTypes.string,
};

SavedLocationAvatar.defaultProps = {
  icon: 'other',
};

export default memo(SavedLocationAvatar);
