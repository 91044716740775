import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material';
import {
  AnimatedAxis,
  AnimatedGrid,
  XYChart,
  Tooltip,
  Grid,
} from '@visx/xychart';
import {defaultStyles} from '@visx/tooltip';
import {ParentSize} from '@visx/responsive';
import useChartTheme from '@common/charts/ChartTheme';
import moment from 'moment';
import BaseSkeleton from '@common/BaseSkeleton';

function BaseXYChart({
  width,
  height,
  hideXAxis,
  yAxisOptions,
  xAxisOptions,
  hideYAxis,
  hideGrid,
  showZeroLine,
  children,
  seriesLength,
  angledTicks,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  showBackground,
  isLoading,
  xScale,
  yScale,
  customColors,
  dateFormat,
  tooltipOptions,
  orientation,
  hideYAxisLine,
}) {
  const theme = useTheme();
  const chartTheme = useChartTheme(seriesLength, customColors);

  function truncateLabel(label, maxLength) {
    if (label.length > maxLength) {
      return `${label.substring(0, maxLength - 3)}...`;
    }
    return label;
  }

  const formatLabelTitle = (value, maxLength = undefined) => {
    if (value instanceof Date) {
      return moment(value).format(dateFormat);
    }
    if (maxLength) {
      return truncateLabel(value, maxLength);
    }
    return value;
  };

  const numXTicks = (width) => {
    let max;
    if (angledTicks) {
      max = Math.max(3, Math.floor(width / 30));
    } else {
      max = Math.max(3, Math.floor(width / 100));
    }
    return Math.min(16, max);
  };

  return (
    <ParentSize style={{height: 'inherit'}}>
      {(parent) => {
        const w = width ?? parent.width;
        const h = height ?? parent.height;

        if (!w || !h) return null;

        if (isLoading)
          return (
            <BaseSkeleton
              width={`${w.toString()}px`}
              height={`${h.toString()}px`}
            />
          );

        return (
          <XYChart
            theme={chartTheme}
            width={w}
            height={h}
            margin={{
              top: marginTop,
              right: marginRight,
              bottom: marginBottom,
              left: marginLeft,
            }}
            xScale={xScale ?? {type: 'band', paddingInner: 0.3}}
            yScale={yScale ?? {type: 'linear'}}>
            {showBackground && (
              <rect
                width={w}
                height={h}
                fill={theme.palette.background.dark}
                rx={14}
              />
            )}
            {hideXAxis === false && (
              <AnimatedAxis
                orientation="bottom"
                tickLabelProps={
                  angledTicks && {
                    angle: -45,
                    textAnchor: 'end',
                    scaleToFit: true,
                  }
                }
                numTicks={numXTicks(w)}
                tickFormat={(value) => formatLabelTitle(value, 25)}
                {...xAxisOptions}
              />
            )}
            {hideYAxis === false && (
              <AnimatedAxis
                orientation="left"
                hideTicks
                hideAxisLine={hideYAxisLine}
                numTicks={5}
                tickFormat={(value) => formatLabelTitle(value)}
                {...yAxisOptions}
              />
            )}
            {hideGrid === false && (
              <AnimatedGrid columns={false} numTicks={5} />
            )}
            {showZeroLine && (
              <Grid
                columns={false}
                tickValues={[0]}
                lineStyle={{
                  stroke: theme.palette.text.primary,
                  strokeWidth: 2,
                }}
                stroke={theme.palette.text.primary}
                strokeWidth={2}
              />
            )}
            {children}
            <Tooltip
              snapTooltipToDatumY={orientation === 'horizontal'}
              snapTooltipToDatumX={orientation === 'vertical'}
              style={{
                ...defaultStyles,
                backgroundColor: theme.palette.background.paper,
                padding: 0,
                margin: 0,
                zIndex: 1500,
              }}
              renderTooltip={({tooltipData, colorScale}) => {
                const {colorAccessor, formatValue, renderTooltip} =
                  tooltipOptions;
                if (renderTooltip)
                  return renderTooltip({tooltipData, colorScale});

                const data = tooltipData.datumByKey || [];
                return (
                  <Box
                    sx={{
                      paddingX: '16px',
                      paddingY: '8px',
                      fontSize: '12px',
                      borderRadius: '4px',
                    }}>
                    <Typography
                      variant="caption"
                      color={theme.palette.text.primary}
                      fontWeight="bold">
                      {formatLabelTitle(tooltipData.nearestDatum.datum.x)}
                    </Typography>
                    {Object.keys(data).map((key) => {
                      if (data[key].datum.disabled) return null;
                      return (
                        <Box
                          key={key}
                          sx={{
                            marginTop: '4px',
                            alignItems: 'center',
                            fontWeight: 'normal',
                            color: theme.palette.text.secondary,
                          }}>
                          <Box
                            sx={{
                              display: 'inline-flex',
                              width: '11px',
                              height: '11px',
                              marginRight: '8px',
                              backgroundColor: colorAccessor
                                ? colorAccessor(
                                    key,
                                    data[key].datum,
                                    colorScale,
                                  )
                                : colorScale(key),
                              borderRadius: '4px',
                            }}
                          />
                          <strong>{key}: </strong>
                          {formatValue
                            ? formatValue(key, data[key].datum)
                            : data[key].datum.y}
                        </Box>
                      );
                    })}
                  </Box>
                );
              }}
            />
          </XYChart>
        );
      }}
    </ParentSize>
  );
}

BaseXYChart.defaultProps = {
  hideYAxis: false,
  hideXAxis: false,
  yAxisOptions: {},
  xAxisOptions: {},
  hideGrid: false,
  showZeroLine: false,
  data: {},
  marginBottom: 20,
  marginLeft: 35,
  marginRight: 20,
  marginTop: 20,
  dateFormat: 'MM/DD/YYYY',
  tooltipOptions: {},
  orientation: 'vertical',
  hideYAxisLine: true,
  height: 200,
};

BaseXYChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number.isRequired,
  seriesLength: PropTypes.number.isRequired,
  children: PropTypes.node,
  hideYAxis: PropTypes.bool,
  hideXAxis: PropTypes.bool,
  yAxisOptions: PropTypes.object,
  xAxisOptions: PropTypes.object,
  hideGrid: PropTypes.bool,
  showZeroLine: PropTypes.bool,
  angledTicks: PropTypes.bool,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginTop: PropTypes.number,
  showBackground: PropTypes.bool,
  isLoading: PropTypes.bool,
  customColors: PropTypes.arrayOf(PropTypes.string),
  dateFormat: PropTypes.string,
  hideYAxisLine: PropTypes.bool,
  xScale: PropTypes.shape({
    type: PropTypes.string,
    paddingInner: PropTypes.number,
    domain: PropTypes.arrayOf(PropTypes.string),
  }),
  yScale: PropTypes.shape({
    type: PropTypes.string,
    paddingInner: PropTypes.number,
    domain: PropTypes.arrayOf(PropTypes.string),
  }),
  tooltipOptions: PropTypes.shape({
    colorAccessor: PropTypes.func,
    formatValue: PropTypes.func,
    renderTooltip: PropTypes.func,
  }),
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

export default BaseXYChart;
