import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {DownloadSimple} from '@phosphor-icons/react';
import {useTranslation} from '@/hooks/useTranslation';
import {useChartDownloads} from '@/hooks/charts/useChartDownloads';
import {useLocationsDistribution} from '@/hooks/charts/useLocationsDistribution';
import SankeyChart from '@/components/common/charts/SankeyChart';
import BaseCard from '@/components/common/cards/BaseCard';
import ChartOptionsDropdown from '@/components/analytics/ChartOptionsDropdown';
import {chartIds} from '@/components/saved-locations/constants';
import {CHART_DISPLAY_OPTIONS} from '@/hooks/constants';

function PercentageOfChangeByLocation({option}) {
  const {getI18N, getTooltipTranslation} = useTranslation();
  const {copyImageLabel, downloadImageLabel} = getI18N('chartOptions');
  const {percentageChangeByLocationType, percentageChangeByLocationTag} =
    getI18N('savedLocations');
  const chartId =
    option === 'tag' ? chartIds.changeByTag : chartIds.changeByType;

  const title = useMemo(
    () =>
      option === CHART_DISPLAY_OPTIONS.TYPE
        ? percentageChangeByLocationType
        : percentageChangeByLocationTag,
    [option],
  );

  const {copyImage, downloadImage} = useChartDownloads({id: chartId, title});

  const {data, isFetching} = useLocationsDistribution({option});

  return (
    <BaseCard
      id={chartId}
      title={title}
      tooltip={getTooltipTranslation(
        `percentageChangeByLocation${option === CHART_DISPLAY_OPTIONS.TYPE ? 'Type' : 'Tag'}`,
      )}
      headerActions={[
        <ChartOptionsDropdown
          icon={DownloadSimple}
          options={[
            {
              label: copyImageLabel,
              action: copyImage,
            },
            {
              label: downloadImageLabel,
              action: downloadImage,
            },
          ]}
        />,
      ]}>
      <SankeyChart data={data} isLoading={isFetching} />
    </BaseCard>
  );
}

PercentageOfChangeByLocation.propTypes = {
  option: PropTypes.oneOf(Object.values(CHART_DISPLAY_OPTIONS)).isRequired,
};

export default PercentageOfChangeByLocation;
