export const USER_TABS = [
  {
    label: 'User Profile',
    value: 'profile',
  },
  {
    label: 'Change Password',
    value: 'password',
    hidden: true,
    disabled: true,
  },
  {
    feature: 'api-key-management',
    label: 'Manage API Token',
    value: 'api',
  },
  {
    label: 'Manage Users',
    value: 'admin',
    hidden: true,
    disabled: true,
  },
];
