import {useState} from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import {CaretDown} from '@phosphor-icons/react';
import {Grid, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {getLocation} from '@/selectors';
import {BaseIconButton, ButtonChip} from '@/components/common/buttons';
import {styles} from '@/components/analytics/AnalyticsSelector.styles';
import {useMixpanel} from '@/hooks/useMixpanel';

function AnalyticsSelector({value, options, label, onChange}) {
  const {track} = useMixpanel();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const {name: locationName} = useSelector(getLocation);

  const handleClick = (panel) => {
    track('Analytics Panel Changed', {
      panel: panel.value,
      location: locationName,
    });
    onChange(panel.value);
  };

  return (
    <Grid container direction="column" sx={styles.root}>
      <Grid item sx={styles.header}>
        {open ? (
          <Grid container direction="row" wrap="wrap" sx={styles.chipContainer}>
            {options.map((panel, index) => (
              <Grid item key={index}>
                <ButtonChip
                  label={panel.label}
                  selected={value === panel.value}
                  disabled={panel.disabled}
                  color="info"
                  selectedColor="info"
                  onClick={() => handleClick(panel)}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography
            variant="body2"
            color={theme.palette.text.secondary}
            sx={styles.selectionText}>
            {label}
          </Typography>
        )}
        <BaseIconButton
          icon={CaretDown}
          size={26}
          sx={styles.openButton}
          onClick={() => setOpen(!open)}
        />
      </Grid>
    </Grid>
  );
}

AnalyticsSelector.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AnalyticsSelector;
