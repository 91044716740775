export const styles = {
  quintileScore: {
    display: 'inline-block',
    alignSelf: 'flex-start',
    marginBottom: 2,
    paddingX: '8px',
    paddingY: '4px',
    borderRadius: '2px',
  },
  scoreLabel: {
    fontWeight: 'bold',
    lineHeight: 1,
  },
  quintileSkeleton: {
    marginBottom: 2,
  },
};
