import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import Tab from '@mui/material/Tab';

import {styles} from '@common/tabs/BaseTab.styles';

function BaseTab({textAlign, label, value, sx, ...props}) {
  return (
    <Tab
      wrapped
      sx={(theme) => ({
        ...styles.root(theme),
        ...(typeof sx === 'function' ? sx(theme) : sx),
      })}
      style={{justifyContent: textAlign, pointerEvents: 'auto'}}
      {...props}
      label={<Typography variant="body2">{label}</Typography>}
      value={value}
      data-testid={`tab-option-${value}`}
    />
  );
}

BaseTab.propTypes = {
  value: PropTypes.string,
  textAlign: PropTypes.string,
  label: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default BaseTab;
