import React from 'react';
import PropTypes from 'prop-types';
import {curveMonotoneX} from '@visx/curve';
import {AnimatedLineSeries} from '@visx/xychart';

function BaseLine({data, curve}) {
  const accessors = {
    xAccessor: (d) => d?.x ?? 0,
    yAccessor: (d) => d?.y ?? 0,
  };
  return (
    <>
      {Object.keys(data).map((key) => (
        <AnimatedLineSeries
          key={key}
          dataKey={key}
          data={data[key]}
          curve={curve}
          {...accessors}
        />
      ))}
    </>
  );
}

BaseLine.defaultProps = {
  curve: curveMonotoneX,
};

BaseLine.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.instanceOf(Date),
        ]).isRequired,
        y: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.instanceOf(Date),
        ]).isRequired,
      }),
    ).isRequired,
  ).isRequired,
  curve: PropTypes.func,
};

export default BaseLine;
