export const styles = {
  menu: (theme) => ({
    marginTop: 1,
    '& .MuiPaper-root': {
      borderRadius: 1.5,
      backgroundColor: theme.palette.background.light,
    },
    '& .MuiMenu-list': {
      backgroundColor: theme.palette.background.light,
    },
  }),
};
