import PropTypes from 'prop-types';
import BaseTabGroup from '@/components/common/tabs/BaseTabGroup';
import {styles} from '@/components/analytics/sections/SectionTabs.styles';

function SectionTabs({options, value, onChange, sx}) {
  const customOptions = options.map((option) => ({...option, sx: styles.tab}));
  return (
    <BaseTabGroup
      sx={(theme) => ({
        ...(typeof sx === 'function' ? sx(theme) : sx),
        ...styles.tabGroup(theme),
      })}
      options={customOptions}
      value={value}
      onChange={onChange}
    />
  );
}

SectionTabs.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SectionTabs;
