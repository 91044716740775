import {createSelector} from 'reselect';
import isEmpty from 'lodash/isEmpty';
import {base_languages as I18N} from '@languages';
import {getObjectValues} from '@utils/utils';
import {baseTheme} from '@styles/theme/base';
import {LAYER_TYPE, SPARSE_DATES} from '@/components/map/constants';
import {ZOOM} from '@/hooks/constants';
import {CITY_MARKERS_LAYER} from '@/components/map/layers/constants';
import {getRadiusByQuantity, getWeightByQuantity} from '@/styles/heatmap';
import {areCoordinatesEqual} from '@/utils/mapUtils';

// final selectors
export const getModal = (state) => state.modal;
export const getSnackbar = (state) => state.snackbar;
export const getOrganization = (state) => state.organization;
export const getDrawer = (state) => state.drawer;
export const getSourceCategories = (state) => state.sourceCategories;
export const getDistricts = (state) => state.districts;
export const getThreatCategories = (state) => state.threatCategories;
export const getSavedLocations = (state) => state.savedLocations;
export const getTheme = (state) => state.theme;
export const getMap = (state) => state.map;

// user selectors
export const getUserSettings = (state) => state.user.settings;
export const getUserProfile = (state) => state.user.profile;
export const getNewUserLocation = (state) => state.user.newLocation;
export const getUserLocationPopupStatus = (state) => state.user.isPopupOpen;
export const getUserSelectedLocation = (state) => state.user.selectedLocation;
export const getClickedLocation = (state) => state.user.clickedLocation;
export const getThreatAnalyticsArea = (state) => state.user.threatAnalyticsArea;
export const getUserAddingLocationStatus = (state) =>
  state.user.isAddingLocation;
export const getUserInspectingLocationStatus = (state) =>
  state.user.isInspectingLocation;
export const getUserSavingLocationStatus = (state) =>
  state.user.isSavingLocation;

// map selectors
export const getMapTooltip = (state) => state.map.mapTooltip;
export const getViewType = (state) => state.map.viewType;
export const getUnmappedMode = (state) => state.map.unmappedMode;
export const getLoadingMap = (state) => state.map.loadingMap;
export const getIsFlying = (state) => state.map.isFlying;
export const getCrimesNumber = (state) => state.map.crimeEventsCount;
export const getTiles = (state) => state.map.tiles;
export const getSelectionMarker = (state) => state.map.marker;
export const getSearchMarker = (state) => state.map.searchMarker;
export const getDebugMode = (state) => state.map.debugMode;
export const getLocationAddress = (state) => state.map.locationAddress;
export const getRoutedToMap = (state) => state.map.routedToMap;
export const getMapMountedStatus = (state) => state.map.isMapMounted;
export const getLocationSummaryLoading = (state) =>
  state.map.loadingLocationSummary;
export const getReportDownloadModalStatus = (state) =>
  state.modal.isReportModelOpen;
export const getViewport = (state) => state.map.viewport;

// filters selectors
export const getGranularity = (state) => state.filters.granularity;
export const getLocationLoading = (state) => state.filters.locationLoading;
export const getActiveCity = (state) => state.filters.activeCity;
export const getLayers = (state) => state.filters.layers;
export const getSources = (state) => state.filters.sources;
export const getCategories = (state) => state.filters.categories;
export const getTimeSlices = (state) => state.filters.timeslices;
export const getMonths = (state) => state.filters.months;
export const getPrevMonths = (state) => state.filters.prevFilters.months;
export const getQuarters = (state) => state.filters.quarters;
export const getPrevQuarters = (state) => state.filters.prevFilters.quarters;
export const getFromDate = (state) => state.filters.fromDate;
export const getPrevFromDate = (state) => state.filters.prevFilters.fromDate;
export const getToDate = (state) => state.filters.toDate;
export const getPrevToDate = (state) => state.filters.prevFilters.toDate;
export const getRange = (state) => state.filters.range;
export const getSparseDatesType = (state) => state.filters.sparseDateType;
export const getSparseTimes = (state) => state.filters.sparseTimes;
export const getLocationSettings = (state) => state.filters.locationSettings;
export const getLocationFilters = (state) => state.filters.locationFilters;
export const getFiltersLoading = (state) => state.filters.filtersLoading;
export const getTimezone = (state) => state.filters.timezone;
export const getInitialLoading = (state) => state.filters.loading;
export const getGoogleValue = (state) => state.filters.googleValue;
export const getInputValue = (state) => state.filters.inputValue;
export const getSuggestions = (state) => state.filters.suggestions;
export const getSearchValue = (state) => state.filters.searchValue;
export const getSearchResult = (state) => state.filters.searchResult;
export const getPrevSources = (state) => state.filters.prevFilters.sources;
export const getLoadingTile = (state) => state.filters.loadingTile;
export const getMaxDate = (state) => state.filters.maxDate;
export const getMinDate = (state) => state.filters.minDate;
export const getPrevCategories = (state) =>
  state.filters.prevFilters.categories;
export const getPrevTimeSlices = (state) =>
  state.filters.prevFilters.timeslices;
export const getTilesTimestamp = (state) =>
  state.filters.loadingTilesetTimestamp;
export const getLoadingNeighborhoods = (state) =>
  state.filters.loadingNeighborhoods;
export const getViewportBoundingBox = (state) =>
  state.filters.viewportBoundingBox;
export const getNeighborhoodsTimestamp = (state) =>
  state.filters.loadingNeighborhoodsTimestamp;

export const getIsMapReady = createSelector(
  [getMapMountedStatus, getLoadingMap],
  (mounted, loading) => mounted && !loading,
);

export const getSelectedDistricts = createSelector(
  [getDistricts],
  ({selectedDistricts, locationDistricts}) =>
    selectedDistricts.map((selected) => ({
      ...selected,
      ...locationDistricts.find((district) => district.id === selected.id),
    })),
);

export const getSelectedDistrictIds = createSelector(
  [getSelectedDistricts],
  (selectedDistricts) => [...(selectedDistricts?.map(({id}) => id) ?? [])],
);

export const getNewUserLocationLat = createSelector(
  [getNewUserLocation],
  ({lat}) => lat,
);

export const getNewUserLocationLon = createSelector(
  [getNewUserLocation],
  ({lon}) => lon,
);

export const getSelectionMarkerLat = createSelector(
  [getSelectionMarker],
  ({lat}) => lat,
);

export const getSelectionMarkerLon = createSelector(
  [getSelectionMarker],
  ({lon}) => lon,
);

export const getLocation = createSelector(
  [getLocationSettings],
  (location) => ({...location}),
);

export const getSummary = createSelector([getLocationFilters], (summary) => ({
  ...summary,
}));

export const getClickedLocationId = createSelector(
  [getClickedLocation],
  ({id}) => id,
);

export const getClickedLocationLon = createSelector(
  [getClickedLocation],
  ({longitude}) => longitude,
);

export const getClickedLocationLat = createSelector(
  [getClickedLocation],
  ({latitude}) => latitude,
);

export const getClickedLocationName = createSelector(
  [getClickedLocation],
  ({name}) => name,
);

export const getClickedHoodId = createSelector(
  [getClickedLocation],
  ({hoodId}) => hoodId,
);

export const getClickedLocationAddress = createSelector(
  [getClickedLocation],
  ({address}) => address,
);

export const getClickedLocationHood = createSelector(
  [getClickedLocation, getDistricts],
  ({hoodId}, {locationDistricts}) =>
    locationDistricts?.find(({id}) => id === hoodId),
);

export const getClickedLocationCityId = createSelector(
  [getClickedLocation],
  ({locationId}) => locationId,
);

export const getThreatAnalyticsAreaDisplay = createSelector(
  [getThreatAnalyticsArea],
  ({display}) => display,
);

export const getThreatAnalyticsAreaLat = createSelector(
  [getThreatAnalyticsArea],
  ({latitude}) => latitude,
);

export const getThreatAnalyticsAreaLon = createSelector(
  [getThreatAnalyticsArea],
  ({longitude}) => longitude,
);

export const getThreatAnalyticsAreaRadius = createSelector(
  [getThreatAnalyticsArea],
  ({radius}) => radius,
);

export const getThreatAnalyticsAreaShape = createSelector(
  [getThreatAnalyticsArea],
  ({shape}) => shape,
);

export const getUserId = createSelector([getUserProfile], ({id}) => id);

export const getUser = createSelector(
  [getUserProfile, getUserSettings],
  (profile, settings) => ({...profile, ...settings}),
);

export const getUserLocale = createSelector(
  [getUserSettings],
  ({language}) => language,
);

export const getSelectedSources = createSelector([getSources], (sources) =>
  Object.keys(sources)
    .filter((source) => sources[source])
    .map(Number),
);

export const getPrevSelectedSources = createSelector(
  [getPrevSources],
  (sources) =>
    Object.keys(sources)
      .filter((source) => sources[source])
      .map(Number),
);

export const getSelectedCategories = createSelector(
  [getCategories],
  (categories) =>
    Object.keys(categories)
      .filter((category) => categories[category])
      .map(Number),
);

export const getSelectedThreatTypes = createSelector(
  [getThreatCategories, getSelectedCategories],
  (threatTypes, categories) => {
    const children = threatTypes.filter(({id}) => categories.includes(id));
    const parents = threatTypes.filter(({id}) =>
      children.map(({parentId}) => parentId).includes(id),
    );
    return [...parents, ...children];
  },
);

export const getPrevSelectedCategories = createSelector(
  [getPrevCategories],
  (categories) => getObjectValues(categories),
);

export const getSelectedTimeslices = createSelector(
  [getTimeSlices],
  (timeslices) =>
    Object.keys(timeslices)
      .filter((timeslice) => timeslices[timeslice])
      .map(Number),
);

export const getPrevSelectedTimeSlices = createSelector(
  [getPrevTimeSlices],
  (times) => getObjectValues(times),
);

export const getSelectedMonths = createSelector([getMonths], (months) =>
  Object.keys(months)
    .filter((month) => months[month])
    .map(Number),
);

export const getPrevSelectedMonths = createSelector([getPrevMonths], (months) =>
  getObjectValues(months),
);

export const getViewTypeWorld = createSelector(
  [getViewType],
  (viewType) => viewType === LAYER_TYPE.WORLD,
);

export const getViewTypeCountry = createSelector(
  [getViewType],
  (viewType) => viewType === LAYER_TYPE.COUNTRY,
);

export const getViewTypeCity = createSelector(
  [getViewType],
  (viewType) => viewType === LAYER_TYPE.CITY,
);

export const getIsAnnualData = createSelector(
  [getSparseDatesType],
  (type) => type === SPARSE_DATES.ONLY_YEARS,
);

export const getIsCompleteData = createSelector(
  [getSparseDatesType],
  (type) => type === SPARSE_DATES.COMPLETE_DATE,
);

export const getIsOnlyData = createSelector(
  [getSparseDatesType],
  (type) => type === SPARSE_DATES.DATE_ONLY,
);

export const getIsMonthlyData = createSelector(
  [getSparseDatesType],
  (type) => type === SPARSE_DATES.WITH_MONTHS,
);
export const getIsQuarterlyData = createSelector(
  [getSparseDatesType],
  (type) => type === SPARSE_DATES.QUARTERLY,
);

export const getDarkThemeStatus = createSelector(
  [getUser],
  ({mapStyle}) => mapStyle === 'dark',
);

export const getDefaultThemeStatus = createSelector(
  [getUser],
  ({mapStyle}) => mapStyle === 'map',
);

export const getShowFilters = createSelector(
  [getViewType, getIsFlying],
  (viewType, isFLying) =>
    (viewType === LAYER_TYPE.CITY || viewType === LAYER_TYPE.COUNTRY) &&
    !isFLying,
);

export const getThreatPointsFilter = createSelector(
  [
    getSelectedSources,
    getSelectedCategories,
    getSelectedMonths,
    getSelectedTimeslices,
    getSparseTimes,
  ],
  (sources, categories, months, timeSlices, sparseTimes) => {
    const filters = ['all'];

    if (sources.length > 0) {
      filters.push(['match', ['get', 's'], sources, true, false]);
    }

    if (categories.length > 0) {
      filters.push(['match', ['get', 'c'], categories, true, false]);
    }

    if (months.length > 0) {
      filters.push(['match', ['get', 'm'], months, true, false]);
    }

    if (timeSlices.length > 0 && !sparseTimes) {
      filters.push(['match', ['get', 'h'], timeSlices, true, false]);
    }

    return filters;
  },
);

export const getThreatPointsVisibility = createSelector(
  [
    getSelectedSources,
    getSelectedCategories,
    getSelectedTimeslices,
    getSelectedMonths,
    getLayers,
    getViewTypeCountry,
    getViewTypeCity,
    getIsFlying,
  ],
  (
    sources,
    categories,
    timeslices,
    months,
    layers,
    isCountryView,
    isCityView,
    isFlying,
  ) => {
    const allowedViewOptions = layers.events || layers.heatmap;
    const allowedViewTypes = isCountryView || isCityView;
    const selectedFilters =
      sources.length > 0 &&
      categories.length > 0 &&
      timeslices.length > 0 &&
      months.length > 0;

    const isLayerVisible =
      selectedFilters && allowedViewOptions && allowedViewTypes && !isFlying;

    return isLayerVisible ? 'visible' : 'none';
  },
);

export const getThreatPointsOpacity = createSelector([getLayers], (layers) =>
  layers.events ? 0.6 : 0,
);

export const getThreatPointsWeights = createSelector(
  [getCrimesNumber],
  (eventCount) => getRadiusByQuantity(eventCount),
);

export const getThreatPointsRadius = createSelector(
  [getCrimesNumber],
  (eventCount) => getWeightByQuantity(eventCount),
);

export const getThreatHeatmapVisibility = createSelector(
  [
    getSelectedSources,
    getSelectedCategories,
    getSelectedTimeslices,
    getSelectedMonths,
    getLayers,
    getViewTypeCity,
    getIsFlying,
  ],
  (sources, categories, timeslices, months, layers, isCityView, isFlying) => {
    const selectedFilters =
      sources.length > 0 &&
      categories.length > 0 &&
      timeslices.length > 0 &&
      months.length > 0;

    const isLayerVisible =
      selectedFilters && layers.heatmap && isCityView && !isFlying;

    return isLayerVisible ? 'visible' : 'none';
  },
);

export const getThreatHeatmapSettings = createSelector(
  [
    getThreatPointsFilter,
    getThreatPointsWeights,
    getThreatPointsRadius,
    getThreatHeatmapVisibility,
    getLocation,
  ],
  (filter, weights, radius, visibility, {id}) => ({
    id: 'threat-heatmap-layer',
    source: 'threat-points-source',
    'source-layer': 'public.base_threat_points_source',
    beforeId: 'country-boundaries-layer',
    minzoom: id ? 2 : 10,
    type: 'heatmap',
    filter,
    layout: {
      visibility,
    },
    paint: {
      'heatmap-weight': weights,
      'heatmap-radius': radius,
      'heatmap-opacity': 0.6,
      'heatmap-intensity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        0.05,
        11,
        0.08,
        12,
        0.11,
        13,
        0.14,
        14,
        0.15,
      ],
      'heatmap-color': [
        'step',
        ['heatmap-density'],
        baseTheme.palette.map.none,
        0.1,
        baseTheme.palette.decile[1],
        0.2,
        baseTheme.palette.decile[2],
        0.3,
        baseTheme.palette.decile[3],
        0.4,
        baseTheme.palette.decile[4],
        0.5,
        baseTheme.palette.decile[5],
        0.6,
        baseTheme.palette.decile[6],
        0.7,
        baseTheme.palette.decile[7],
        0.8,
        baseTheme.palette.decile[8],
        0.9,
        baseTheme.palette.decile[9],
        1,
        baseTheme.palette.decile[10],
      ],
    },
  }),
);

export const getThreatPointsSettings = createSelector(
  [
    getThreatPointsVisibility,
    getThreatPointsFilter,
    getThreatPointsOpacity,
    getLocation,
  ],
  (visibility, filter, opacity, {id}) => ({
    id: 'threat-points-layer',
    source: 'threat-points-source',
    'source-layer': 'public.base_threat_points_source',
    beforeId: 'country-boundaries-layer',
    minzoom: id ? 2 : 10,
    type: 'circle',
    filter,
    layout: {
      visibility,
    },
    paint: {
      'circle-radius': 5,
      'circle-opacity': opacity,
      'circle-color': [
        'match',
        ['get', 'w'],
        10,
        baseTheme.palette.decile[10],
        9,
        baseTheme.palette.decile[9],
        8,
        baseTheme.palette.decile[8],
        7,
        baseTheme.palette.decile[7],
        6,
        baseTheme.palette.decile[6],
        5,
        baseTheme.palette.decile[5],
        4,
        baseTheme.palette.decile[4],
        3,
        baseTheme.palette.decile[3],
        2,
        baseTheme.palette.decile[2],
        1,
        baseTheme.palette.decile[1],
        baseTheme.palette.map.disabled,
      ],
    },
  }),
);

export const getCityMarkersFilter = createSelector(
  [getViewTypeWorld],
  (isWorldView) => {
    if (isWorldView) {
      return ['step', ['zoom'], false, 4, ['all']];
    }
    return ['all'];
  },
);

export const getCityMarkersLayerSettings = createSelector(
  [
    getCityMarkersFilter,
    getIsFlying,
    getLocation,
    getSelectionMarker,
    getThreatAnalyticsAreaDisplay,
    getSearchMarker,
  ],
  (filter, isFlying, {id}, selectionMarker, areaVisible, searchMarker) => ({
    id: 'city-markers-layer',
    source: 'city-markers-source',
    'source-layer': CITY_MARKERS_LAYER,
    type: 'symbol',
    filter,
    layout: {
      'icon-image': [
        'case',
        ['!=', ['get', 'id'], id || ''],
        'marker_default',
        !isEmpty(selectionMarker) || areaVisible || !isEmpty(searchMarker)
          ? 'marker_default'
          : 'marker_focused',
      ],
      'icon-size': 0.7,
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      visibility: !isFlying ? 'visible' : 'none',
    },
  }),
);

export const getCityMarkersTextLayer = createSelector(
  [getDefaultThemeStatus, getViewTypeCity, getIsFlying],
  (isDefaultTheme, isCityView, isFlying) => ({
    id: 'city-markers-text-layer',
    source: 'city-markers-source',
    'source-layer': CITY_MARKERS_LAYER,
    type: 'symbol',
    layout: {
      'text-field': ['get', 'n'],
      'text-size': 10,
      'text-max-width': 20,
      'text-radial-offset': 2,
      'text-justify': 'center',
      'text-anchor': 'bottom',
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-optional': true,
      visibility: !isFlying && isCityView ? 'visible' : 'none',
    },
    paint: {
      'text-color': isDefaultTheme
        ? baseTheme.palette.map.text.light
        : baseTheme.palette.map.text.dark,
      'text-halo-color': isDefaultTheme
        ? baseTheme.palette.map.text.dark
        : baseTheme.palette.map.text.light,
      'text-halo-width': 1,
    },
  }),
);

export const getLocationZoomThreshold = createSelector(
  [getLocation, getLayers],
  ({id, minZoomLevel}, layers) => {
    const isRestrictedView = layers.events || layers.heatmap;

    if (isRestrictedView && !id) {
      return ZOOM.HEATMAP_THRESHOLD;
    }
    return minZoomLevel || ZOOM.HEATMAP_THRESHOLD;
  },
);

export const getLocationZoom = createSelector(
  [getLocation, getLayers],
  ({id, defaultZoomLevel}, layers) => {
    const isRestrictedView = layers.events || layers.heatmap;

    if (isRestrictedView && !id) {
      return ZOOM.HEATMAP_THRESHOLD;
    }
    return defaultZoomLevel || ZOOM.HEATMAP_THRESHOLD;
  },
);

export const citySettings = createSelector(
  [
    getLayers,
    getCategories,
    getTimeSlices,
    getMonths,
    getUserLocale,
    getThreatHeatmapSettings,
    getThreatPointsSettings,
  ],
  (
    layers,
    categories,
    timeslices,
    months,
    locale,
    heatmapSettings,
    eventSettings,
  ) => {
    const opts = {
      categories: Object.keys(categories)
        .filter((m) => categories[m])
        .map((m) => I18N[locale].filters.categories[m]),
      timeslices: Object.keys(timeslices)
        .filter((m) => timeslices[m])
        .map((m) => I18N[locale].filters.timeslices[m]),
      months: Object.keys(months)
        .filter((m) => months[m])
        .map((m) => I18N[locale].filters.months[m]),
    };

    const showEvents = layers.heatmap || layers.events;

    const showHeatmap = layers.heatmap;

    if (showEvents) {
      opts.settings = eventSettings;
    }

    if (showHeatmap) {
      opts.settings = heatmapSettings;
    }

    return {
      ...opts,
    };
  },
);

export const getIsDataVisible = createSelector(
  [getSummary, getLocation],
  (summary, location) =>
    !isEmpty(summary.sourceThreatCategories) || !isEmpty(location),
);

export const getAnalyticsVisibility = createSelector(
  [
    getViewTypeCountry,
    getViewTypeCity,
    getDrawer,
    getSelectedDistrictIds,
    getIsFlying,
    getSelectedCategories,
    getThreatAnalyticsAreaDisplay,
    getSelectedSources,
    getLocation,
    getSelectionMarker,
    getIsDataVisible,
  ],
  (
    isCountryView,
    isCityView,
    {analyticsOpen},
    selectedDistrictIds,
    isFlying,
    categories,
    radiusAnalyticsVisible,
    sources,
    {id},
    selectionMarker,
    isDataVisible,
  ) =>
    analyticsOpen &&
    (isCountryView || isCityView) &&
    !isFlying &&
    categories?.length > 0 &&
    sources?.length > 0 &&
    (radiusAnalyticsVisible ||
      !!id ||
      selectedDistrictIds.length > 0 ||
      !isEmpty(selectionMarker)) &&
    isDataVisible,
);

export const getFiltersVisibility = createSelector(
  [
    getViewTypeCountry,
    getViewTypeCity,
    getDrawer,
    getIsFlying,
    getIsDataVisible,
  ],
  (isCountryView, isCityView, {filtersOpen}, isFlying, isDataVisible) =>
    filtersOpen && (isCountryView || isCityView) && !isFlying && isDataVisible,
);

export const getAnalyticsRequestEnabled = createSelector(
  [getFiltersLoading, getAnalyticsVisibility],
  (isLoadingFilters, isDisplayAnalytics) =>
    !isLoadingFilters && isDisplayAnalytics,
);

export const getSavedLocationsMarkersLayer = createSelector(
  [getIsFlying, getViewTypeWorld, getClickedLocation],
  (isFlying, isWorldView, {id}) => ({
    id: 'saved-locations-markers-layer',
    source: 'saved-locations-source',
    'source-layer': 'public.saved_locations_fn',
    type: 'symbol',
    filter: id ? ['!=', ['get', 'id'], id] : ['all'],
    layout: {
      'icon-image': [
        'case',
        ['==', ['get', 't'], 'office'],
        'marker_office',
        ['==', ['get', 't'], 'hotel'],
        'marker_hotel',
        ['==', ['get', 't'], 'event'],
        'marker_event',
        ['==', ['get', 't'], 'restaurant'],
        'marker_restaurant',
        ['==', ['get', 't'], 'travel'],
        'marker_travel',
        ['==', ['get', 't'], 'supply_chain'],
        'marker_supply_chain',
        ['==', ['get', 't'], 'facility'],
        'marker_facility',
        'marker_other',
      ],
      'icon-size': 0.7,
      'icon-allow-overlap': !isWorldView,
      'icon-ignore-placement': !isWorldView,
      visibility: isFlying ? 'none' : 'visible',
    },
  }),
);

export const getSavedLocationsTextLayer = createSelector(
  [getDefaultThemeStatus, getViewTypeWorld, getIsFlying, getClickedLocation],
  (isDefaultTheme, isWorldView, isFlying, {id}) => ({
    id: 'saved-locations-text-layer',
    source: 'saved-locations-source',
    'source-layer': 'public.saved_locations_fn',
    type: 'symbol',
    filter: id ? ['!=', ['get', 'id'], id] : ['all'],
    layout: {
      'text-field': ['get', 'n'],
      'text-size': 10,
      'text-max-width': 20,
      'text-radial-offset': 2,
      'text-justify': 'center',
      'text-anchor': 'bottom',
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-optional': true,
      visibility: isFlying || isWorldView ? 'none' : 'visible',
    },
    paint: {
      'text-opacity': isWorldView ? 0 : 1,
      'text-color': isDefaultTheme
        ? baseTheme.palette.map.text.light
        : baseTheme.palette.map.text.dark,
      'text-halo-color': isDefaultTheme
        ? baseTheme.palette.map.text.dark
        : baseTheme.palette.map.text.light,
      'text-halo-width': 1,
    },
  }),
);

export const getSelectionMarkerSourceSettings = createSelector(
  [
    getSelectionMarker,
    getSearchMarker,
    getNewUserLocation,
    getLocationAddress,
    getClickedLocationName,
  ],
  (
    selectionMarker,
    searchMarker,
    locationMarker,
    searchValue,
    locationName,
  ) => ({
    id: 'custom-marker-source',
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: !isEmpty(selectionMarker)
              ? [selectionMarker.lon, selectionMarker.lat]
              : [0, 0],
          },
          properties: {
            'marker-type': 'selection',
          },
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: !isEmpty(searchMarker)
              ? [searchMarker.lng, searchMarker.lat]
              : [0, 0],
          },
          properties: {
            'marker-type': 'search',
            'marker-label': searchValue || '',
          },
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: !isEmpty(locationMarker)
              ? [locationMarker.lon, locationMarker.lat]
              : [0, 0],
          },
          properties: {
            'marker-type': 'user-location',
            'marker-label': locationName || '',
          },
        },
      ],
    },
  }),
);

export const getCustomMarkerFilter = createSelector(
  [getSelectionMarker, getSearchMarker, getNewUserLocation],
  (selectionMarker, searchMarker, locationMarker) => {
    const filter = ['none'];

    if (isEmpty(selectionMarker)) {
      filter.push(['==', 'marker-type', 'selection']);
    }

    if (isEmpty(searchMarker)) {
      filter.push(['==', 'marker-type', 'search']);
    }

    if (isEmpty(locationMarker)) {
      filter.push(['==', 'marker-type', 'user-location']);
    }

    return filter;
  },
);

export const getCustomMarkerVisibility = createSelector(
  [getSelectionMarker, getSearchMarker, getNewUserLocation, getIsFlying],
  (selectionMarker, searchMarker, locationMarker, isFlying) =>
    (!isEmpty(selectionMarker) ||
      !isEmpty(searchMarker) ||
      !isEmpty(locationMarker)) &&
    !isFlying
      ? 'visible'
      : 'none',
);

export const getSelectionMarkerSettings = createSelector(
  [
    getCustomMarkerVisibility,
    getCustomMarkerFilter,
    getDefaultThemeStatus,
    getViewTypeWorld,
    getSelectionMarker,
    getThreatAnalyticsAreaDisplay,
    getThreatAnalyticsArea,
    getSearchMarker,
  ],
  (
    visibility,
    filter,
    isDefaultTheme,
    isWorldView,
    selectionMarker,
    areaVisible,
    analyticsArea,
    searchMarker,
  ) => ({
    id: 'custom-marker-layer',
    source: 'custom-marker-source',
    type: 'symbol',
    layout: {
      'text-field': isWorldView ? '' : ['get', 'marker-label'],
      'text-size': 10,
      'text-max-width': 20,
      'text-radial-offset': 2.7,
      'text-justify': 'center',
      'text-anchor': 'bottom',
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-allow-overlap': false,
      'text-optional': true,
      'text-ignore-placement': ['==', 'marker-type', 'user-location'],
      'icon-allow-overlap': ['==', 'marker-type', 'user-location'],
      'icon-size': 0.7,
      'icon-image': [
        'case',
        ['==', ['get', 'marker-type'], 'selection'],
        'marker_focused',
        ['==', ['get', 'marker-type'], 'user-location'],
        '',
        ['==', ['get', 'marker-type'], 'search'],
        areaVisible && areCoordinatesEqual(searchMarker, analyticsArea)
          ? 'marker_other'
          : 'marker_focused',
        !isEmpty(selectionMarker) || areaVisible
          ? 'marker_default'
          : 'marker_focused',
      ],
      visibility,
    },
    filter,
    paint: {
      'icon-opacity': 1,
      'icon-translate': [0, -7],
      'text-opacity': isWorldView ? 0 : 1,
      'text-color': isDefaultTheme
        ? baseTheme.palette.map.text.light
        : baseTheme.palette.map.text.dark,
      'text-halo-color': isDefaultTheme
        ? baseTheme.palette.map.text.dark
        : baseTheme.palette.map.text.light,
      'text-halo-width': 1,
    },
  }),
);

export const getViewTypeString = createSelector(
  [getViewTypeWorld, getViewTypeCountry, getViewTypeCity],
  (isWorldView, isCountryView, isCityView) => {
    let type = '';

    if (isCityView) {
      type = 'City';
    } else if (isCountryView) {
      type = 'Country';
    } else if (isWorldView) {
      type = 'World';
    }

    return type;
  },
);

export const getLocationThreatTypes = createSelector(
  [getCategories, getThreatCategories, getSelectedSources, getSummary],
  (
    categories,
    threatTypes,

    selectedSources,
    {sourceThreatCategories},
  ) => {
    const sourcesThreatIds = new Set(
      (sourceThreatCategories || [])
        .filter(({sourceCategoryId}) =>
          selectedSources.includes(sourceCategoryId),
        )
        .flatMap(({threatCategoryIds}) => threatCategoryIds),
    );

    const children = (threatTypes || [])
      .filter(({id}) => new Set(Object.keys(categories).map(Number)).has(id))
      .map((category) => ({
        ...category,
        disabled: !sourcesThreatIds.has(category.id),
      }));

    const parentsOrder = [58, 59, 57, 60, 55, 56];

    const parents = (threatTypes || [])
      .filter(({id}) => new Set(children.map(({parentId}) => parentId)).has(id))
      .map((parent) => {
        const parentChildren = children.filter(
          ({parentId}) => parentId === parent.id,
        );
        return {
          ...parent,
          disabled: parentChildren.every(({disabled}) => disabled),
        };
      })
      .sort(
        (parentA, parentB) =>
          parentsOrder.indexOf(parentA.id) - parentsOrder.indexOf(parentB.id),
      );

    return [...parents, ...children];
  },
);
