import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Box, Typography, useTheme} from '@mui/material';

import {getClickedHoodId, getDistricts} from '@/selectors';

import Quintiles from '@/components/analytics/quintile/Quintile';

import {useTranslation} from '@/hooks/useTranslation';
import {API_CHART_QUINTILES} from '@/hooks/api/constants';
import {useChart} from '@/hooks/api/useChart';
import BaseSkeleton from '@/components/common/BaseSkeleton';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import {styles} from '@/components/analytics/sections/SeverityScoreSection.styles';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';

function SeverityScoreSection({id, granularity, sx, onlyCharts}) {
  const {getI18N} = useTranslation();
  const theme = useTheme();

  const {locationDistricts} = useSelector(getDistricts);
  const {selectedDistricts} = useSelector(getDistricts);
  const locationHoodId = useSelector(getClickedHoodId);

  const quintiles = getI18N('quantiles');
  const unknown = getI18N('unknown');

  const score = useMemo(() => {
    const hoodId = selectedDistricts?.[0]?.id ?? locationHoodId;
    const hood = locationDistricts.find(({id}) => id === hoodId);
    return {
      value: hood?.total || '-',
      label: quintiles[hood?.total] || unknown,
    };
  }, [
    selectedDistricts,
    locationDistricts,
    quintiles,
    unknown,
    locationHoodId,
  ]);

  // Downloads
  const title = getI18N('threatAnalytics.chartTitle9');

  const {
    data,
    isFetching,
    error,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title,
    path: API_CHART_QUINTILES,
    granularity,
  });

  if (onlyCharts)
    return (
      <ReportChartWrapper isLoading={isFetching} error={Boolean(error)}>
        {!isFetching && (
          <Box
            sx={styles.quintileScore}
            style={{
              backgroundColor: theme.palette.quintile[score.value],
            }}>
            <Typography variant="caption" sx={styles.scoreLabel}>
              {score.value} ({score.label})
            </Typography>
          </Box>
        )}
        <Quintiles
          compact
          defaultExpanded
          height={sx.height}
          width={sx.width}
          data={data}
          isFetching={isFetching}
        />
      </ReportChartWrapper>
    );

  if (error) return null;

  return (
    <Box id={id} sx={sx}>
      <Box display="flex" flexDirection="column" alignItems="left">
        <AnalyticsWrapper
          downloadable
          title={title}
          tooltip="severityScore"
          downloadCSV={downloadCSV}
          copyCSV={copyCSV}
          downloadImage={downloadImage}
          copyImage={copyImage}
          chart={
            <>
              {isFetching && (
                <BaseSkeleton
                  width="60px"
                  height="24px"
                  sx={styles.quintileSkeleton}
                />
              )}
              {!isFetching && (
                <Box
                  sx={styles.quintileScore}
                  style={{
                    backgroundColor: theme.palette.quintile[score.value],
                  }}>
                  <Typography variant="caption" sx={styles.scoreLabel}>
                    {score.value} ({score.label})
                  </Typography>
                </Box>
              )}
              <Quintiles data={data} isFetching={isFetching} />
            </>
          }
        />
      </Box>
    </Box>
  );
}

SeverityScoreSection.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onlyCharts: PropTypes.bool,
};

export default SeverityScoreSection;
