import {useStore} from 'react-redux';

import {
  THREAT_POINTS_LAYER,
  SAVED_LOCATIONS_LAYER,
  CHANGE_LAYER,
  THREAT_SCORE,
} from '@/components/map/layers/constants';
import {buildQueryParams} from '@/utils/utils';

import {useDates} from '@/hooks/useDates';
import {useAuth0Service} from '@/hooks/useAuth0Service';
import {useRouter} from '@/hooks/useRouter';

export const useMapTransformRequest = () => {
  const store = useStore();
  const {toMoment} = useDates();
  const {reportAccessToken, accessToken} = useAuth0Service();
  const {isMapRoute} = useRouter();

  const handleTransformRequest = (url, resource) => {
    let params = '';
    let headers = {};
    const token = reportAccessToken || accessToken;
    const state = store.getState();
    const userId = state.user.profile.id;
    const organizationId = state.organization.activeId;

    if (
      (resource === 'Tile' || resource === 'Source') &&
      url.includes('baseoperationsenterprise') &&
      url.includes('maps') &&
      token
    ) {
      headers = {Authorization: `Bearer ${token}`};
    }

    if (
      resource === 'Tile' &&
      url.includes(THREAT_POINTS_LAYER) &&
      !url.includes(THREAT_SCORE)
    ) {
      const {fromDate, toDate} = state.filters;
      const fromDateQuery =
        fromDate &&
        toMoment({
          year: fromDate.year(),
          month: fromDate.month(),
          date: fromDate.date(),
          hour: 0,
          minute: 0,
          second: 0,
        }).toISOString();

      const toDateQuery =
        toDate &&
        toMoment({
          year: toDate.year(),
          month: toDate.month(),
          date: toDate.date(),
          hour: 23,
          minute: 59,
          second: 59,
        }).toISOString();

      params = buildQueryParams({
        fromDate: fromDateQuery,
        toDate: toDateQuery,
      });
    } else if (resource === 'Tile' && url.includes(CHANGE_LAYER)) {
      const {analysisFilters: filters} = state.savedLocations;
      if (isMapRoute) {
        params = buildQueryParams({
          userId,
          organizationId,
        });
      } else {
        params = buildQueryParams({
          userId,
          organizationId,
          minCustomerRating: filters.minCustomerRating,
          maxCustomerRating: filters.maxCustomerRating,
          includeUnrated: filters.includeUnrated,
          addressTypes:
            filters.addressTypes?.length > 0
              ? JSON.stringify(filters.addressTypes)
              : null,
          tags: filters.tags?.length > 0 ? JSON.stringify(filters.tags) : null,
          visibility: filters.visibility,
        });
      }
    } else if (resource === 'Tile' && url.includes(SAVED_LOCATIONS_LAYER)) {
      params = buildQueryParams({userId, organizationId});
    }
    return {
      url: url + params,
      headers,
    };
  };

  return {handleTransformRequest};
};
