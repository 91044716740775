import {useDispatch} from 'react-redux';
import {useEffect} from 'react';

import {API_THREAT_CATEGORIES} from '@hooks/api/constants';
import {useApi} from '@hooks/api/useApi';
import {setThreatCategories} from '@/store/modules/threat-categories/actions';

export const useThreatCategories = ({enabled}) => {
  const dispatch = useDispatch();
  const {useGetQuery} = useApi();

  const {data, isFetching} = useGetQuery({
    path: API_THREAT_CATEGORIES,
    config: {
      enabled,
    },
  });

  useEffect(() => {
    if (data && !isFetching) {
      dispatch(setThreatCategories(data));
    }
  }, [dispatch, data, isFetching]);
};
