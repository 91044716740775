import {useEffect, useMemo, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import throttle from 'lodash/throttle';

import {GOOGLE_PLACES_API} from '@config';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import {MagnifyingGlass} from '@phosphor-icons/react';

import {API_LOCATION_SEARCH} from '@hooks/api/constants';
import {useScript} from '@hooks/useScript';
import {useTranslation} from '@hooks/useTranslation';
import {useApi} from '@hooks/api/useApi';
import {setSearchMarker} from '@/store/modules/map/actions';
import {
  setGoogleValue,
  setSuggestions,
  setInputValue,
  setSearchValue,
  setSearchResult,
} from '@/store/modules/filters/actions';
import {
  getGoogleValue,
  getInputValue,
  getSuggestions,
  getSearchValue,
  getModal,
} from '@/selectors';
import {useSnackbar} from '@/hooks/useSnackbar';

const autocompleteService = {current: null};

const useStyles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  startIcon: {
    marginLeft: '32px',
    marginRight: '12px',
  },
});

function SearchInput(props) {
  const styles = useStyles();
  const dispatch = useDispatch();
  const {getI18N} = useTranslation();
  const {useGetQuery} = useApi();
  const scriptStatus = useScript(GOOGLE_PLACES_API);
  const {searchboxPlaceholder} = getI18N('home');
  const inputRef = useRef(null);
  const {showSnackbar} = useSnackbar();

  const googleValue = useSelector(getGoogleValue);
  const inputValue = useSelector(getInputValue);
  const suggestions = useSelector(getSuggestions);
  const searchValue = useSelector(getSearchValue);
  const {isSearchOpen} = useSelector(getModal);

  const {isFetching, data: searchLocation} = useGetQuery({
    path: API_LOCATION_SEARCH,
    params: {
      placeId: searchValue,
    },
    config: {
      enabled: Boolean(searchValue),
    },
  });

  useEffect(() => {
    if (searchLocation && !isFetching) {
      dispatch(setSearchResult(searchLocation));
    }
  }, [searchLocation, isFetching]);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 1100),
    [],
  );

  const handleSearchClear = () => {
    dispatch(setSearchMarker(null));
    dispatch(setInputValue());
    dispatch(setGoogleValue());
    dispatch(setSuggestions());
    dispatch(setSearchValue());
    dispatch(setSearchResult());
  };

  const handleInputValue = (event, newInputValue, reason) => {
    if (reason === 'clear') {
      handleSearchClear();
    }

    dispatch(setInputValue(newInputValue));
  };

  const handleOptionLabel = (option) =>
    typeof option === 'string' ? option : option?.description;

  const handleFilterOptions = (x) => x;

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (inputValue === '') {
      dispatch(setSuggestions(googleValue ? [googleValue] : []));
      return undefined;
    }

    fetch({input: inputValue}, (results) => {
      if (active) {
        let newSuggestions = [];
        if (googleValue) {
          newSuggestions = [googleValue];
        }
        if (results) {
          newSuggestions = [...newSuggestions, ...results];
        }
        dispatch(setSuggestions(newSuggestions));
      }
    });

    return () => {
      active = false;
    };
  }, [googleValue, inputValue, fetch]);

  useEffect(
    () => () => {
      handleSearchClear();
    },
    [isSearchOpen],
  );

  useEffect(() => {
    if (scriptStatus === 'error') {
      showSnackbar({
        icon: 'warning',
        iconColor: 'error',
        message: 'Unable to read Google Maps API',
      });
    }
  }, [scriptStatus]);

  useEffect(() => {
    if (scriptStatus === 'ready') {
      inputRef.current.focus();
    }
  }, [scriptStatus]);

  return (
    <Autocomplete
      id="modal-search-autocomplete"
      open={false}
      disabled={scriptStatus !== 'ready'}
      freeSolo
      autoComplete
      includeInputInList
      filterSelectedOptions
      disableClearable
      autoHighlight
      sx={styles.root}
      getOptionLabel={handleOptionLabel}
      filterOptions={handleFilterOptions}
      options={suggestions}
      value={googleValue}
      onInputChange={handleInputValue}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          fullWidth
          variant="standard"
          size="small"
          color="secondary"
          inputRef={inputRef}
          placeholder={searchboxPlaceholder}
          InputProps={{
            ...params.InputProps,
            'data-testid': 'search-box-text-field',
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                {isFetching ? (
                  <CircularProgress size={24} style={styles.startIcon} />
                ) : (
                  <MagnifyingGlass size={24} style={styles.startIcon} />
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

export default SearchInput;
