import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTranslation} from '@/hooks/useTranslation';
import {styles} from '@/components/saved-locations/cards/SavedVisibilityLabel.styles';

function SavedVisibilityLabel({sx, isPrivate}) {
  const {getI18N} = useTranslation();
  const {visibilityOptions: visibilityOptionLabels} = getI18N('savedLocations');

  return (
    <Box sx={{...styles.wrapper, ...sx}}>
      <Typography sx={styles.label}>
        {isPrivate
          ? visibilityOptionLabels.private
          : visibilityOptionLabels.shared}
      </Typography>
    </Box>
  );
}

SavedVisibilityLabel.propTypes = {
  sx: PropTypes.object,
  isPrivate: PropTypes.bool,
};

export default SavedVisibilityLabel;
