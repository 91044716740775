import React from 'react';
import PropTypes from 'prop-types';

import Drawer from '@mui/material/Drawer';
import {styles} from '@common/drawer/DrawerBO.styles';

function DrawerBO(props) {
  const {
    open,
    anchor,
    variant,
    PaperProps,
    onClose,
    children,
    width,
    height,
    isMain,
    onClick,
  } = props;

  return (
    <Drawer
      onClick={onClick}
      variant={variant}
      anchor={anchor}
      PaperProps={{
        ...PaperProps,
        sx: (theme) =>
          styles.drawerPaper({theme, width, height, isMain, anchor}),
      }}
      onClose={onClose}
      open={open}
      sx={styles.root}>
      {children}
    </Drawer>
  );
}

DrawerBO.propTypes = {
  onClick: PropTypes.func,
  isMain: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  anchor: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
  variant: PropTypes.oneOf(['persistent', 'temporary']),
  PaperProps: PropTypes.object,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DrawerBO.defaultProps = {
  height: '100vh',
  width: '360px',
  open: true,
  isMain: false,
  variant: 'persistent',
  anchor: 'left',
};

export default DrawerBO;
