import {useEffect, useState} from 'react';

import {MODE} from '@config';
import {useMixpanel} from '@/hooks/useMixpanel';
import {useTranslation} from '@/hooks/useTranslation';
import {useSnackbar} from '@/hooks/useSnackbar';

export const useVersionUpdates = () => {
  const {track} = useMixpanel();
  const {getI18N} = useTranslation();
  const {showSnackbar, hideSnackbarById} = useSnackbar();
  const [version, setVersion] = useState('');
  const [newVersion, setNewVersion] = useState('');

  const {message, action} = getI18N('updateNotification');
  const snackbarId = 'new-version-alert';

  const checkVersion = async () => {
    if (MODE === 'development') return '';
    try {
      const response = await fetch('/version.json');
      const data = await response.json();
      return data.version;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    (async () => {
      const version = await checkVersion();
      setVersion(version);
    })();
  }, []);

  useEffect(() => {
    const interval = setInterval(
      async () => {
        const newVersion = await checkVersion();
        if (newVersion !== version) {
          setNewVersion(newVersion);
        }
      },
      15 * 60 * 1000,
    );
    return () => clearInterval(interval);
  }, [version]);

  useEffect(() => {
    if (version && newVersion && version !== newVersion) {
      track('Opened New Dash Version Pop up');
      showSnackbar({
        id: snackbarId,
        message,
        actions: [
          {
            label: action,
            props: {
              onClick: (event) => {
                event?.preventDefault();
                hideSnackbarById(snackbarId);
                window.location.reload();
              },
            },
          },
        ],
        duration: 0,
      });
    }
  }, [version, newVersion]);

  return {};
};
