export const styles = {
  root: () => ({
    marginTop: 2,
    marginBottom: 3,
    marginInline: 2,
  }),
  header: {
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  labelText: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
};
