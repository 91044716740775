export const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  disabled: (theme) => ({
    display: 'flex',
    color: theme.palette.text.disabled,
  }),
  banner: (theme) => ({
    background: theme.palette.background.dark,
    borderColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 2,
    width: '100%',
    height: '64px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '4px',
    marginBottom: 2,
  }),
};
