// Packages
import {useState, useEffect, useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// MUI
import Box from '@mui/material/Box';

// Constants
import {
  getLocationFilterOptions,
  SAVED_LOCATIONS_VISIBILITY,
  SAVED_LOCATION_TYPES,
  DEGREE_OF_CHANGE,
} from '@saved-locations/constants';

// State Handlers
import {getSavedLocations} from '@/selectors';
import {
  setSavedLocationFilters,
  setSavedLocationsAnalysisFilters,
} from '@/store/modules/saved-locations/actions';

// Hooks
import {useTranslation} from '@/hooks/useTranslation';
import {useMixpanel} from '@/hooks/useMixpanel';

// Components
import {styles} from '@/components/filters/FilterSavedLocations.styles';
import BaseSlider from '@/components/common/inputs/BaseSlider';
import BaseExpandableCheckboxGroup from '@/components/common/checkboxes/BaseExpandableCheckboxGroup';
import FilterItem from '@/components/filters/FilterItem';
import BaseSwitch from '@/components/common/inputs/BaseSwitch';
import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';
import BaseCard from '@/components/common/cards/BaseCard';
import {ButtonChip} from '@/components/common/buttons';
import {useRouter} from '@/hooks/useRouter';

function FilterSavedLocations() {
  const dispatch = useDispatch();
  const {getI18N, getSavedTranslations} = useTranslation();
  const {track} = useMixpanel();
  const {isSavedRoute, isMyLocationsAnalysisRoute} = useRouter();

  const {tags: userTags} = useSelector(getSavedLocations);

  const [customerRating, setCustomerRating] = useState([1, 5]);
  const [includeUnrated, setIncludeUnrated] = useState(true);
  const [selectedTags, setSelectedTags] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState(
    Object.keys(SAVED_LOCATION_TYPES),
  );
  const [selectedVisibilities, setSelectedVisibilities] = useState(
    SAVED_LOCATIONS_VISIBILITY,
  );
  const [selectedDegreesOfChange, setSelectedDegreesOfChange] =
    useState(DEGREE_OF_CHANGE);

  const {
    filtersTitle,
    locationTypeLabel,
    tagsLabel,
    visibilityOptions: visibilityOptionLabels,
  } = getI18N('savedLocations');
  const {allDegreeOfChangesLabel, degreeOfChangeOptions} = getI18N(
    'myLocationsAnalysis.filters',
  );

  const handleCustomerRatingRange = (_, newValue) =>
    setCustomerRating(newValue);

  useEffect(() => {
    if (isSavedRoute) {
      dispatch(
        setSavedLocationFilters({
          addressTypes: JSON.stringify(
            selectedVisibilities.length === 0 ? [] : selectedTypes,
          ),
          tags: selectedTags,
          minCustomerRating: customerRating[0],
          maxCustomerRating: customerRating[1],
          includeUnrated,
          visibility:
            selectedVisibilities.length === 1
              ? selectedVisibilities[0]
              : undefined,
        }),
      );
    }

    if (isMyLocationsAnalysisRoute) {
      dispatch(
        setSavedLocationsAnalysisFilters({
          addressTypes: selectedTypes,
          tags: selectedTags,
          visibility:
            selectedVisibilities.length === 1
              ? selectedVisibilities[0]
              : undefined,
          degreesOfChange: selectedDegreesOfChange,
        }),
      );
    }
  }, [
    dispatch,
    selectedTypes,
    selectedTags,
    customerRating,
    includeUnrated,
    selectedVisibilities,
    selectedDegreesOfChange,
    isMyLocationsAnalysisRoute,
    isSavedRoute,
  ]);

  const getAllTags = useMemo(
    () => userTags?.map(({name}) => name) || [],
    [userTags],
  );

  const handleVisibility = useCallback(
    (visibility) => {
      if (selectedVisibilities.includes(visibility)) {
        setSelectedVisibilities(
          selectedVisibilities.filter(
            (selectedVisibility) => selectedVisibility !== visibility,
          ),
        );
      } else {
        setSelectedVisibilities([...selectedVisibilities, visibility]);
      }
    },
    [selectedVisibilities],
  );

  return (
    <Box sx={styles.root}>
      <BaseCard title={filtersTitle}>
        <FeatureAccessWrapper feature="shared-saved-locations">
          <FilterItem sx={styles.filterItem}>
            <ButtonChip
              label={visibilityOptionLabels.shared}
              selected={selectedVisibilities.includes(
                SAVED_LOCATIONS_VISIBILITY[0],
              )}
              onClick={() => handleVisibility(SAVED_LOCATIONS_VISIBILITY[0])}
            />
            <ButtonChip
              label={visibilityOptionLabels.private}
              selected={selectedVisibilities.includes(
                SAVED_LOCATIONS_VISIBILITY[1],
              )}
              onClick={() => handleVisibility(SAVED_LOCATIONS_VISIBILITY[1])}
            />
          </FilterItem>
        </FeatureAccessWrapper>
        {isSavedRoute && (
          <FeatureAccessWrapper feature="saved-locations-priority">
            <FilterItem
              sx={styles.filterItem}
              label={getSavedTranslations('Priority Rating')}>
              <Box marginTop={2}>
                <BaseSlider
                  marks
                  min={1}
                  max={5}
                  value={customerRating}
                  onChange={handleCustomerRatingRange}
                />
              </Box>
              <BaseSwitch
                checked={includeUnrated}
                onChange={() => {
                  setIncludeUnrated(!includeUnrated);
                }}
                label={getSavedTranslations('Include Unrated Locations')}
                checkedLabel={getSavedTranslations('Yes')}
                uncheckedLabel={getSavedTranslations('No')}
              />
            </FilterItem>
          </FeatureAccessWrapper>
        )}
        <FilterItem sx={styles.filterItem}>
          <BaseExpandableCheckboxGroup
            dense
            bold
            columns={1}
            selected={selectedTypes}
            onChange={(selected) => {
              setSelectedTypes(selected);
              track('Applied Saved Location Filters', {
                filters: selected,
              });
            }}
            options={[
              {
                name: locationTypeLabel,
                id: 'main',
                parentId: null,
              },
              ...getLocationFilterOptions(SAVED_LOCATION_TYPES).map((o) => ({
                name: getSavedTranslations(o.name),
                id: o.id,
                parentId: o.parentId,
              })),
            ]}
          />
        </FilterItem>
        <FeatureAccessWrapper feature="saved-locations-tags">
          <FilterItem sx={styles.filterItem}>
            <BaseExpandableCheckboxGroup
              dense
              bold
              columns={1}
              selected={selectedTags || []}
              onChange={(selected) => setSelectedTags(selected)}
              options={[
                {
                  name: tagsLabel,
                  id: 'main',
                  parentId: null,
                },
                ...getAllTags.map((tag) => ({
                  name: tag,
                  id: tag,
                  parentId: 'main',
                })),
              ]}
            />
          </FilterItem>
        </FeatureAccessWrapper>
        {isMyLocationsAnalysisRoute && (
          <FilterItem sx={styles.filterItem}>
            <BaseExpandableCheckboxGroup
              selected={selectedDegreesOfChange}
              dense
              columns={1}
              onChange={(selected) => setSelectedDegreesOfChange(selected)}
              options={[
                {
                  name: allDegreeOfChangesLabel,
                  id: 'main',
                  parentId: null,
                },
                ...degreeOfChangeOptions.map(({name, value}) => ({
                  name,
                  id: value,
                  parentId: 'main',
                })),
              ]}
            />
          </FilterItem>
        )}
      </BaseCard>
    </Box>
  );
}

export default FilterSavedLocations;
