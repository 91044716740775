import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BaseModal from '@/components/common/modals/BaseModal';
import BaseButton from '@/components/common/buttons/BaseButton';
import {styles} from '@/components/feature-access/FeatureAccessModal.styles';
import {useTranslation} from '@/hooks/useTranslation';

function FeatureAccessModal({isOpen, onClose, onSubmit, status}) {
  const {getI18N} = useTranslation();
  const {
    enterpriseFeature,
    thanks,
    offerUpgrade,
    upgradeFeedback,
    interest,
    closeLabel,
  } = getI18N('featureAccess');

  return (
    <BaseModal sx={styles.root} open={isOpen} onClose={onClose}>
      <Box sx={styles.content}>
        <Box sx={styles.image} />
        <Box sx={styles.textContainer}>
          <Typography variant="body1" sx={styles.text}>
            {status === 'initial' ? enterpriseFeature : thanks}
          </Typography>
          <Typography variant="caption">
            {status === 'initial' ? offerUpgrade : upgradeFeedback}
          </Typography>
        </Box>
        <BaseButton onClick={status === 'initial' ? onSubmit : onClose}>
          {status === 'initial' ? interest : closeLabel}
        </BaseButton>
      </Box>
    </BaseModal>
  );
}

FeatureAccessModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  status: PropTypes.string,
};

FeatureAccessModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  imageSrc: '',
  text: '',
  buttonText: '',
  onSubmit: () => {},
  status: 'initial',
};

export default FeatureAccessModal;
