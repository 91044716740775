import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import {useTranslation} from '@/hooks/useTranslation';

const useStyles = (prev, next) => ({
  basePagination: (theme) => ({
    width: '100%',
    minHeight: 'auto',
    '& .MuiToolbar-root': {
      minHeight: 'auto',
    },
    '& .MuiTablePagination-displayedRows': {
      fontSize: '12px',
      margin: '0 !important',
    },
    '& .MuiTablePagination-toolbar': {
      marginBottom: 0,
      overflow: 'hidden',
      justifyContent: 'space-between',
      width: '100%',
      padding: '0',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '16px !important',
    },
    '& .MuiTablePagination-actions': {
      margin: 0,
      color: theme.palette.primary.main,
      '& .MuiIconButton-root:hover': {
        backgroundColor: 'transparent',
      },
      '& button': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        '&:nth-of-type(2)': {
          '& .MuiIconButton-label': {
            display: 'none',
          },
          '&::after': {
            content: `"${prev}"`,
            display: 'inline',
            fontSize: '12px',
            textDecoration: 'underline',
          },
        },
        '&:nth-last-of-type(2)': {
          paddingRight: '0px',
          '& .MuiIconButton-label': {
            display: 'none',
          },
          '&::before': {
            content: `"${next}"`,
            display: 'inline',
            fontSize: '12px',
            textDecoration: 'underline',
          },
        },
        '&:disabled': {
          color: theme.palette.text.disabled,
        },
      },
    },
    '& .MuiTablePagination-spacer': {
      display: 'none',
    },
  }),
});

/**
 * Custom component for table pagination.
 *
 * @component
 * @param {Object} props - Component properties see [TablePagination API](https://mui.com/api/table-pagination/#props)
 * @returns {JSX.Element} Custom TablePagination component.
 */
function BasePagination(props) {
  const {getI18N} = useTranslation();
  const {of: ofLabel, moreThanLabel} = getI18N('legendQuintile');
  const {
    showing: showingLabel,
    nextLabel,
    previousLabel,
  } = getI18N('tablelocalization.pagination');

  const styles = useStyles(previousLabel, nextLabel);

  const handleLabelDisplayedRows = ({from, to, count}) =>
    `${showingLabel}: ${from}-${to} ${ofLabel} ${
      count !== -1 ? count : `${moreThanLabel} ${to}`
    }`;

  return (
    <TablePagination
      component="div"
      sx={styles.basePagination}
      rowsPerPageOptions={[]}
      labelDisplayedRows={handleLabelDisplayedRows}
      rowsPerPage={-1}
      showFirstButton
      showLastButton
      {...props}
    />
  );
}

export default BasePagination;
