import {memo} from 'react';
import {Source, Layer} from 'react-map-gl';
import {useSelector} from 'react-redux';
import {useTheme} from '@mui/material';

import {MARTIN_SERVICE_URL} from '@config';
import {
  POPULATION,
  POPULATION_FILL_LAYER,
  POPULATION_LINE_LAYER,
  POPULATION_SOURCE,
} from '@/components/map/layers/constants';
import {getLayers} from '@/selectors';

function LayerPopulation() {
  const theme = useTheme();
  const {population: isPopulationLayer} = useSelector(getLayers);

  return (
    <Source
      id={POPULATION_SOURCE}
      type="vector"
      url={MARTIN_SERVICE_URL + POPULATION}>
      <Layer
        id={POPULATION_FILL_LAYER}
        source={POPULATION_SOURCE}
        source-layer={`public.${POPULATION}_fn`}
        type="fill"
        minzoom={1}
        layout={{visibility: isPopulationLayer ? 'visible' : 'none'}}
        paint={{
          'fill-opacity': 0.6,
          'fill-color': [
            'step',
            ['get', 'q'],
            theme.palette.decile[1],
            1,
            theme.palette.decile[2],
            2,
            theme.palette.decile[3],
            3,
            theme.palette.decile[4],
            4,
            theme.palette.decile[5],
            5,
            theme.palette.decile[6],
            6,
            theme.palette.decile[7],
            7,
            theme.palette.decile[8],
            8,
            theme.palette.decile[9],
            9,
            theme.palette.decile[10],
          ],
        }}
      />
      <Layer
        id={POPULATION_LINE_LAYER}
        source={POPULATION_SOURCE}
        source-layer={`public.${POPULATION}_fn`}
        type="line"
        minzoom={2}
        layout={{visibility: isPopulationLayer ? 'visible' : 'none'}}
        paint={{
          'line-color': [
            'step',
            ['get', 'q'],
            theme.palette.decile[1],
            1,
            theme.palette.decile[2],
            2,
            theme.palette.decile[3],
            3,
            theme.palette.decile[4],
            4,
            theme.palette.decile[5],
            5,
            theme.palette.decile[6],
            6,
            theme.palette.decile[7],
            7,
            theme.palette.decile[8],
            8,
            theme.palette.decile[9],
            9,
            theme.palette.decile[10],
          ],
          'line-width': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            2,
            0.5,
          ],
        }}
      />
    </Source>
  );
}

export default memo(LayerPopulation);
