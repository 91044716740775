import {useCallback, useEffect, useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import {object, string} from 'yup';
import {useQuery} from '@/hooks/useQuery';
import {useRouter} from '@/hooks/useRouter';

export const useReportURL = () => {
  const query = useQuery();
  const [reportURL, setReportURL] = useState();
  const {isReportRoute} = useRouter();

  const citySchema = object({
    id: string().required(),
    locationId: string().required(),
    height: string().nullable(),
    width: string().nullable(),
    sources: string().required(),
    categories: string().required(),
    months: string().required(),
    daytimes: string().required(),
    fromDate: string().required(),
    toDate: string().required(),
    customFilters: string().nullable(),
  });

  const districtSchema = object({
    id: string().required(),
    locationId: string().required(),
    hoodId: string().required(),
    height: string().nullable(),
    width: string().nullable(),
    sources: string().required(),
    categories: string().required(),
    months: string().required(),
    daytimes: string().required(),
    fromDate: string().required(),
    toDate: string().required(),
    customFilters: string().nullable(),
  });

  const radiusSchema = object({
    id: string().required(),
    latitude: string().required(),
    longitude: string().required(),
    radius: string().required(),
    units: string().required(),
    height: string().nullable(),
    width: string().nullable(),
    sources: string().required(),
    categories: string().required(),
    months: string().required(),
    daytimes: string().required(),
    fromDate: string().required(),
    toDate: string().required(),
    customFilters: string().nullable(),
  });

  const setParam = (param, type) => (param ? type(param) : undefined);

  const validateURL = useCallback(async () => {
    let schema;
    switch (query.get('granularity')) {
      case 'radius':
        schema = radiusSchema;
        break;
      case 'district':
        schema = districtSchema;
        break;
      default:
        schema = citySchema;
    }

    try {
      return await schema.validate(Object.fromEntries(query));
    } catch (error) {
      console.error('error', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (isReportRoute && isEmpty(reportURL)) {
        const url = await validateURL();
        if (url) {
          setReportURL({
            id: setParam(url.id, String),
            granularity: setParam(url.granularity, String),
            height: setParam(url.height, Number),
            width: setParam(url.width, Number),
            latitude: setParam(url.latitude, Number),
            longitude: setParam(url.longitude, Number),
            locationId: setParam(url.locationId, Number),
            hoodId: setParam(url.hoodId, Number),
            radius: setParam(url.radius, Number),
            units: setParam(url.units, String),
            sources: setParam(url.sources, JSON.parse),
            categories: setParam(url.categories, JSON.parse),
            months: setParam(url.months, JSON.parse),
            daytimes: setParam(url.daytimes, JSON.parse),
            fromDate: setParam(url.fromDate, String),
            toDate: setParam(url.toDate, String),
            customFilters: setParam(url.customFilters, JSON.parse),
          });
        }
      }
    })();
  }, [validateURL, reportURL, isReportRoute]);

  return {reportURL};
};
