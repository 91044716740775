import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {useTranslation} from '@/hooks/useTranslation';
import {styles} from '@/components/analytics/AnalyticsTitle.styles';

import BaseTooltip from '@/components/common/BaseTooltip';
import ChartOptionsDropdown from '@/components/analytics/ChartOptionsDropdown';
import {BaseIconButton} from '@/components/common/buttons';

function AnalyticsTitle({
  tooltip,
  title,
  downloadable,
  copyImage,
  downloadImage,
  copyCSV,
  downloadCSV,
  sx,
  icon,
  onClick,
}) {
  const {getI18N, getTooltipTranslation} = useTranslation();
  const {copyImageLabel, downloadImageLabel, copyCSVLabel, downloadCSVLabel} =
    getI18N('chartOptions');

  const chartOptions = [
    {
      label: copyImageLabel,
      action: copyImage,
    },
    {
      label: downloadImageLabel,
      action: downloadImage,
    },
    {
      label: copyCSVLabel,
      action: copyCSV,
    },
    {
      label: downloadCSVLabel,
      action: downloadCSV,
    },
  ];

  const availableChartOptions = chartOptions.filter(
    (option) => option.action !== undefined,
  );

  return (
    <Box data-testid="chart-header" sx={{...styles.chartHeader, ...sx}}>
      <Typography align="left" variant="body2" style={styles.chartTitle}>
        {title}
        {tooltip && (
          <BaseTooltip message={getTooltipTranslation(tooltip)} iconSize={14} />
        )}
      </Typography>
      <Box>
        {icon && onClick && (
          <BaseIconButton icon={icon} onClick={onClick} sx={styles.icon} />
        )}
        {downloadable && (
          <ChartOptionsDropdown options={availableChartOptions} />
        )}
      </Box>
    </Box>
  );
}

AnalyticsTitle.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  downloadable: PropTypes.bool,
  copyImage: PropTypes.func,
  downloadImage: PropTypes.func,
  copyCSV: PropTypes.func,
  downloadCSV: PropTypes.func,
  sx: PropTypes.object,
  icon: PropTypes.elementType,
  onClick: PropTypes.func,
};

export default AnalyticsTitle;
