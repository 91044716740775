import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {styles} from '@/components/map/actions/ActionColorScale.styles';

function ActionColorScale({sx, title, height, width}) {
  return (
    <Box
      sx={(theme) => ({
        ...styles.root(theme),
        ...(typeof sx === 'function' ? sx(theme) : sx),
      })}>
      <Typography variant="caption">{title}</Typography>
      <Box sx={styles.colorScale} style={{width, height}} />
    </Box>
  );
}

ActionColorScale.defaultProps = {
  sx: {},
  title: '',
  height: '10px',
  width: '120px',
};

ActionColorScale.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ActionColorScale;
