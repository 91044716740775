import PropTypes from 'prop-types';

import Tabs from '@mui/material/Tabs';
import BaseTab from '@common/tabs/BaseTab';

function BaseTabGroup({
  value,
  onChange,
  options,
  orientation,
  variant,
  indicatorColor,
  textColor,
  textAlign,
  ...props
}) {
  return (
    <Tabs
      {...props}
      value={value}
      onChange={onChange}
      orientation={orientation}
      variant={variant}
      indicatorColor={indicatorColor}
      textColor={textColor}
      data-testid="tab-group-component">
      {options?.map((option, index) => (
        <BaseTab {...option} textAlign={textAlign} key={index} />
      ))}
    </Tabs>
  );
}

BaseTabGroup.defaultProps = {
  onChange: () => {},
  value: '',
  orientation: 'horizontal',
  variant: 'fullWidth',
  indicatorColor: 'none',
  textColor: 'primary',
  textAlign: 'center',
};

BaseTabGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  orientation: PropTypes.string,
  variant: PropTypes.string,
  indicatorColor: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default BaseTabGroup;
