import {useMemo} from 'react';
import {useChart} from '@/hooks/api/useChart';
import {
  API_HISTORICAL_MONTHLY,
  API_HISTORICAL_MONTHLY_RADIUS,
} from '@/hooks/api/constants';
import {useTranslation} from '@/hooks/useTranslation';

export const useHistoricalAverage = ({
  id = 'historical-average',
  title = 'Historical Average',
  granularity,
}) => {
  const {getAnalyticsTranslation} = useTranslation();
  const path = useMemo(
    () =>
      granularity === 'radius'
        ? API_HISTORICAL_MONTHLY_RADIUS
        : API_HISTORICAL_MONTHLY,
    [granularity],
  );

  const {
    data: rawData,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title,
    granularity,
    path,
    exactDates: false,
    customParams: {
      timeAggregation: 'monthly',
    },
  });

  const formattedData = useMemo(() => {
    const data = rawData?.chartData;
    if (!data || data.length === 0) {
      return [];
    }

    const sortedData = data.sort((a, b) => a.monthNumber - b.monthNumber);
    return sortedData.map((d) => ({
      x: getAnalyticsTranslation(d.month),
      y: parseFloat(d.average),
      monthNumber: d.monthNumber,
    }));
  }, [rawData]);

  return {
    data: formattedData,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  };
};
