import {useEffect, useState} from 'react';
import {useLocationsMostChange} from '@/hooks/api/useLocationsMostChange';
import {useChangeTrend} from '@/hooks/charts/useChangeTrend';

export const useLocationChangeValue = ({granularity, locationId}) => {
  const [percentChange, setPercentChange] = useState();

  const {data, isFetching} = useLocationsMostChange({
    filters: {
      granularity,
      timeAggregation: 'yearly',
      locationIds: [locationId],
    },
    changeMetric: 'yearOverYear',
    sortDirection: 'desc',
    config: {
      enabled: Boolean(locationId),
    },
  });

  const {
    data: percentChangeRadiusData,
    isFetching: percentChangeRadiusIsFetching,
  } = useChangeTrend({
    granularity,
    aggregation: 'yearly',
  });

  useEffect(() => {
    if (granularity === 'city' && data && data[0] && !isFetching) {
      setPercentChange(data[0].change);
    }
    if (
      granularity === 'radius' &&
      percentChangeRadiusData &&
      !percentChangeRadiusIsFetching
    ) {
      setPercentChange(
        percentChangeRadiusData[percentChangeRadiusData.length - 1]?.y.toFixed(
          2,
        ),
      );
    }
    if (granularity === 'district') {
      setPercentChange(undefined);
    }
  }, [
    percentChangeRadiusData,
    percentChangeRadiusIsFetching,
    data,
    isFetching,
  ]);

  const percentChangeIsLoading = isFetching || percentChangeRadiusIsFetching;

  return {
    percentChange,
    percentChangeIsLoading,
  };
};
