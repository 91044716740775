import {useMemo} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Box, Typography, useTheme} from '@mui/material';
import {useTranslation} from '@/hooks/useTranslation';
import BaseXYChart from '@/components/common/charts/BaseXYChart';
import BaseArea from '@/components/common/charts/XYCharts/BaseArea';
import {styles} from '@/components/analytics/charts/ChangeTrend.styles';

function ChangeTrend({
  data,
  isLoading,
  yDomain,
  height,
  width,
  period,
  aggregation,
  normalized,
}) {
  const theme = useTheme();
  const {locale, getI18N} = useTranslation();
  const customColors = [theme.palette.quintile[5], theme.palette.quintile[2]];

  const {
    incidentsPerMonth: incidentsPerMonthLabel,
    incidentsPerQuarter: incidentsPerQuarterLabel,
  } = getI18N('chartSelection');

  const {change: changeLabel, percentChange: percentChangeLabel} =
    getI18N('changeOverview');

  const tooltipLabel = useMemo(() => {
    if (normalized) {
      return changeLabel;
    }
    return percentChangeLabel;
  }, [normalized, locale]);

  const incidentsPerLabel = useMemo(() => {
    if (aggregation === 'monthly') {
      return incidentsPerMonthLabel;
    }
    return incidentsPerQuarterLabel;
  }, [aggregation]);

  const formatTooltip = (_, value) => {
    if (!normalized && value) {
      return `${value.toFixed(2)}%`;
    }
    if (!normalized && value) {
      return `${value} ${incidentsPerLabel}`;
    }
    // can be null
    return value;
  };

  /**
   * Get current period label by date
   * @param {*} d ISO8601 date string
   * @returns string
   * @example
   * currentPeriod('2021-09-01') // 09/2021
   * currentPeriod('2021-09-01') // 09/2021 - 11/2021
   */
  const currentPeriod = (d) => {
    const date = moment(d);
    if (aggregation === 'monthly') {
      return date.format('MM/YYYY');
    }
    return `${date.format('MM/YYYY')} - ${date
      .clone()
      .add(2, 'month')
      .format('MM/YYYY')}`;
  };

  /**
   * Get current previous period label by date
   * @param {*} d ISO8601 date string
   * @returns string
   * @example
   * currentPeriod('2021-09-01') // 09/2020
   * currentPeriod('2021-09-01') // 09/2020 - 11/2021
   */
  const prevPeriod = (d) => {
    let date = moment(d);
    if (period === 'periodOverPeriod' && aggregation === 'monthly') {
      date = date.clone().subtract(1, 'month');
    } else if (period === 'periodOverPeriod' && aggregation === 'quarterly') {
      date = date.clone().subtract(3, 'month');
    } else if (period === 'yearOverYear') {
      date = date.clone().subtract(1, 'year');
    }

    if (aggregation === 'monthly') {
      return date.format('MM/YYYY');
    }
    return `${date.format('MM/YYYY')} - ${date.add(2, 'month').format('MM/YYYY')}`;
  };

  const renderTooltip = ({tooltipData}) => {
    const nearestDatum = tooltipData.nearestDatum.datum;
    const {datumByKey} = tooltipData;

    // We render the highest absolute value, and show the color accordingly
    const yValues = Object.values(datumByKey).map(({datum}) => datum.y);
    const max = Math.max(...yValues.map(Math.abs));
    const maxIndex = yValues.findIndex((y) => Math.abs(y) === max);
    const sx = styles.tooltipDetails({
      backgroundColor: customColors[maxIndex],
    });

    return (
      <Box sx={styles.tooltipContainer}>
        <Box sx={styles.tooltipTitle}>
          <Box key={tooltipLabel} sx={styles.tooltip}>
            <Box sx={sx} />
            <strong>{tooltipLabel}: </strong>
            {formatTooltip(tooltipLabel, yValues[maxIndex])}
          </Box>
        </Box>
        <Typography variant="caption" color={theme.palette.text.primary}>
          <strong>{currentPeriod(nearestDatum.x)}: </strong>
          <Box component="span" color={theme.palette.text.secondary}>
            {nearestDatum.currentCount} {incidentsPerLabel}
          </Box>
          <br />
          <strong>{prevPeriod(nearestDatum.x)}: </strong>
          <Box component="span" color={theme.palette.text.secondary}>
            {nearestDatum.previousCount} {incidentsPerLabel}
          </Box>
        </Typography>
      </Box>
    );
  };

  return (
    <BaseXYChart
      height={height}
      width={width}
      showZeroLine
      isLoading={isLoading}
      seriesLength={1}
      customColors={customColors}
      dateFormat="MM/YYYY"
      xScale={{type: 'time'}}
      yScale={{type: 'linear', domain: yDomain}}
      xAxisOptions={{hideAxisLine: true}}
      tooltipOptions={{renderTooltip}}
      hideGrid>
      <BaseArea data={data} />
    </BaseXYChart>
  );
}

ChangeTrend.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  yDomain: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.number,
  width: PropTypes.number,
  period: PropTypes.string,
  aggregation: PropTypes.string,
  normalized: PropTypes.bool,
};

export default ChangeTrend;
