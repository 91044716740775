// Packages
import {useMemo, useState} from 'react';

// MUI
import Box from '@mui/material/Box';

// Hooks
import {useTranslation} from '@/hooks/useTranslation';
import {useFeatureAccess} from '@/hooks/useFeatureAccess';

// styles
import {styles} from '@/components/user/UserProfile.styles';

// Components
import {USER_TABS} from '@/components/user/constants';
import UserProfile from '@/components/user/UserProfile';
import UserKeyManagement from '@/components/user/key-management/UserKeyManagement';
import BaseTabGroup from '@/components/common/tabs/BaseTabGroup';

function UserPage() {
  const {getFeatureAccess} = useFeatureAccess();
  const {locale, getUserTranslations, getI18N} = useTranslation();
  const [tab, setTab] = useState('profile');
  const {title} = getI18N('user');

  const UserAccount = {profile: UserProfile, api: UserKeyManagement}[tab];

  const accountOptions = useMemo(
    () =>
      USER_TABS.map(({feature, label, hidden, ...tab}) => ({
        ...tab,
        label: getUserTranslations(label),
        hidden: hidden ?? getFeatureAccess(feature, 'hide'),
      })).filter(({hidden}) => !hidden),
    [locale],
  );

  return (
    <Box overflow="auto" data-testid="user-page" sx={styles.root}>
      <Box sx={styles.header}>{title}</Box>
      <Box sx={styles.tabsContainer}>
        <BaseTabGroup
          options={accountOptions}
          value={tab}
          onChange={(_, value) => setTab(value)}
        />
      </Box>
      <UserAccount />
    </Box>
  );
}

export default UserPage;
