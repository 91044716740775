import React from 'react';
import PropTypes from 'prop-types';

import {useTheme} from '@mui/material';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {CaretRight, MapPin, Star} from '@phosphor-icons/react';
import {styles} from '@/components/search/SearchCard.styles';

function SearchCard(props) {
  const theme = useTheme();
  const {text, caption, type, action} = props;

  return (
    <Card elevation={3} onClick={action}>
      <Box sx={styles.root}>
        {type && (
          <Box sx={styles.prefixIcon}>
            {type === 'saved' ? (
              <Star size={18} color={theme.palette.primary.main} />
            ) : (
              <MapPin size={18} />
            )}
          </Box>
        )}
        <Box sx={styles.textBox}>
          <Typography sx={styles.text} variant="body2">
            {text}
          </Typography>
          <Typography sx={styles.text} variant="caption">
            {caption}
          </Typography>
        </Box>
        <Box sx={styles.suffixIcon}>
          <CaretRight size={18} style={styles.suffixIcon} />
        </Box>
      </Box>
    </Card>
  );
}

SearchCard.propTypes = {
  action: PropTypes.func,
  text: PropTypes.string,
  caption: PropTypes.string,
  type: PropTypes.oneOf(['button', 'saved', 'google']),
};

export default SearchCard;
