import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function ReportChartWrapper({isLoading, error, children}) {
  if (isLoading) return <Box id="report-chart-loading">Chart Loading...</Box>;

  if (error) return <Box id="report-chart-error">Error rendering chart...</Box>;

  return <Box id="report-chart">{children}</Box>;
}

ReportChartWrapper.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.node,
};

export default ReportChartWrapper;
