export const styles = {
  grid: {
    paddingBottom: 4,
  },
  secretTextField: (theme) => ({
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  }),
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    flexGrow: 1,
  },
  warningContainer: {
    display: 'flex',
    marginTop: 1,
  },
  warningText: {
    display: 'flex',
    marginLeft: 1,
  },
  deleteText: {
    display: 'flex',
    marginLeft: 1,
    alignItems: 'center',
  },
  deleteIcon: {
    display: 'flex',
    alignItems: 'center',
  },
};
