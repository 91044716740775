const REQUIRED_CHART_PARAMS = {
  city: ['locationId'],
  district: ['locationId'],
  radius: ['latitude', 'longitude', 'radius', 'units'],
  dates: ['fromDate', 'toDate'],
};

export const validateChartParams = (queryParams, requiredKeys) => {
  const requiredParams = requiredKeys
    .map((k) => REQUIRED_CHART_PARAMS[k])
    .flat(1);
  return requiredParams.every((param) => queryParams[param] !== undefined);
};
