import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material';

import {Info} from '@phosphor-icons/react';

const useStyle = () => ({
  root: {
    position: 'relative',
    display: 'flex',
  },
  icon: {
    marginLeft: '5px',
    verticalAlign: 'sub',
    pointerEvents: 'auto',
  },
  text: {
    whiteSpace: 'pre-line',
    fontSize: '0.625rem',
  },
});

function BaseTooltip({
  message,
  placement,
  icon,
  iconSize,
  iconColor,
  children,
  disabled,
  ...props
}) {
  const styles = useStyle();
  const theme = useTheme();

  return (
    <Tooltip
      placement={placement}
      sx={styles.root}
      data-html2canvas-ignore="true"
      title={<Typography sx={styles.text}>{message}</Typography>}
      onClick={(e) => {
        if (disabled && icon) {
          e.stopPropagation();
        }
      }}
      {...props}>
      {icon ? (
        <Info
          style={styles.icon}
          size={iconSize}
          color={iconColor ?? theme.palette.secondary.light}
        />
      ) : (
        children
      )}
    </Tooltip>
  );
}

BaseTooltip.defaultProps = {
  placement: 'top',
  children: null,
  icon: true,
  iconSize: 13,
  message: 'Hey there!',
  disabled: false,
};

BaseTooltip.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.string,
  icon: PropTypes.bool,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  message: PropTypes.string.isRequired,
  props: PropTypes.object,
  disabled: PropTypes.bool,
};

export default BaseTooltip;
