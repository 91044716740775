import PropTypes from 'prop-types';

import {Menu, MenuItem} from '@mui/material';
import {useMemo} from 'react';
import {useSavedLocationsReportDownload} from '@/hooks/useSavedLocationsReportDownload';
import {useImageCapture} from '@/hooks/useImageCapture';
import {chartIds} from '@/components/saved-locations/constants';
import {useTranslation} from '@/hooks/useTranslation';
import {styles} from '@/components/navigation/menus/MyLocationsDownload.styles';

function MyLocationsDownloadMenu({anchorEl, handleClose = () => {}}) {
  const {getI18N} = useTranslation();
  const {downloadCSVReport} = useSavedLocationsReportDownload();
  const {downloadImage} = useImageCapture();
  const {downloadChartImages, downloadSavedLocationReport} = getI18N(
    'myLocationsAnalysis',
  );

  const imageCaptures = Object.values(chartIds).map((id) => ({id}));

  const availableImageCaptures = useMemo(
    () => imageCaptures.filter((image) => !!document.getElementById(image.id)),
    [imageCaptures],
  );

  const myLocationAnalysisDownloadOptions = [
    {
      label: downloadChartImages,
      action: () => {
        availableImageCaptures.forEach((image) => {
          downloadImage({
            id: image.id,
            fileName: image.id,
          });
        });
      },
    },
    {
      label: downloadSavedLocationReport,
      action: downloadCSVReport,
    },
  ];

  return (
    <Menu
      data-html2canvas-ignore="true"
      data-testid="download-chart-menu"
      getcontentanchorel={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={styles.menu}>
      {myLocationAnalysisDownloadOptions.map((op) => (
        <MenuItem
          key={op.label}
          data-testid={`chart-${op.label}`}
          onClick={op.action}>
          {op.label}
        </MenuItem>
      ))}
    </Menu>
  );
}

MyLocationsDownloadMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
};

export default MyLocationsDownloadMenu;
