import {useState} from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {useTranslation} from '@/hooks/useTranslation';
import {useRouter} from '@/hooks/useRouter';
import {useAuth0Service} from '@/hooks/useAuth0Service';
import {useMixpanel} from '@/hooks/useMixpanel';

const useStyles = () => ({
  modal: (theme) => ({
    top: 'calc(50vh - 100px)',
    margin: 'auto',
    position: 'relative',
    width: '20%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
    padding: theme.spacing(2),
  }),
  button: (theme) => ({
    margin: theme.spacing(1),
    marginLeft: 0,
    marginBottom: 0,
  }),
  centeredModal: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  centeredBox: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const ModalContent = styled(Box)(({theme}) => ({
  top: 'calc(50vh - 100px)',
  margin: 'auto',
  position: 'relative',
  width: '20%',
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  outline: 'none',
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const ModalButton = styled(Button)(({theme}) => ({
  margin: theme.spacing(1),
  marginLeft: 0,
  marginBottom: 0,
}));

function MenuSignOut({anchorEl, onClose}) {
  const {track} = useMixpanel();
  const {getI18N} = useTranslation();
  const {pushUserRoute} = useRouter();
  const {
    signOut,
    areYouSureYouWantToSignOutOfBaseOperations,
    yes,
    no,
    changePasswordEmail,
    changePasswordOption,
  } = getI18N('menuSignOut');
  const {title} = getI18N('user');

  const {handleAuth0LogOut, handleAuth0Password} = useAuth0Service();
  const styles = useStyles();
  const [signoutModalOpen, setSignoutModalOpen] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const handleLogOut = () => {
    setSignoutModalOpen(false);
    onClose();
    handleAuth0LogOut();
  };

  const handleChangePassword = () => {
    handleAuth0Password();
    setOpenPasswordModal(true);
    onClose();
  };

  const handlePushUserRoute = () => {
    track('Open User Settings');
    pushUserRoute();
    onClose();
  };

  return (
    <>
      <Modal open={signoutModalOpen} sx={styles.centeredModal}>
        <ModalContent>
          <Typography data-testid="lblSignOutModal">
            {areYouSureYouWantToSignOutOfBaseOperations}
          </Typography>
          <Box sx={styles.centeredBox}>
            <ModalButton
              onClick={handleLogOut}
              variant="outlined"
              data-testid="btnSignOutModalYes">
              <Typography>{yes}</Typography>
            </ModalButton>
            <ModalButton
              onClick={() => setSignoutModalOpen(false)}
              variant="outlined"
              data-testid="btnSignOutModalNo">
              <Typography>{no}</Typography>
            </ModalButton>
          </Box>
        </ModalContent>
      </Modal>

      <Modal open={openPasswordModal} sx={styles.centeredModal}>
        <ModalContent>
          <Typography data-testid="lblChangePassword">
            {changePasswordEmail}
          </Typography>
          <Box sx={styles.centeredBox}>
            <ModalButton
              onClick={() => setOpenPasswordModal(false)}
              variant="outlined"
              data-testid="btnChangePasswordOk">
              <Typography>Ok</Typography>
            </ModalButton>
          </Box>
        </ModalContent>
      </Modal>
      <Menu
        id="menu-signout"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={onClose}
        classes={{paper: styles.dropdown}}>
        <MenuItem
          onClick={handlePushUserRoute}
          data-testid="btnUserSettingsSubMenu">
          <Typography variant="body2">{title}</Typography>
        </MenuItem>
        <MenuItem
          onClick={handleChangePassword}
          data-testid="btnChangePasswordSubMenu">
          <Typography variant="body2">{changePasswordOption}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => setSignoutModalOpen(true)}
          data-testid="btnSignOutSubMenu">
          <Typography variant="body2">{signOut}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

MenuSignOut.propTypes = {
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.any.isRequired,
};

export default MenuSignOut;
