export const styles = {
  root: {
    width: '100%',
    textAlign: 'center',
  },
  optionHeader: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.light,
  }),
  collapsibleBox: {
    borderRadius: '4px',
  },
  divider: {
    margin: '0px 8px',
  },
  expandIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingRight: 2,
  },
  parent: (theme) => ({
    marginBottom: '4px',
    backgroundColor: theme.palette.background.light,
  }),
  parentItem: {
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  childItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '7px 16px',
    paddingRight: '50px',
  },
  compactChildItem: {
    padding: 0,
    margin: 0,
    paddingRight: 0,
  },
  threatLabel: {
    width: '40%',
    textAlign: 'start',
  },
  threatCount: {
    width: '30%',
    textAlign: 'start',
  },
  threatQuintile: {
    width: '30%',
    textAlign: 'start',
  },
};
