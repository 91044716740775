import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {Typography, useTheme} from '@mui/material';
import {LegendOrdinal, LegendItem, LegendLabel} from '@visx/legend';
import {scaleOrdinal} from '@visx/scale';
import useChartTheme from '@common/charts/ChartTheme';
import BaseSkeleton from '@common/BaseSkeleton';
import {styles} from '@common/charts/BaseLegend.styles';

function BaseLegend({
  labels,
  onClick,
  disabled,
  isLoading,
  sx,
  showValues,
  height,
}) {
  const theme = useTheme();
  const chartTheme = useChartTheme(labels.length);
  const {colors} = chartTheme;

  const colorScale = scaleOrdinal({
    domain: labels,
    range: labels.map((_, i) => colors[i % colors.length]),
  });

  if (isLoading) {
    return <BaseSkeleton height={height} sx={{...sx}} />;
  }
  return (
    <Box sx={{...styles.root(theme), ...sx}}>
      <LegendOrdinal scale={colorScale} labelFormat={(label) => label.label}>
        {(labels) => (
          <Box sx={styles.innerBox}>
            {labels.map((label) => (
              <LegendItem
                key={`legend-${label.datum.value}-${label.index}`}
                style={styles.legendItem}
                onClick={() => onClick(label.datum.value)}>
                <Box sx={styles.legendItemBox}>
                  <svg width={16} height={16}>
                    <rect
                      fill={label.datum.color ?? label.value}
                      fillOpacity={
                        disabled.includes(label.datum.value) ? 0.2 : 1
                      }
                      rx={4}
                      width={16}
                      height={16}
                    />
                  </svg>
                </Box>
                <LegendLabel
                  style={{
                    ...styles.legendLabel,
                    color: disabled.includes(label.datum.value)
                      ? theme.palette.text.disabled
                      : theme.palette.text.primary,
                  }}>
                  {showValues ? (
                    <Typography variant="caption" fontWeight="bold">
                      {`${label.datum.value} `}
                    </Typography>
                  ) : undefined}
                  <Typography variant="caption">{label.text}</Typography>
                </LegendLabel>
              </LegendItem>
            ))}
          </Box>
        )}
      </LegendOrdinal>
    </Box>
  );
}

BaseLegend.defaultProps = {
  labels: [
    {
      label: 'Label 1',
      value: 'value1',
      color: 'yellow',
    },
    {
      label: 'Label 2',
      value: 'value2',
      color: 'red',
    },
    {
      label: 'Label 3',
      value: 'value3',
      color: 'blue',
    },
  ],
  onClick: () => {},
  disabled: [],
  height: 60,
};

BaseLegend.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sx: PropTypes.object,
  isLoading: PropTypes.bool,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      color: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  showValues: PropTypes.bool,
};

export default BaseLegend;
