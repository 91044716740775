import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {handleClickRadius} from '@/utils/mapUtils';
import {
  getSelectionMarkerLat,
  getSelectionMarkerLon,
  getThreatAnalyticsAreaRadius,
} from '@/selectors';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';

export const useClickedMap = () => {
  const {getZoom} = useMapboxGL();
  const selectedLat = useSelector(getSelectionMarkerLat);
  const selectedLon = useSelector(getSelectionMarkerLon);
  const savedRadius = useSelector(getThreatAnalyticsAreaRadius);

  const computedRadius = useMemo(
    () =>
      selectedLat && selectedLon ? handleClickRadius(getZoom()) : savedRadius,
    [getZoom, selectedLat, selectedLon, savedRadius],
  );

  return {
    computedRadius,
  };
};
