export const styles = {
  root: {
    marginBottom: 3,
  },
  reportGrid: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 550,
    height: 300,
    backgroundColor: theme.palette.common.white,
  }),
};
