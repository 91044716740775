export const styles = {
  typographyLabel: ({theme, bold, disabled}) => ({
    display: 'flex',
    alignItems: 'center',
    fontWeight: bold ? 'bold' : 'normal',
    color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
  }),
  skeletorContainer: {
    marginY: 0.5,
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
  },
  checkboxContainer: {
    padding: '3px',
    marginX: '6px',
  },
};
